import { useMemo, useState } from "react";
import { Title } from "@atoms";
import { TabsContainer } from "@organisms";
import { RoomsReport, EventsReport, CreditsReport } from "@features";

const Reports = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleSetBookingsActiveTab = () => {
    setActiveTab(0);
  };

  const tabs = useMemo(
    () => [
      {
        id: 1,
        label: "Room bookings",
        component: <RoomsReport />,
      },
      {
        id: 2,
        label: "Events",
        component: <EventsReport />,
      },
      {
        id: 3,
        label: "Credit Use",
        component: (
          <CreditsReport onSetBookingsActiveTab={handleSetBookingsActiveTab} />
        ),
      },
    ],
    []
  );

  return (
    <>
      <Title>Reports</Title>
      <TabsContainer
        tabs={tabs}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        sx={{ p: "0 30px", m: " 0 -30px" }}
      />
    </>
  );
};

export default Reports;
