import { Controller, useFormContext } from "react-hook-form";
import { bool, string } from "prop-types";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Label } from "@atoms";

import { FlexWrapper } from "./styles";

const TimePickerField = ({
  id,
  label,
  name,
  errorText,
  ampm,
  isLabelBold,
  ...props
}) => {
  const { control, getValues } = useFormContext();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <FlexWrapper>
        {label && (
          <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <TimePicker
              {...props}
              id={id}
              ampm={ampm}
              value={getValues(name)}
              onChange={(time) => {
                field.onChange(time);
              }}
              renderInput={(params) => (
                <TextField {...params} error={!!errorText} />
              )}
            />
          )}
        />
      </FlexWrapper>
    </LocalizationProvider>
  );
};

TimePickerField.propTypes = {
  id: string.isRequired,
  label: string,
  name: string.isRequired,
  errorText: string,
  ampm: bool,
  isLabelBold: bool,
};

TimePickerField.defaultProps = {
  label: "",
  errorText: "",
  ampm: false,
  isLabelBold: false,
};

export default TimePickerField;
