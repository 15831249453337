import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  maxWidth: 568,
}));

export const StyledLabel = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontWeight: 400,
}));
