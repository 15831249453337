import { func, string, number, bool } from "prop-types";
import { WelcomeForm } from "@molecules";
import { Auth } from "@templates";

const text = "To log in, we will send you a one time security code.";

const Login = ({
  isLoading,
  error,
  onTabClick,
  onFormSubmit,
  onEmailChange,
  activeTab,
  selectedCountry,
  onCountrySelect,
  onPhoneNumberValueChange,
  phoneNumberValue,
  emailValue,
}) => (
  <Auth useLogo text={text} isLoading={isLoading}>
    <WelcomeForm
      onTabClick={onTabClick}
      activeTab={activeTab}
      onFormSubmit={onFormSubmit}
      error={error}
      onEmailChange={onEmailChange}
      onCountrySelect={onCountrySelect}
      selectedCountry={selectedCountry}
      onPhoneNumberValueChange={onPhoneNumberValueChange}
      phoneNumberValue={phoneNumberValue}
      emailValue={emailValue}
    />
  </Auth>
);

Login.propTypes = {
  isLoading: bool.isRequired,
  error: string.isRequired,
  onTabClick: func.isRequired,
  onFormSubmit: func.isRequired,
  onEmailChange: func.isRequired,
  activeTab: number.isRequired,
  selectedCountry: string.isRequired,
  onCountrySelect: func.isRequired,
  onPhoneNumberValueChange: func.isRequired,
  phoneNumberValue: string.isRequired,
  emailValue: string.isRequired,
};

export default Login;
