import { useState, useCallback } from "react";

const useModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [payload, setPayload] = useState(null);

  const onOpen = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const onClose = useCallback(() => {
    setPayload(null);
    setShowModal(false);
  }, [setShowModal]);

  return {
    showModal,
    onOpen,
    onClose,
    payload,
    setPayload,
  };
};

export default useModal;
