import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  ControlledTextField,
  RadioButtonWithLabel,
  RichTextEditor,
} from "@atoms";
import { checkDisabledField } from "./utils";
import { RadioGroupInner, StyledRadioGroup, Wrap } from "./styles";

const GetBenefitSection = () => {
  const { control, watch } = useFormContext();
  const howToGetFormValue = watch("howToGet");
  const [activeField, setActiveField] = useState(howToGetFormValue);

  useEffect(() => {
    setActiveField(howToGetFormValue);
  }, [howToGetFormValue]);

  const onRadioChange = ({ target: { value } }) => setActiveField(value);

  const {
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <Wrap>
      <Typography variant="h3" sx={{ mb: 4 }}>
        How to Get the Benefit*
      </Typography>
      <Controller
        name="howToGet"
        control={control}
        render={({ field }) => (
          <StyledRadioGroup
            {...field}
            onChange={(event) => {
              field.onChange(event);
              onRadioChange(event);
            }}
            aria-labelledby="how-to-get-benefit-approaches"
          >
            <RadioGroupInner>
              <RadioButtonWithLabel
                label="Description"
                value="couponDescription"
                sx={{ mb: 2 }}
              />
              <Controller
                name="couponDescription"
                disabled={checkDisabledField(activeField, "couponDescription")}
                control={control}
                render={({ field: { value, onChange: onFieldChange } }) => (
                  <RichTextEditor
                    onChange={(value) => {
                      clearErrors("howToGet");
                      onFieldChange(value);
                    }}
                    value={value}
                    {...((activeField === "couponDescription" ||
                      !activeField) && {
                      error: errors?.howToGet?.message,
                    })}
                  />
                )}
              />
            </RadioGroupInner>
            <Box
              sx={{
                pl: 5,
                width: "50%",
              }}
            >
              <RadioButtonWithLabel
                label="Coupon Code for Benefit"
                value="couponCode"
                sx={{ mb: 2 }}
              />
              <ControlledTextField
                disabled={checkDisabledField(activeField, "couponCode")}
                name="couponCode"
                placeholder="#Mindspace"
                onChange={() => clearErrors("howToGet")}
                {...((activeField === "couponCode" || !activeField) && {
                  error: errors?.howToGet?.message,
                })}
              />
            </Box>
          </StyledRadioGroup>
        )}
      />
    </Wrap>
  );
};

export default GetBenefitSection;
