import { node, string } from "prop-types";
import { BackToPrevPage, Title } from "@atoms";
import { StyledSection } from "./styles";

const LeftSidabarSection = ({
  title,
  prevPageButtonContent,
  prevPageRoute,
  children,
}) => (
  <StyledSection>
    <BackToPrevPage to={prevPageRoute}>{prevPageButtonContent}</BackToPrevPage>
    <Title>{title}</Title>
    {children}
  </StyledSection>
);

LeftSidabarSection.propTypes = {
  title: string.isRequired,
  prevPageButtonContent: string.isRequired,
  prevPageRoute: string.isRequired,
  children: node,
};

LeftSidabarSection.defaultProps = {
  children: null,
};

export default LeftSidabarSection;
