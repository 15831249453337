import { useCallback } from "react";
import { arrayOf, bool, func, node, number, shape, string } from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import {
  dataTableImageHeightInPx,
  rowPaddingYinPx,
  SuperscriptionWrap,
} from "./styles";
import { pageSize } from "@requests/config";

const DEFAULT_HEIGHT = 40;
const DEFAULT_HEADER_HEIGHT = 56;

const DataTable = ({
  rows,
  columns,
  superscription,
  onRowClick,
  withDefaultRowHeight,
  withDefaultHeaderHeight,
  ...props
}) => {
  const handleRowClick = useCallback(
    ({ id, row }, event) => {
      onRowClick && onRowClick(id, row);
      event.stopPropagation();
    },
    [onRowClick]
  );

  return (
    <>
      {superscription && (
        <SuperscriptionWrap>{superscription}</SuperscriptionWrap>
      )}
      <DataGrid
        autoHeight
        hideFooterSelectedRowCount
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        disableColumnMenu
        disableSelectionOnClick
        pageSize={pageSize}
        rowHeight={
          withDefaultRowHeight
            ? dataTableImageHeightInPx + rowPaddingYinPx * 2
            : DEFAULT_HEIGHT
        }
        headerHeight={
          withDefaultHeaderHeight ? DEFAULT_HEADER_HEIGHT : DEFAULT_HEIGHT
        }
        {...props}
      />
    </>
  );
};

DataTable.propTypes = {
  rows: arrayOf(shape({})),
  columns: arrayOf(shape({})),
  superscription: node,
  onRowClick: func,
  onPageChange: func,
  withDefaultRowHeight: bool,
  withDefaultHeaderHeight: bool,
  paginationMode: string,
  page: number,
  rowCount: number,
};

DataTable.defaultProps = {
  rows: [],
  columns: [],
  superscription: undefined,
  onRowClick: () => null,
  onPageChange: () => null,
  withDefaultRowHeight: true,
  withDefaultHeaderHeight: false,
  paginationMode: "client",
  page: 0,
  rowCount: 0,
};

export default DataTable;
