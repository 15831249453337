import { Box, InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  "& .MuiSelect-select": {
    padding: `${theme.spacing(0, 2)} !important`,
  },
  "& .MuiOutlinedInput-root": {
    height: "32px",
  },
}));

export const StyledInputLabel = styled(InputLabel)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  color: theme.palette.black,
}));
