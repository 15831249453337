import { bool, func, object } from "prop-types";
import { Stack } from "@mui/material";
import dayjs from "dayjs";

import { ModalContainer, TextWithLabel } from "@molecules";
import { DATE_TIME_TEMPLATE } from "@constants";

const SentPushModal = ({ isOpen, onClose, payload: pushData }) => {
  const { label, content, sentTo, sentDate } = pushData || {};

  return (
    <ModalContainer
      isOpen={isOpen}
      onClose={onClose}
      title="Sent Notification"
      titleAriaLabel="sent-push-modal-title"
      confirmButtonConfig={{
        title: "Close",
        onClick: onClose,
      }}
      maxWidth={495}
    >
      <Stack spacing={6}>
        <TextWithLabel label="Label" text={label} />
        <TextWithLabel label="Content" text={content} />
        <TextWithLabel label="Send to" text={sentTo?.join(", ")} />
        <TextWithLabel
          label="Sent time"
          text={dayjs(sentDate).format(DATE_TIME_TEMPLATE)}
          helperText="*In local time zone for selected locations"
        />
      </Stack>
    </ModalContainer>
  );
};

SentPushModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  isEditable: true,
  payload: null,
};

SentPushModal.propTypes = {
  isOpen: bool,
  onClose: func,
  isEditable: bool,
  payload: object,
};

export default SentPushModal;
