import { Rooms as View } from "@views";
import { Layout } from "@templates";

const Rooms = () => (
  <Layout>
    <View />
  </Layout>
);

export default Rooms;
