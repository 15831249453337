import { Menu, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledMenu = styled(Menu)(({ theme }) => ({
  ".MuiList-root": {
    padding: theme.spacing(2),
  },
  ".MuiMenuItem-root": {
    width: 193,
    padding: theme.spacing(2.5, 3.5),
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: alpha("#EFECE6", 0.5),
    },
  },
}));
