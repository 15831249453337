import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import {
  getCategories,
  getImage,
  getLocations,
  parseStrictISOString,
} from "@helpers";
import {
  BooleanIndicator,
  BootstrapTooltip,
  DataTableEllipsisCell,
  StatusLabel,
} from "@atoms";
import { MultiplyList } from "@molecules";
import { DATE_TEMPLATE } from "@constants";

export const columns = [
  {
    id: 1,
    field: "image",
    headerName: "Image",
    sortable: false,
    renderCell: ({ value }) => (value ? getImage(value) : <MissingValue />),
    flex: 0,
  },
  {
    id: 2,
    field: "title",
    headerName: "Partner Name",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 2,
  },
  {
    id: 3,
    field: "category",
    headerName: "Category",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? <MultiplyList values={getCategories(value)} /> : <MissingValue />,
    flex: 1,
  },
  {
    id: 4,
    field: "startDate",
    headerName: "Start date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? parseStrictISOString(value, DATE_TEMPLATE) : <MissingValue />,
    flex: 1,
  },
  {
    id: 5,
    field: "endDate",
    headerName: "End date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? parseStrictISOString(value, DATE_TEMPLATE) : <MissingValue />,
    flex: 1,
  },
  {
    id: 6,
    field: "place",
    headerName: "Location",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      Array.isArray(value) && value.length ? (
        <MultiplyList values={getLocations(value)} />
      ) : (
        value || <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 7,
    field: "isFeatured",
    headerName: "Featured",
    width: 140,
    sortable: false,
    renderCell: ({ value }) => <BooleanIndicator value={value} />,
    flex: 0,
    headerAlign: "center",
    align: "center",
  },
  {
    id: 8,
    field: "isNew",
    headerName: "New",
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <BooleanIndicator value={value} />,
    flex: 0,
    headerAlign: "center",
    align: "center",
  },
  {
    id: 9,
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <StatusLabel value={value} />,
    flex: 0,
  },
];
