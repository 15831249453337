import { Box, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginBottom: theme.spacing(9),
  marginTop: "0 !important",
}));

export const StyledRadioGroup = styled(RadioGroup)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "nowrap",
  minWidth: "800px",
  width: "100%",
}));

export const RadioGroupInner = styled(Box)(({ theme }) => ({
  paddingRight: theme.spacing(5),
  borderRight: `1px solid ${theme.palette.gray[700]}`,
  width: "50%",
}));
