import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

// TODO: move this component outside this file
export const Separator = styled(Box)(({ theme }) => ({
  height: "1px",
  width: "100%",
  margin: "30px 0",
  backgroundColor: theme.palette.gray[500],
}));

export const EndDateLabel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));
