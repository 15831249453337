import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { func } from "prop-types";
import { ROUTES } from "@constants";
import { Wrap, Description, Title } from "./styles";

const Error = ({ clearState }) => {
  const navigate = useNavigate();

  const navigateToHomePage = () => {
    clearState();
    navigate(ROUTES.Home);
  };

  return (
    <Wrap>
      <Title variant="h2">Oops!</Title>
      <Description>Something went wrong</Description>
      <Button variant="contained" onClick={navigateToHomePage}>
        Go back to Home page
      </Button>
    </Wrap>
  );
};

Error.propTypes = {
  clearState: func.isRequired,
};

export default Error;
