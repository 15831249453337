import { styled } from "@mui/material/styles";

export const StyledSection = styled("section")(({ theme }) => ({
  position: "relative",
  minWidth: "320px",
  width: "320px",
  padding: theme.spacing(6, 11, 6, 7.5),
  backgroundColor: theme.palette.gray[800],
  minHeight: "100vh",
}));
