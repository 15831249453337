export const statusesItems = [
  {
    id: 1,
    name: "Active",
    value: "active",
  },
  {
    id: 2,
    name: "Inactive",
    value: "inactive",
  },
];

export const eventStatusesItems = [
  {
    id: 1,
    name: "Draft",
    value: "draft",
  },
  // Described in API, but not implemented
  /*
  {
    id: 2,
    name: "Published",
    value: "published",
  },
   */
  {
    id: 2,
    name: "Active",
    value: "active",
  },
  {
    id: 3,
    name: "Upcoming",
    value: "upcoming",
  },
  {
    id: 4,
    name: "Past",
    value: "past",
  },
];

export const pushNotificationsStatusesItems = [
  {
    id: 1,
    name: "Draft",
    value: "draft",
  },
  {
    id: 2,
    name: "Sent",
    value: "sent",
  },
  {
    id: 3,
    name: "Scheduled",
    value: "scheduled",
  },
];

export const reportEventStatusesItems = [
  {
    id: 0,
    name: "Active",
    value: "active",
  },
  {
    id: 1,
    name: "Past",
    value: "past",
  },
];
