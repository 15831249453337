import * as z from "zod";

export const schema = z
  .object({
    category: z.array(z.string()).nonempty("Required field"),
    startDate: z.string().min(1, "Required field"),
    endDate: z.string(),
    isFeatured: z.boolean(),
    isNew: z.boolean(),
    status: z.boolean(),
    title: z.string().trim().min(1, "Required field"),
    tagline: z.string().trim().min(1, "Required field"),
    image: z.union([z.string().min(1, "Required field"), z.instanceof(File)]),
    place: z
      .array(
        z.object({
          label: z.string().min(1, "Required field"),
          value: z.string().min(1, "Required field"),
          parent: z.string().optional(),
        })
      )
      .nonempty("Required field"),
    description: z.string().trim(),
    howToGet: z.string(),
    couponDescription: z.string(),
    couponCode: z.string().trim(),
    website: z.string().trim(),
    instagram: z.string().trim(),
    facebook: z.string().trim(),
    phone: z.string().trim(),
    address: z.string().trim(),
    latitude: z.union([z.number(), z.null()]).optional(),
    longitude: z.union([z.number(), z.null()]).optional(),
  })
  .refine(
    (value) =>
      !(
        (!value.howToGet && !value.couponCode && !value.couponDescription) ||
        (value.howToGet === "couponDescription" && !value.couponDescription) ||
        (value.howToGet === "couponCode" && !value.couponCode)
      ),
    {
      path: ["howToGet"],
      message:
        "CouponDescription or/and couponCode are not provided for this benefit",
    }
  )
  .refine(
    (input) => !(input.address && (!input.latitude || !input.longitude)),
    {
      path: ["address"],
      message: "Latitude and logitude are not found for this address",
    }
  );
