import { Autocomplete } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { string, shape, arrayOf, number } from "prop-types";
import { StyledTextField } from "./styles";

const SingleSelect = ({
  label,
  placeholder,
  name,
  options,
  error,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete
          {...field}
          id={name}
          options={options}
          getOptionLabel={(option) => option.name || ""}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          onChange={(_, data) => onChange(data)}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              label={label}
              placeholder={placeholder}
              error={!!error}
            />
          )}
          {...props}
        />
      )}
    />
  );
};

SingleSelect.defaultProps = {
  options: [],
  label: "",
  error: undefined,
};

SingleSelect.propTypes = {
  placeholder: string.isRequired,
  name: string.isRequired,
  options: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ),
  label: string,
  error: string,
};

export default SingleSelect;
