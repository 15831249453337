import { alpha, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MenuItem = styled(Box)(({ theme }) => ({
  padding: "8px 12px",
  cursor: "pointer",
  borderBottom: `1px solid ${alpha(theme.palette.black, 0.15)}`,
  backgroundColor: theme.palette.white,
  "&:last-child": {
    borderBottom: "none",
  },
}));

export const Menu = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
