import * as z from "zod";

export const nonPrintingDetailsSchema = z.object({
  homepageImage: z
    .union([z.string().min(1, "Required field"), z.instanceof(File), z.null()])
    .refine((val) => val !== null, { message: "Required field" }),
  splashImage: z.union([z.string(), z.instanceof(File), z.null()]),
  address: z.string().trim().min(1, "Required field"),
  details: z.object({
    faxNumbers: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
    buildingProcedures: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
    printerInstructionsPC: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
    printerInstructionsMAC: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
    securePrinting: z
      .object({
        value: z.string(),
        isEmpty: z.boolean(),
      })
      .optional(),
    scanningInstructions: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
  }),
  wifiSettings: z.object({
    members: z.object({
      network: z.string().trim().min(1, "Required field"),
      password: z.string().trim().min(1, "Required field"),
    }),
    guests: z.object({
      network: z.string().trim().min(1, "Required field"),
      password: z.string().trim().min(1, "Required field"),
    }),
  }),
  locationToAmenity: z
    .array(
      z.object({
        amenity: z
          .object({
            id: z.number({
              invalid_type_error: "Required field",
            }),
            name: z.string(),
          })
          .nullable()
          .refine((val) => val !== null, {
            message: "Required field",
            path: ["id"],
          }),
        description: z.string().trim().min(1, "Required field"),
      })
    )
    .nonempty("Required field"),
  isActivateLocation: z.boolean(),
});

export const printingDetailsSchema = nonPrintingDetailsSchema.extend({
  details: z.object({
    printerInstructionsPC: z.object({
      value: z.string(),
      isEmpty: z.boolean().refine((val) => !val, { message: "Required field" }),
    }),
    printerInstructionsMAC: z.object({
      value: z.string(),
      isEmpty: z.boolean().refine((val) => !val, { message: "Required field" }),
    }),
    securePrinting: z
      .object({
        value: z.string(),
        isEmpty: z.boolean(),
      })
      .optional(),
    scanningInstructions: z.object({
      value: z.string(),
      isEmpty: z.boolean().refine((val) => !val, { message: "Required field" }),
    }),
    faxNumbers: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
    buildingProcedures: z.object({
      value: z.string(),
      isEmpty: z.boolean(),
    }),
  }),
});
