import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  formatDateToString,
  getUTCDateAndTimeFromZoned,
  getZonedDateAndTimeFromUTC,
} from "@helpers";
import { DATE_YEARS_TEMPLATE, WEEK_IN_DAYS } from "@constants";
import { adjustHTML } from "../../../helpers/common";

export const emptyService = {
  name: "",
  duration: 15,
  price: "0",
  currency: "EUR",
  action: "create",
};

export const getDefaultStartDate = () => {
  const defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate() + WEEK_IN_DAYS);
  return defaultDate;
};

export const defaultEventValues = {
  name: "",
  description: "",
  locationDetails: "",
  image: "",
  date: {
    eventType: "single",
    startDate: formatDateToString(getDefaultStartDate(), DATE_YEARS_TEMPLATE),
    endDate: "",
  },
  startTime: dayjs("18:00", "HH:mm"),
  endTime: null,
  frequency: "weekly",
  isTimeSlotted: false,
  capacityLimit: "",
  services: [
    {
      name: "",
      duration: 15,
      price: "0",
      currency: "EUR",
      action: "create",
    },
  ],
  location: null,
  categories: [],
};

export const transformLocationsStructure = (data) => {
  const res = [];
  data?.forEach((item) => {
    const foundCountry = res.find((country) => item.country === country.name);
    if (foundCountry) {
      const foundCity = foundCountry.suboptions.find(
        (city) => item.city === city.name
      );
      if (foundCity) {
        const foundLocation = foundCity.suboptions.find(
          (location) => item.title === location.name
        );
        if (!foundLocation) {
          foundCity.suboptions.push({
            id: item.id,
            name: item.title,
            timezone: item.timeZone,
            city: item.city,
            country: item.country,
          });
        }
      } else {
        foundCountry.suboptions.push({
          id: uuidv4(),
          name: item.city,
          suboptions: [
            { id: item.id, name: item.title, timezone: item.timeZone },
          ],
          city: item.city,
          country: item.country,
        });
      }
    } else {
      res.push({
        id: uuidv4(),
        name: item.country,
        city: item.city,
        country: item.country,
        suboptions: [
          {
            id: uuidv4(),
            name: item.city,
            city: item.city,
            country: item.country,
            suboptions: [
              {
                id: item.id,
                name: item.title,
                timezone: item.timeZone,
                city: item.city,
                country: item.country,
              },
            ],
          },
        ],
      });
    }
  });
  return res;
};

export const transformCategoriesStructure = (data) =>
  data?.data?.map(({ id, name }) => ({
    value: String(id),
    label: name,
  })) || [];

export const transformCreatedByStructure = (data) =>
  data?.map(({ id, firstName, lastName }) => ({
    id: String(id),
    name: `${firstName} ${lastName}`,
  })) || [];

export const transformRoomsByStructure = (data = []) =>
  data.map(({ id, roomNumber }) => ({
    id: String(id),
    name: roomNumber,
  }));

export const transformCompaniesByStructure = (data = []) =>
  data.map(({ id, name }) => ({ id: String(id), name }));

export const transformUsersByStructure = (data = []) =>
  data.map(({ id, firstName, lastName, companyName }) => ({
    id: String(id),
    name: `${firstName} ${lastName}${companyName ? ` (${companyName})` : ""}`,
  }));

export const transformCategoriesStructureFull = (data) =>
  data?.data?.map(({ id, name }) => ({
    id,
    name,
    value: String(id),
  })) || [];

export const createRequestBody = (
  {
    name,
    description,
    locationDetails,
    image,
    date: { eventType, startDate, endDate },
    startTime,
    endTime,
    frequency,
    isTimeSlotted,
    capacityLimit,
    location,
    categories,
  },
  services,
  dirtyFields
) => {
  const fd = new FormData();
  const fullStartDate = `${startDate} ${startTime.$d
    .toTimeString()
    .slice(0, 5)}`;

  const fullEndDate = `${endDate || startDate} ${endTime.$d
    .toTimeString()
    .slice(0, 5)}`;

  const [startDateUTC, startTimeUTC] = getUTCDateAndTimeFromZoned(
    fullStartDate,
    location.timezone
  );
  const [endDateUTC, endTimeUTC] = getUTCDateAndTimeFromZoned(
    fullEndDate,
    location.timezone
  );

  if (typeof image !== "string") {
    fd.append("image", image);
  }
  fd.append("name", name);
  fd.append("description", adjustHTML(description));
  fd.append("hasSlots", isTimeSlotted);
  fd.append("isRecurring", eventType === "recurring" ? "true" : "false");
  fd.append("startDate", startDateUTC);
  if (eventType === "recurring") {
    fd.append("frequency", frequency);
  }
  fd.append("endDate", endDateUTC);
  fd.append("startTime", startTimeUTC);
  fd.append("endTime", endTimeUTC);
  categories.forEach((categoryId, index) => {
    fd.append(`eventCategories[${index}]`, categoryId);
  });
  fd.append("locationId", location.id);
  if (locationDetails) {
    fd.append("locationDescription", locationDetails);
  }

  if (isTimeSlotted) {
    services.forEach((service, index) => {
      if (dirtyFields?.services?.[index] || service.action === "delete") {
        if (!service.action) {
          service.action = "update";
        }
        Object.entries(service).forEach((item) => {
          const [key, value] = item;
          if (key === "price") {
            fd.append(`services[${index}][${key}]`, value || 0);
          } else {
            fd.append(`services[${index}][${key}]`, value);
          }
        });
      }
    });
  } else if (capacityLimit) {
    fd.append("capacity", capacityLimit);
  }
  return fd;
};

export const createEventFormValues = (data, locations = []) => {
  const timezone = locations?.find(
    ({ id }) => id === data?.data?.locationId
  )?.timeZone;

  if (!(data?.data && timezone)) {
    return defaultEventValues;
  }

  // eslint-disable-next-line no-unsafe-optional-chaining
  const { startDate, endDate, startTime, endTime } = data?.data;
  const [startZonedDate, startZonedTime] = getZonedDateAndTimeFromUTC(
    startDate,
    startTime,
    timezone
  );
  const [endZonedDate, endZonedTime] = getZonedDateAndTimeFromUTC(
    endDate,
    endTime,
    timezone
  );

  return {
    name: data?.data?.name || "",
    description: data?.data?.description || "",
    locationDetails: data?.data?.locationDescription || "",
    image: data?.data?.imageUrl || "",
    date: {
      eventType: data?.data?.isRecurring ? "recurring" : "single",
      startDate: startZonedDate || "",
      endDate: data?.data.isRecurring ? endZonedDate : "",
    },
    startTime: startZonedTime ? dayjs(startZonedTime, "HH:mm") : null,
    endTime: endZonedTime ? dayjs(endZonedTime, "HH:mm") : null,
    frequency: data?.data?.frequency || "weekly",
    isTimeSlotted: !!data?.data?.hasSlots,
    capacityLimit: data?.data?.capacity || "",
    services: data?.data?.services?.length
      ? data?.data?.services
      : [emptyService],
    location: data?.data?.locationId
      ? {
          id: data?.data?.locationId,
          name: data?.data?.locationName,
          timezone,
        }
      : null,
    categories: data?.data?.eventCategories?.map(({ id }) => String(id)) || [],
  };
};
