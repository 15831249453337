import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  display: "flex",
  position: "relative",
}));

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 7.5, 7.5, 7.5),
}));

export const StyledBox = styled(Box)(() => ({
  flexGrow: 1,
  height: "100vh",
  overflow: "auto",
}));
