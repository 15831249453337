import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { RichTextEditor } from "@atoms";
import { Wrap, StyledLabel } from "./styles";

const BuildingProcedures = () => {
  const { control } = useFormContext();

  return (
    <Box sx={{ p: "0 50px" }}>
      <StyledLabel>Body</StyledLabel>
      <Wrap>
        <Controller
          name="details.buildingProcedures"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              onChange={onChange}
              value={value.value}
              withEmptyState
            />
          )}
        />
      </Wrap>
    </Box>
  );
};

export default BuildingProcedures;
