import { useCallback, useEffect, useState } from "react";
import { bool, func, node, string } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { ReactComponent as EventsIcon } from "@assets/svgs/Events.svg";
import { ClearFilter, Label } from "@atoms";
import { formatDateToString } from "@helpers";
import {
  DATE_DEFAULT_FORMAT_TEMPLATE,
  MONTH_DEFAULT_FORMAT_TEMPLATE,
  DATE_YEARS_TEMPLATE,
} from "@constants";
import { CustomInputWrap, Placeholder, Wrap } from "./styles";

const DatePickerField = ({
  name,
  onChange,
  placeholder,
  label,
  errorText,
  placeholderText,
  isLabelBold,
  dateFormat,
  showMonthYearPicker,
  showResetButton,
  customWidth,
  ...props
}) => {
  const { control, getValues } = useFormContext();
  const [startDate, setStartDate] = useState("");

  const renderDayContents = (day) => <span>{day}</span>;

  const renderCustomInput = useCallback(
    (date) => (
      <CustomInputWrap
        role="button"
        error={!!errorText}
        customWidth={customWidth}
      >
        {placeholder && !date ? <Placeholder>{placeholder}</Placeholder> : null}
        {date
          ? formatDateToString(
              date,
              showMonthYearPicker
                ? MONTH_DEFAULT_FORMAT_TEMPLATE
                : DATE_DEFAULT_FORMAT_TEMPLATE
            )
          : ""}
        <EventsIcon />
      </CustomInputWrap>
    ),
    [placeholder, errorText]
  );

  const currentValue = getValues(name);

  useEffect(() => {
    if (currentValue) {
      setStartDate(new Date(currentValue));
    }
  }, [currentValue]);

  const handleReset = useCallback(() => {
    setStartDate("");
    onChange && onChange("");
  }, []);

  return (
    <Box>
      {label ? (
        <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
      ) : null}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Wrap>
            <DatePicker
              selected={startDate}
              monthsShown={1}
              placeholderText={placeholderText}
              dateFormat={dateFormat}
              calendarStartDay={1}
              renderDayContents={renderDayContents}
              customInput={renderCustomInput(startDate)}
              showMonthYearPicker={showMonthYearPicker}
              onChange={(date) => {
                setStartDate(date);
                const formattedDate = formatDateToString(
                  date,
                  showMonthYearPicker
                    ? MONTH_DEFAULT_FORMAT_TEMPLATE
                    : DATE_YEARS_TEMPLATE
                );
                field.onChange(formattedDate);
                if (onChange) {
                  onChange(formattedDate);
                }
              }}
              {...props}
            />
            {showResetButton ? (
              <ClearFilter isVisible={!!startDate} onClick={handleReset} />
            ) : null}
          </Wrap>
        )}
      />
    </Box>
  );
};

DatePickerField.propTypes = {
  name: string.isRequired,
  onChange: func,
  placeholder: string,
  label: node,
  errorText: string,
  placeholderText: string,
  isLabelBold: bool,
  dateFormat: string,
  showMonthYearPicker: bool,
  showResetButton: bool,
  customWidth: string,
};

DatePickerField.defaultProps = {
  onChange: undefined,
  placeholder: "Select date",
  label: null,
  errorText: "",
  placeholderText: "",
  isLabelBold: false,
  dateFormat: "MMM d",
  showMonthYearPicker: false,
  showResetButton: false,
  customWidth: "140px",
};

export default DatePickerField;
