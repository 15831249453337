import { bool, string } from "prop-types";
import { alpha, Stack, Typography, useTheme } from "@mui/material";

import { Label } from "@atoms";

const TextWithLabel = ({ label, text, helperText, isTitleBold }) => {
  const { palette } = useTheme();

  return (
    <Stack>
      {label ? (
        <Label
          customColor={alpha(palette.dark, 0.7)}
          fontWeight={isTitleBold ? 600 : 400}
          marginBottom={1}
        >
          {label}
        </Label>
      ) : null}
      <Typography>{text}</Typography>
      {helperText ? (
        <Typography color={alpha(palette.dark, 0.7)}>{helperText}</Typography>
      ) : null}
    </Stack>
  );
};

TextWithLabel.defaultProps = {
  label: "",
  text: "",
  helperText: "",
  isTitleBold: false,
};

TextWithLabel.propTypes = {
  label: string,
  text: string,
  helperText: string,
  isTitleBold: bool,
};

export default TextWithLabel;
