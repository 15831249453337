import { Checkbox, TextField, Autocomplete } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { string, shape, number, arrayOf } from "prop-types";
import CheckBoxOutlineBlank from "@mui/icons-material/CheckBoxOutlineBlank";
import { CheckBox as CheckBoxIcon } from "@mui/icons-material";

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MultipleSelect = ({ label, placeholder, name, options, values }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <Autocomplete
          multiple
          limitTags={2}
          options={options}
          disableCloseOnSelect
          getOptionLabel={(option) => option.name}
          onChange={(_, data) => onChange(data)}
          isOptionEqualToValue={(option, value) => option.name === value.name}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                {...field}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder={placeholder} />
          )}
          style={{
            maxWidth: "400px",
            width: "100%",
          }}
          value={values}
        />
      )}
    />
  );
};

MultipleSelect.defaultProps = {
  options: [],
  values: [],
};

MultipleSelect.propTypes = {
  label: string.isRequired,
  placeholder: string.isRequired,
  name: string.isRequired,
  options: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ),
  values: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ),
};

export default MultipleSelect;
