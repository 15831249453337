export const getManagersList = (managers) =>
  managers?.map((item) => ({
    id: item.id,
    name: `${item.firstName} ${item.lastName}`,
    value: item.id,
  }));

export const getFeaturesList = (features) =>
  features?.map((item) => ({
    label: item.displayName,
    value: String(item.id),
  }));

export const getCompaniesList = (companies) =>
  companies?.map((item) => ({
    ...item,
    value: item.value,
  }));

const prepareGallery = (values) =>
  values
    .map((file, index) => {
      let formData;

      if (typeof file === "object") {
        formData = new FormData();
        if (file === null) {
          formData.append("image", file);
        } else {
          formData.append("image", file, file.name);
        }
        formData.append("isCover", index === 0);
        formData.append("position", index);
      }

      return formData;
    })
    .filter((formData) => !!formData);

export const prepareFormValues = (values) => {
  const {
    features,
    gallery0,
    gallery1,
    gallery2,
    companyLimits,
    communityManagerLimits,
    status,
    cmNotificationEnabled,
    ...data
  } = values;

  return {
    ...data,
    status: status ? "active" : "inactive",
    cmNotificationEnabled,
    gallery: prepareGallery([gallery0, gallery1, gallery2]),
    features: features.map((item) => +item),
    companyLimits: companyLimits.map((item) => item.id),
    communityManagerLimits: communityManagerLimits.map((item) => item.id),
  };
};

export const createRoomFormValues = (data = {}) => {
  const {
    features = [],
    limitAccess,
    gallery,
    cmNotificationEnabled,
    status,
  } = data;

  const coverImage = gallery?.find((item) => item.isCover);
  const firstImage = gallery?.find((item) => item.position === 1);
  const secondImage = gallery?.find((item) => item.position === 2);

  return {
    status: status === "active",
    features: features.map((item) => String(item.id)),
    companyLimits: limitAccess?.company,
    communityManagerLimits: getManagersList(limitAccess?.communityManager),
    cmNotificationEnabled,
    gallery0: coverImage?.image,
    gallery1: firstImage?.image,
    gallery2: secondImage?.image,
  };
};
