import { createTheme } from "@mui/material/styles";
import { palette } from "./palette";
import { shape } from "./shape";
import { breakpoints } from "./breakpoints";
import { typography } from "./typography";
import { components } from "./components";
import { mixins } from "./mixins";

const theme = createTheme({
  palette,
  spacing: 4,
  shape,
  breakpoints,
  typography,
  components,
  mixins,
});

export default theme;
