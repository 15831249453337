import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { DatePickerField, TreeSelect } from "@molecules";
import { FiltersContainer } from "@organisms";
import {
  useEventCategoriesDictionary,
  usePlacesWithLocationsDictionary,
} from "@hooks";
import {
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
} from "@atoms";
import { reportEventStatusesItems } from "@constants";
import { transformCategoriesStructureFull } from "@views/Event/utils";
import { getDestinationsOptionsByLocationId } from "@features/pushNotifications/CreatePushModal/utils";
import { ReactComponent as ExportIcon } from "@assets/svgs/Export.svg";
import { downloadFile, getFiltersForPage, setFiltersForPage } from "@helpers";
import {
  getEventsReportsCSV,
  getEventsReportsList,
  getEventsReportsTotal,
} from "@requests";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";

import {
  IconWrap,
  ItemTitle,
  StyledLink,
  Superscription,
  SuperscriptionItem,
  TotalItemsWrap,
} from "../styles";

import { columns } from "./columns";

const EventsReport = () => {
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    page: 1,
    locationIds: [locationId],
    ...getFiltersForPage("events-report"),
  });

  const [isCSVLoading, setIsCSVLoading] = useState(false);

  useEffect(() => {
    setFiltersForPage("events-report", filters);
  }, [filters]);

  const { data: reportsListData, isLoading: isReportsListDataLoading } =
    useQuery({
      queryKey: ["eventsReportsList", filters],
      queryFn: getEventsReportsList,
    });

  const { data: reportsTotalData, isLoading: isReportsTotalDataLoading } =
    useQuery({
      queryKey: ["eventsReportsTotal", filters],
      queryFn: getEventsReportsTotal,
    });

  const { data: locationsData } = usePlacesWithLocationsDictionary();

  const { data: eventCategories } = useEventCategoriesDictionary();

  const destinationsOptions = useMemo(
    () =>
      getDestinationsOptionsByLocationId(
        locationsData?.data,
        filters.locationIds
      ),
    [locationsData]
  );

  const eventCategoriesOptions = useMemo(
    () => transformCategoriesStructureFull(eventCategories),
    [eventCategories]
  );

  const handleLocationChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationIds;

      if (data.length) {
        const [{ locationId }] = data;

        if (data.length !== 1 || locationId !== -1) {
          newData.locationIds = data.map(({ locationId }) => locationId);
        }
      }

      return newData;
    });
  }, []);

  const handleSearchEventNameChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.eventName = value;
      } else {
        delete newData.eventName;
      }
      return newData;
    });
  }, []);

  const handleCategoriesChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.categoryIds = [value];
      } else {
        delete newData.categoryIds;
      }
      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.status = value;
      } else {
        delete newData.status;
      }
      return newData;
    });
  }, []);

  const handleFromDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.fromDate = value;
      } else {
        delete newData.fromDate;
      }
      return newData;
    });
  }, []);

  const handleToDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.toDate = value;
      } else {
        delete newData.toDate;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const handleCSV = useCallback(
    async (event) => {
      event.preventDefault();

      if (!isCSVLoading) {
        setIsCSVLoading(true);
        const data = await getEventsReportsCSV({
          queryKey: ["eventsReportsCSV", filters],
        });
        setIsCSVLoading(false);
        downloadFile(data);
      }
    },
    [filters, isCSVLoading]
  );

  const superscription = (
    <Superscription>
      <TotalItemsWrap>
        <SuperscriptionItem>
          <ItemTitle>Total events&nbsp;</ItemTitle>
          <span>{reportsListData?.pagination?.total}</span>
        </SuperscriptionItem>
        <SuperscriptionItem>
          <ItemTitle>Total attending&nbsp;</ItemTitle>
          <span>{reportsTotalData?.data?.attendings}</span>
        </SuperscriptionItem>
      </TotalItemsWrap>
      <StyledLink href="#csv" target="_blank" onClick={handleCSV}>
        {isCSVLoading && "⏳ "}
        <IconWrap>
          <ExportIcon />
        </IconWrap>
        Export
      </StyledLink>
    </Superscription>
  );

  const isLoading = isReportsListDataLoading || isReportsTotalDataLoading;

  return (
    <>
      <FiltersContainer defaultValues={filters} sx={{ pt: 0 }}>
        <Box sx={{ "& > div": { minWidth: "240px" } }}>
          <TreeSelect
            id="locationsFilter"
            name="locationIds"
            mode="multiSelect"
            options={destinationsOptions}
            onChange={handleLocationChange}
            texts={{ placeholder: "Location" }}
          />
        </Box>
        <SearchFieldFilter
          id="searchEventNameFilter"
          name="eventName"
          placeholder="Search Event name..."
          onChange={handleSearchEventNameChange}
        />
        <DropdownSimpleFilter
          id="categoriesFilter"
          name="categoryIds"
          label="Category"
          options={eventCategoriesOptions}
          onChange={handleCategoriesChange}
        />
        <DatePickerField
          id="fromDateFilter"
          name="fromDate"
          placeholder="From"
          onChange={handleFromDateChange}
          showResetButton
        />
        <DatePickerField
          id="toDateFilter"
          name="toDate"
          placeholder="To"
          onChange={handleToDateChange}
          showResetButton
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={reportEventStatusesItems}
          onChange={handleStatusChange}
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={reportsListData?.data}
          columns={columns}
          superscription={superscription}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={reportsListData?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default EventsReport;
