import { useEffect, useMemo, useState, useCallback, useContext } from "react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { UserContext } from "@context/UserContext";
import { addCombo, getCombineRooms, updateRoomGallery } from "@requests";
import { ROUTES } from "@constants";
import { Loader } from "@atoms";
import { LeftSidabarSection } from "@molecules";
import { LeaveModalContainer } from "@templates";
import { AddComboDetails, RoomFeatures, RoomGallery } from "@features";
import { palette } from "@styles/palette";
import {
  useErrorsToast,
  useFeaturesDictionary,
  usePlacesWithLocationsDictionary,
} from "@hooks";
import { displayToastSuccess, serverErrorHandler } from "@helpers";

import {
  getFeaturesList,
  prepareFormValues,
  transformLocationsStructure,
  createRoomFormValues,
} from "./utils";

const CombineRooms = () => {
  const navigate = useNavigate();

  const { isLoading: isFeaturesLoading, data: featuresData } =
    useFeaturesDictionary();
  const { data: locations } = usePlacesWithLocationsDictionary();

  const { mutate: mutateGallery } = useMutation(updateRoomGallery, {
    onError: serverErrorHandler,
  });

  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [searchedLocations, setSearchedLocations] = useState(locations?.data);

  const queryClient = useQueryClient();

  const {
    mutateAsync: AddCombo,
    isSuccess: isSuccessfulUpdate,
    isLoading: isUpdateLoading,
  } = useMutation((formData) => addCombo(formData), {
    onSuccess: () => {
      displayToastSuccess("Success", "Rooms are combined");
      queryClient.invalidateQueries(["rooms"]);
    },
    onError: serverErrorHandler,
  });

  const userLocation = useMemo(
    () => locations?.data?.find((location) => location.id === locationId),
    [locations, locationId]
  );

  const featuresList = useMemo(
    () => getFeaturesList(featuresData?.data),
    [featuresData?.data]
  );

  const values = useMemo(
    () => createRoomFormValues(featuresList, userLocation),
    [featuresData?.data]
  );

  const methods = useForm({
    defaultValues: {
      features: [],
      firstMeetingRoom: 0,
      secondMeetingRoom: 0,
      cmNotificationEnabled: true,
      status: true,
    },
    values,
  });

  const {
    reset,
    handleSubmit,
    formState: { isDirty, errors },
    watch,
  } = methods;

  const { data: rooms } = useQuery({
    queryKey: [
      "combine_rooms",
      { locationId: watch("location")?.id ?? locationId },
    ],
    queryFn: getCombineRooms,
  });

  useErrorsToast(errors, "Rooms");

  const [showLeaveModal, setShowLeaveModal] = useState(
    isDirty && !isUpdateLoading
  );

  const onSubmit = async (values) => {
    const { gallery: preparedGallery, ...preparedValues } =
      prepareFormValues(values);

    const { data, status } = await AddCombo({ data: preparedValues });

    if (data && status === 201) {
      await Promise.all(
        preparedGallery.map((formData) =>
          mutateGallery({ id: data?.data?.id, data: formData })
        )
      );

      navigate(ROUTES.Rooms);
    }
  };

  const handleSearchLocations = useCallback(
    (searchValue) => {
      const lowered = searchValue.toLowerCase();
      const searchedLocationsOptions = locations?.data.filter(
        ({ title, city, country }) =>
          title.toLowerCase().includes(lowered) ||
          city?.toLowerCase().includes(lowered) ||
          country?.toLowerCase().includes(lowered)
      );
      setSearchedLocations(searchedLocationsOptions);
    },
    [locations?.data]
  );

  useEffect(() => {
    setShowLeaveModal(isDirty && !isUpdateLoading);
  }, [isDirty, isUpdateLoading]);

  useEffect(() => {
    if (isSuccessfulUpdate) {
      reset({}, { keepValues: true });
      setShowLeaveModal(false);
    }
  }, [isSuccessfulUpdate, reset]);

  const locationOptions = useMemo(
    () => transformLocationsStructure(searchedLocations),
    [searchedLocations]
  );

  // TODO: create a HOC component and handle loader logic there
  if (isFeaturesLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer when={showLeaveModal}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row">
            <LeftSidabarSection
              prevPageButtonContent="Back to Meeting Rooms"
              prevPageRoute={ROUTES.Rooms}
              title="Add Combo Room"
            >
              <AddComboDetails
                roomOptions={rooms}
                locationOptions={locationOptions}
                handleSearchLocations={handleSearchLocations}
              />
              <Button type="submit" variant="contained">
                Save
              </Button>
            </LeftSidabarSection>
            <Stack spacing={10} pt={12} pb={12} pr={12} pl={12}>
              <RoomGallery gallery={[]} />
              <RoomFeatures features={featuresList} />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default CombineRooms;
