import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { shape } from "prop-types";
import {
  BoldTitle,
  Container,
  FieldsWrap,
  FieldValue,
  ImageWrap,
  TimeWrap,
} from "../PastEvent/styles";
import { ImagePlaceholder } from "@atoms";
import { getZonedDateAndTimeFromUTC } from "@helpers";
import { usePlacesWithLocationsDictionary } from "@hooks";

import { Wrap } from "./styles";

const PastEventDetails = ({ details }) => {
  const {
    name,
    description,
    startDate,
    startTime,
    endDate,
    endTime,
    imageUrl,
    isRecurring,
    locationName,
    locationDescription,
    locationId,
  } = details;

  const { data: locations } = usePlacesWithLocationsDictionary();

  const [startDateZoned, startTimeZoned, endDateZoned, endTimeZoned] =
    useMemo(() => {
      const timezone = locations?.data?.find(
        ({ id }) => id === locationId
      )?.timeZone;

      const [startZonedDate, startZonedTime] = getZonedDateAndTimeFromUTC(
        startDate,
        startTime,
        timezone
      );
      const [endZonedDate, endZonedTime] = getZonedDateAndTimeFromUTC(
        endDate,
        endTime,
        timezone
      );

      return [startZonedDate, startZonedTime, endZonedDate, endZonedTime];
    }, [details, locations]);

  return (
    <Wrap>
      <Container>
        <FieldsWrap>
          <BoldTitle>Event Name</BoldTitle>
          <FieldValue>{name}</FieldValue>
          <BoldTitle>Description of event</BoldTitle>
          <FieldValue>{description}</FieldValue>
          <BoldTitle>Location</BoldTitle>
          <FieldValue>{locationName}</FieldValue>
          {locationDescription ? (
            <>
              <BoldTitle>Location Details</BoldTitle>
              <FieldValue sx={{ mb: 0 }}>{locationDescription}</FieldValue>
            </>
          ) : null}
        </FieldsWrap>
        <Box>
          <BoldTitle>Event Image</BoldTitle>
          {imageUrl ? (
            <ImageWrap>
              <img src={imageUrl} alt="past-event-image" />
            </ImageWrap>
          ) : (
            <ImagePlaceholder />
          )}
        </Box>
      </Container>
      <Box sx={{ mb: 7.5 }}>
        <Typography variant="h3" sx={{ mb: 5 }}>
          Time & Duration
        </Typography>
        <BoldTitle sx={{ mb: 4.5 }}>
          {`${isRecurring ? "Recurring" : "One-time"} event`}
        </BoldTitle>
        <BoldTitle>Starts</BoldTitle>
        <FieldValue>
          {startDateZoned}
          <TimeWrap>{startTimeZoned}</TimeWrap>
        </FieldValue>
        {isRecurring ? (
          <>
            <BoldTitle>Ends</BoldTitle>
            <FieldValue>
              {endDateZoned}
              <TimeWrap>{endTimeZoned}</TimeWrap>
            </FieldValue>
          </>
        ) : null}
      </Box>
    </Wrap>
  );
};

PastEventDetails.defaultProps = {
  details: {},
};

PastEventDetails.propTypes = {
  details: shape({}),
};

export default PastEventDetails;
