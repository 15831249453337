import mixpanel from "mixpanel-browser";
import { MIXPANEL_PROJECT_TOKEN } from "@constants";

export const initMixPanel = () => {
  try {
    mixpanel.init(MIXPANEL_PROJECT_TOKEN, {
      debug: false,
      ignore_dnt: true,
    });
  } catch (e) {
    console.error(`MixPanel can't be initialized`);
  }
};
