import { useMemo, forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { bool, node, string, elementType } from "prop-types";
import { Typography } from "@mui/material";
import { Icon as IconComponent } from "@atoms";
import { palette } from "@styles/palette";
import { StyledListItem } from "./styles";

const NavLink = ({ to, Icon, children, isActive }) => {
  const renderLink = useMemo(
    () =>
      forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <StyledListItem
      sx={{
        backgroundColor: isActive ? palette.gray[400] : "none",
      }}
      component={renderLink}
    >
      <IconComponent
        SVG={Icon}
        color={isActive ? palette.lightGreen : "#fff"}
      />
      <Typography variant="caption" sx={{ ml: 2 }}>
        {children}
      </Typography>
    </StyledListItem>
  );
};

NavLink.propTypes = {
  to: string.isRequired,
  isActive: bool.isRequired,
  Icon: elementType,
  children: node,
};

NavLink.defaultProps = {
  Icon: null,
  children: null,
};

export default NavLink;
