import { useLocation } from "react-router-dom";
import { Link } from "@mui/material";
import { NavLink } from "@molecules";
import { ROUTES } from "@constants";
import { ReactComponent as Logo } from "@assets/svgs/Logo.svg";
import { NavList, StyledDrawer, LogoWrap, StyledList } from "./styles";
import { navigationItems } from "./utils";

const Sidebar = () => {
  const location = useLocation();

  return (
    <StyledDrawer variant="permanent">
      <Link href={ROUTES.Home}>
        <LogoWrap>
          <Logo />
        </LogoWrap>
      </Link>
      <StyledList component="nav">
        <NavList>
          {navigationItems.map(({ route, icon, name }) => (
            <li key={name}>
              <NavLink
                to={route}
                Icon={icon}
                isActive={location.pathname.startsWith(route)}
              >
                {name}
              </NavLink>
            </li>
          ))}
        </NavList>
      </StyledList>
    </StyledDrawer>
  );
};

export default Sidebar;
