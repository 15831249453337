import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));
