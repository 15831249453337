import { useCallback, useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import { arrayOf, func, number, shape, string } from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { Wrap } from "./styles";
import { ClearFilter } from "@atoms";

const DropdownSimple = ({ id, name, label, options, onChange, ...props }) => {
  const { control, setValue, getValues } = useFormContext();

  const [inputValue, setInputValue] = useState(getValues(name) || "");

  const handleOnChange = useCallback(
    ({ target: { value } }) => {
      if (name) setValue(name, value);
      setInputValue(value);
      onChange && onChange(value);
    },
    [onChange]
  );

  const handleReset = useCallback(() => {
    if (name) setValue(name, "");
    setInputValue("");
    onChange && onChange("");
  }, [onChange]);

  return (
    <Wrap>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <TextField
              select
              id={id}
              label={label}
              {...props}
              {...field}
              value={inputValue}
              onChange={handleOnChange}
            >
              {options.map(
                ({ id: optionId, name: optionName, value: optionValue }) => (
                  <MenuItem key={optionId} value={optionValue}>
                    {optionName}
                  </MenuItem>
                )
              )}
            </TextField>
            <ClearFilter isVisible={!!field.value} onClick={handleReset} />
          </>
        )}
      />
    </Wrap>
  );
};

DropdownSimple.defaultProps = {
  onChange: undefined,
  name: "",
};

DropdownSimple.propTypes = {
  id: string.isRequired,
  name: string,
  label: string,
  options: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  onChange: func,
};

export default DropdownSimple;
