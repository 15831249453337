import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { ControlledTextField, Label, PlacesAutocomplete } from "@atoms";
import { ReactComponent as FacebookField } from "@assets/svgs/FacebookField.svg";
import { ReactComponent as InstagramField } from "@assets/svgs/InstagramField.svg";

const ContactDetailsSection = () => {
  const {
    setValue,
    formState: { errors },
  } = useFormContext();

  const handleSelectCoordinates = (latitude, longitude) => {
    setValue("latitude", latitude);
    setValue("longitude", longitude);
  };

  return (
    <>
      <Typography variant="h3" sx={{ mb: 4 }}>
        Partner Contact Details
      </Typography>

      <Box sx={{ mb: 5 }}>
        <Label>Website</Label>
        <ControlledTextField name="website" placeholder="Website url" />
      </Box>
      <Box sx={{ mb: 5 }}>
        <Label>Social networks</Label>
        <ControlledTextField
          name="instagram"
          placeholder="Instagram Url"
          icon={<InstagramField />}
          sx={{ mb: 3 }}
        />
        <ControlledTextField
          name="facebook"
          placeholder="Facebook Url"
          icon={<FacebookField />}
          sx={{ mb: 3 }}
        />
      </Box>

      <Box sx={{ mb: 5 }}>
        <Label>Phone number</Label>
        <ControlledTextField
          name="phone"
          type="tel"
          placeholder="+972111222333"
        />
      </Box>

      <Box sx={{ mb: 5 }}>
        <Label>Address</Label>
        <PlacesAutocomplete
          name="address"
          placeholder="example: 1480 Mainstraße, Berlin, Germany"
          id="benefits-places-autocomplete"
          onSelectCoordinates={handleSelectCoordinates}
          error={errors?.address?.message}
        />
      </Box>
    </>
  );
};

export default ContactDetailsSection;
