import { ROLES } from "@constants/roles";

export const REGEXP_EMAIL =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BACKSPACE_KEY = "Backspace";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ENV = process.env.REACT_APP_ENV;

export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;

export const MIXPANEL_PROJECT_TOKEN =
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN;

export const UNAUTHORIZED = 401;

export const TOKEN = "token";
export const USER_ID = "userId";
export const AMPLIFY_AUTH_USER = "amplifyAuthUser";

export const SIDEBAR_WIDTH = 190;

export const FILE_MAX_SIZE = 10485760; // 10 MiB
export const FILE_NAME_MAX_LENGTH = 255;
export const HEADER_HEIGHT = 50;
export const WEEK_IN_DAYS = 7;

export const ROLES_ALLOWED_ON_CMS = [
  ROLES.Admin,
  ROLES.CommunityManager,
  ROLES.CMSEmployee,
];

export const DATE_MONTHS_FORMAT_TEMPLATE = "MMM D";
export const DATE_DEFAULT_FORMAT_TEMPLATE = "MMM D, YYYY";
export const MONTH_DEFAULT_FORMAT_TEMPLATE = "MMMM, YYYY";
export const DATE_FULL_MONTH_FORMAT_TEMPLATE = "MMMM D, YYYY";
export const DATE_YEARS_TEMPLATE = "YYYY-MM-DD";
export const DATE_DAYS_TEMPLATE = "DD/MM/YYYY";
export const TIME_TEMPLATE = "HH:mm";
export const DATE_TIME_TEMPLATE = "MMM DD, YYYY, H:mm";
export const DATE_TEMPLATE = "MMM DD, YYYY";

export const EMPTY_CONTENT = "<p></p>";
