import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  "& > a": {
    textDecoration: "none",
    color: "initial",
    display: "flex",
    alignItems: "center",
  },
}));

export const IconWrap = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
