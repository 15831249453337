import { string, arrayOf, shape, number } from "prop-types";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const CheckboxesGroup = ({ options, name, gridProps, gridSize }) => {
  const { control } = useFormContext();

  return (
    <FormGroup>
      <Grid container {...gridProps}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              {options.map(({ value: optionValue, label }) => (
                <Grid item key={optionValue} xs={gridSize}>
                  <FormControlLabel
                    label={label}
                    control={
                      <Checkbox
                        disableRipple
                        value={optionValue}
                        checked={field.value?.includes(optionValue)}
                        onChange={(event, checked) => {
                          if (checked) {
                            field.onChange([
                              ...field.value,
                              event.target.value,
                            ]);
                          } else {
                            field.onChange(
                              field.value.filter(
                                (value) => value !== event.target.value
                              )
                            );
                          }
                        }}
                      />
                    }
                  />
                </Grid>
              ))}
            </>
          )}
        />
      </Grid>
    </FormGroup>
  );
};

CheckboxesGroup.defaultProps = {
  gridProps: {},
  gridSize: 12,
};

CheckboxesGroup.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  name: string.isRequired,
  gridProps: shape({}),
  gridSize: number,
};

export default CheckboxesGroup;
