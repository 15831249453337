import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Superscription = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const TotalItemsWrap = styled(Box)(() => ({
  display: "flex",
}));

export const SuperscriptionItem = styled(Typography)(({ theme }) => ({
  display: "flex",
  position: "relative",
  paddingRight: theme.spacing(3),
  marginRight: theme.spacing(3),
  "&:last-child": {
    "&::after": {
      content: "none",
    },
  },
  "&::after": {
    content: "''",
    display: "inline-block",
    position: "absolute",
    right: 0,
    top: "2px",
    width: "1px",
    height: "15px",
    backgroundColor: theme.palette.gray[200],
  },
}));

export const ItemTitle = styled("span")(() => ({
  fontWeight: 700,
}));

export const StyledLink = styled("a")(({ theme }) => ({
  color: theme.palette.darkGreen,
  textDecoration: "none",
  fontWeight: 600,
}));

export const IconWrap = styled("span")(({ theme }) => ({
  marginRight: theme.spacing(1),
}));
