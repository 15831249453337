import { node, string, func } from "prop-types";
import { MenuItemButton } from "./styles";

const MenuItem = ({ icon, title, action, isActive = null }) => (
  <MenuItemButton
    className={`${isActive && isActive() ? " is-active" : ""}`}
    onClick={action}
    title={title}
    type="button"
  >
    {icon}
  </MenuItemButton>
);

MenuItem.defaultProps = {
  isActive: null,
};

MenuItem.propTypes = {
  icon: node.isRequired,
  title: string.isRequired,
  action: func.isRequired,
  isActive: func,
};

export default MenuItem;
