import { toast } from "react-toastify";
import { Toast } from "@atoms";

export const displayToastError = (title, description) => {
  toast.error(<Toast title={title} description={description} />);
};

export const displayToastSuccess = (title, description) => {
  toast.success(<Toast title={title} description={description} />);
};
