import { number, node } from "prop-types";
import { Wrap } from "./styles";

const TabPanel = ({ value, index, children }) => {
  if (value !== index) return null;

  return (
    <Wrap
      role="tabpanel"
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
    </Wrap>
  );
};

TabPanel.defaultProps = {
  children: null,
};

TabPanel.propTypes = {
  value: number.isRequired,
  index: number.isRequired,
  children: node,
};

export default TabPanel;
