import { Locations as View } from "@views";
import { Layout } from "@templates";

const Locations = () => (
  <Layout>
    <View />
  </Layout>
);

export default Locations;
