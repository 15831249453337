import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Container = styled(Box)(({ theme }) => ({
  position: "relative",
  padding: theme.spacing(3.5),
  backgroundColor: theme.palette.common.white,
  borderRadius: "6px",
  border: `1px solid ${theme.palette.gray[700]}`,
}));
