import { useCallback, useEffect, useMemo, useState, useContext } from "react";
import { bool, func, number } from "prop-types";
import { FormProvider, useForm } from "react-hook-form";
import { Stack, Typography } from "@mui/material";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { ReactComponent as SaveIcon } from "@assets/svgs/Save.svg";
import { ReactComponent as SendIcon } from "@assets/svgs/Send.svg";
import {
  DatePickerField,
  ModalContainer,
  TextareaWithEmojis,
  TextFieldWithLimit,
  TreeSelect,
} from "@molecules";
import { TimePickerField, Toggle } from "@atoms";
import { LeaveModalContainer } from "@templates";
import { useErrorsToast, usePlacesWithLocationsDictionary } from "@hooks";
import {
  createPushNotification,
  getPushNotificationById,
  sendNowPushNotification,
  sendTestPushNotification,
  updatePushNotification,
} from "@requests";
import {
  displayToastSuccess,
  serverErrorHandler,
  trackInteraction,
} from "@helpers";
import { UserContext } from "@context/UserContext";

import {
  checkMoreThanOneCity,
  getDestinationsOptions,
  getDestinationsOptionsByLocationId,
  prepareFormValues,
  transformDestinationsToFormValue,
} from "./utils";
import { schema } from "./schema";

const defaultValues = {
  label: "",
  content: "",
  destinations: [],
  scheduled: false,
  time: null,
  date: "",
};

const CreatePushModal = ({ isOpen, onClose, payload: id }) => {
  const { data: pushData, isFetching: isDataFetching } = useQuery({
    queryKey: ["pushNotification", id],
    queryFn: () => getPushNotificationById(id),
    enabled: !!id,
  });
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const { isLoading, data: locationsData } = usePlacesWithLocationsDictionary();

  const { mutate: mutateSendTestPushNotification } = useMutation(
    sendTestPushNotification,
    {
      onSuccess: () => {
        trackInteraction("'Send test' button clicked", {
          scope: "PushNotifications",
        });
        displayToastSuccess("Success", "Push sent successfully");
      },
      onError: serverErrorHandler,
    }
  );

  const { mutate: mutateCreatePushNotification, isLoading: isCreateLoading } =
    useMutation(createPushNotification, {
      onError: serverErrorHandler,
    });

  const { mutate: mutateUpdatePushNotification, isLoading: isUpdateLoading } =
    useMutation(updatePushNotification, {
      onError: serverErrorHandler,
    });

  const { mutate: mutateSendNowPushNotification, isLoading: isSendLoading } =
    useMutation(sendNowPushNotification, {
      onSuccess: () => {
        onClose();
        trackInteraction("'Send now' button clicked", {
          scope: "PushNotifications",
        });
        displayToastSuccess("Success", "Push sent successfully");
      },
      onError: serverErrorHandler,
    });

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    watch,
    reset,
    getValues,
    setValue,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = methods;

  const scheduled = watch("scheduled");
  const destinations = watch("destinations");

  const [toggleDisabled, setToggleDisabled] = useState(false);

  const destinationsOptions = useMemo(
    () =>
      id && pushData?.data.id === id
        ? getDestinationsOptions(locationsData?.data, pushData.data.sentTo)
        : getDestinationsOptionsByLocationId(locationsData?.data, [locationId]),
    [locationsData, id, pushData]
  );

  const onSubmit = (values, toSave = false) => {
    const preparedValues = prepareFormValues(values, toSave);

    if (id) {
      mutateUpdatePushNotification(
        { id, data: preparedValues },
        {
          onSuccess: (data, variables) => {
            if (variables.data.toSave) {
              onClose();
              trackInteraction("'Save' button clicked", {
                scope: "PushNotifications",
              });
              displayToastSuccess("Success", "Push saved successfully");
            } else if (variables.data.scheduled) {
              onClose();
              trackInteraction("'Schedule' button clicked", {
                scope: "PushNotifications",
              });
              displayToastSuccess("Success", "Push scheduled successfully");
            } else {
              mutateSendNowPushNotification(id);
            }
          },
        }
      );
    } else {
      mutateCreatePushNotification(preparedValues, {
        onSuccess: (data, variables) => {
          if (variables.toSave) {
            onClose();
            trackInteraction("'Save' button clicked", {
              scope: "PushNotifications",
              newEntity: true,
            });
            displayToastSuccess("Success", "Push saved successfully");
          } else if (variables.scheduled) {
            onClose();
            trackInteraction("'Schedule' button clicked", {
              scope: "PushNotifications",
              newEntity: true,
            });
            displayToastSuccess("Success", "Push scheduled successfully");
          } else {
            mutateSendNowPushNotification(data?.data?.id);
          }
        },
      });
    }
  };

  const onSendTest = () => {
    const [label, content] = getValues(["label", "content"]);

    if (label && content) {
      mutateSendTestPushNotification({
        label,
        content,
      });
    } else {
      if (!label)
        setError("label", { type: "custom", message: "Required field" });

      if (!content)
        setError("content", { type: "custom", message: "Required field" });
    }
  };

  const defaultShowLeavePageModal =
    isDirty && !(isCreateLoading || isUpdateLoading || isSendLoading);

  const [showLeavePageModal, setShowLeavePageModal] = useState(
    defaultShowLeavePageModal
  );

  const [showLeaveModalOnClose, setShowLeaveModalOnClose] = useState(false);

  useEffect(() => {
    setShowLeavePageModal(defaultShowLeavePageModal);
  }, [defaultShowLeavePageModal]);

  useEffect(() => {
    const isScheduled = checkMoreThanOneCity(destinations, destinationsOptions);

    if (isScheduled) {
      setValue("scheduled", true);
      setToggleDisabled(true);
    } else {
      setToggleDisabled(false);
    }
  }, [destinations, destinationsOptions, setValue]);

  useEffect(() => {
    if (pushData && locationsData) {
      const { label, content, scheduled, sendDate, sendTime, sentTo } =
        pushData.data;

      reset({
        label,
        content,
        scheduled,
        date: sendDate || "",
        time: sendTime ? dayjs(`${sendDate}T${sendTime.slice(0, 5)}`) : null,
        destinations: transformDestinationsToFormValue(
          sentTo,
          locationsData.data
        ),
      });
    }
  }, [pushData, locationsData, reset]);

  useEffect(() => {
    if ((isOpen && pushData?.data?.id !== id) || (!isOpen && !id)) {
      reset(defaultValues);
    }
  }, [isOpen, pushData, id, reset]);

  const onHandleClose = useCallback(() => {
    if (!showLeavePageModal) {
      onClose();
    } else {
      setShowLeaveModalOnClose(true);
    }
  }, [onClose, showLeavePageModal]);

  const resetTriggerLeavePageModal = useCallback(() => {
    setShowLeaveModalOnClose(false);
  }, []);

  useErrorsToast(errors, "PushNotifications");

  return (
    <>
      <ModalContainer
        isOpen={isOpen}
        onClose={onHandleClose}
        title={id ? "Edit Notification" : "New Notification"}
        titleAriaLabel="create-push-modal-title"
        confirmButtonConfig={{
          title: scheduled ? "Schedule" : "Send now",
          onClick: handleSubmit((data) => onSubmit(data, false)),
          disabled: isLoading || isDataFetching,
        }}
        actionButtonConfig={{
          title: "Send test",
          onClick: onSendTest,
          disabled: isLoading || isDataFetching,
          icon: <SendIcon />,
        }}
        cancelButtonConfig={{
          title: "Save",
          onClick: handleSubmit((values) => onSubmit(values, true)),
          disabled: isLoading || isDataFetching,
          icon: <SaveIcon />,
        }}
      >
        {isLoading || isDataFetching ? (
          <Typography>Loading...</Typography>
        ) : (
          <FormProvider {...methods}>
            <form>
              <Stack spacing={6}>
                <TextFieldWithLimit
                  name="label"
                  label="Label"
                  charactersLimit={60}
                  fullWidth
                  isLabelBold
                  error={!!errors?.label?.message}
                  placeholder="Short description of this notification"
                />
                <TextareaWithEmojis
                  name="content"
                  label="Content"
                  multiline
                  charactersLimit={100}
                  fullWidth
                  isLabelBold
                  error={!!errors?.content?.message}
                  placeholder="Push notification content"
                />
                <TreeSelect
                  name="destinations"
                  options={destinationsOptions}
                  id="destinations"
                  label="Send to"
                  error={errors?.destinations?.message}
                />
                <Stack spacing={3}>
                  <Toggle
                    name="scheduled"
                    id="scheduled"
                    label="Schedule for later"
                    disabled={toggleDisabled}
                  />
                  <Stack direction="row" spacing={3}>
                    <DatePickerField
                      name="date"
                      placeholderText="Date"
                      disabled={!scheduled}
                      minDate={new Date()}
                      errorText={errors?.date?.message}
                    />
                    <TimePickerField
                      name="time"
                      id="time-scheduled"
                      disabled={!scheduled}
                      errorText={errors?.time?.message}
                    />
                  </Stack>
                  {scheduled ? (
                    <Typography>
                      Push notifications will be sent in the local time zone for
                      selected locations.
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
            </form>
          </FormProvider>
        )}
      </ModalContainer>
      <LeaveModalContainer
        when={showLeavePageModal}
        showByTrigger={showLeaveModalOnClose}
        resetTrigger={resetTriggerLeavePageModal}
        handleConfirm={onClose}
      />
    </>
  );
};

CreatePushModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  payload: null,
};

CreatePushModal.propTypes = {
  isOpen: bool,
  onClose: func,
  payload: number,
};

export default CreatePushModal;
