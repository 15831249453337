import { node, bool } from "prop-types";
import { Toolbar } from "@mui/material";
import { Header, Sidebar } from "@organisms";
import { StyledBox, Wrap, StyledContainer } from "./styles";

const Layout = ({ withContainer, children }) => (
  <Wrap>
    <Header />
    <Sidebar />
    <StyledBox component="main">
      <Toolbar />
      {withContainer ? <StyledContainer>{children}</StyledContainer> : children}
    </StyledBox>
  </Wrap>
);

Layout.propTypes = {
  children: node.isRequired,
  withContainer: bool,
};

Layout.defaultProps = {
  withContainer: true,
};

export default Layout;
