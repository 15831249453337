import mixpanel from "mixpanel-browser";

export const identifyUser = (id) => {
  try {
    mixpanel.identify(id);
  } catch (e) {
    console.error(
      `MixPanel can't identify user, probably is it wasn't initialized well`
    );
  }
};
