/**
 *
 * @param {String} pageName
 * @param {Object} data
 * @returns {void}
 */
export const setFiltersForPage = (pageName, data) => {
  if (typeof sessionStorage === "undefined" || !sessionStorage) {
    return;
  }

  try {
    sessionStorage.setItem(`${pageName}-filters`, JSON.stringify(data));
  } catch (e) {
    console.warn(
      "Something's happened while saving filters data to localStorage"
    );
  }
};
