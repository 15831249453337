import { Announcements as View } from "@views";
import { Layout } from "@templates";

const Announcements = () => (
  <Layout>
    <View />
  </Layout>
);

export default Announcements;
