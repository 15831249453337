import { ReactComponent as Placeholder } from "@assets/svgs/Placeholder.svg";
import { Wrap } from "./styles";

const ImagePlaceholder = () => (
  <Wrap>
    <Placeholder />
  </Wrap>
);

export default ImagePlaceholder;
