import { useFormContext, Controller } from "react-hook-form";
import { bool, func, string } from "prop-types";
import { StyledFormControlLabel, StyledSwitch } from "./styles";

const Toggle = ({ name, id, label, isBig, onChange, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledFormControlLabel
          label={label}
          control={
            <StyledSwitch
              disableRipple
              size="small"
              id={id}
              onChange={(e) => {
                field.onChange(e.target.checked);
                if (onChange) {
                  onChange(e.target.checked);
                }
              }}
              checked={field.value}
              isBig={isBig}
            />
          }
          sx={{
            fontWeight: isBig ? 600 : 400,
            // eslint-disable-next-line react/prop-types
            ...{ ...(props.sx && { ...props.sx }) },
          }}
          {...props}
        />
      )}
    />
  );
};

Toggle.defaultProps = {
  onChange: undefined,
  isBig: false,
};

Toggle.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  label: string.isRequired,
  isBig: bool,
  onChange: func,
};

export default Toggle;
