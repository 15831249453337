import { useEffect } from "react";
import { node } from "prop-types";
import { UserContext } from "@context/UserContext";
import { useQuery } from "@tanstack/react-query";
import { getLocation, getMe } from "@requests";
import { identifyUser } from "@helpers";

const UserContextWrapper = ({ children }) => {
  const { data: userData, isLoading: isUserLoading } = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
  });

  useEffect(() => {
    if (userData?.data?.id) {
      identifyUser(userData?.data?.id);
    }
  }, [userData?.data?.id]);

  const { locationId } = userData?.data || {};

  const { data: locationData, isLoading: isLocationLoading } = useQuery({
    queryKey: ["location", locationId],
    queryFn: getLocation,
    enabled: !!locationId,
  });

  const isReady =
    !isUserLoading &&
    !isLocationLoading &&
    userData?.data &&
    locationData?.data;

  return isReady ? (
    <UserContext.Provider
      value={{
        userData: userData?.data,
        locationData: locationData?.data,
      }}
    >
      {children}
    </UserContext.Provider>
  ) : null;
};

UserContextWrapper.propTypes = {
  children: node,
};

export default UserContextWrapper;
