import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrap = styled(Box)(() => ({
  position: "absolute",
  top: 0,
  right: 0,
  "& > button": {
    backgroundColor: "white",
    border: "1px solid rgba(21,21,21,0.15)",
    borderRadius: "6px",
    boxSizing: "border-box",
    boxShadow: "1px 1px 4px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    width: "12px",
    height: "12px",
    position: "absolute",
    top: "-15px",
    right: "-3px",
    opacity: 0,
    transition: "0.3s ease",
  },
  "& > button.visible": {
    top: "-3px",
    opacity: 1,
  },
  "& button .MuiSvgIcon-root": {
    color: "rgb(21, 21, 21)",
    width: "10px",
    height: "10px",
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
