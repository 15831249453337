import { API_ENDPOINTS } from "@constants";
import { client } from "@utils";
import { pageSize } from "./config";

export { getRoomsList as getRoomsReportsList } from "./reports";
export { getRoomsTotal as getRoomsReportsTotal } from "./reports";
export { getRoomsCSV as getRoomsReportsCSV } from "./reports";
export { getEventsCSV as getEventsReportsCSV } from "./reports";
export { getEventsList as getEventsReportsList } from "./reports";
export { getCreditsList as getCreditsReportsList } from "./reports";
export { getEventsTotal as getEventsReportsTotal } from "./reports";

export const getMe = () =>
  client.authorized.get(`${API_ENDPOINTS.Me}`).then((res) => res.data);

export const getUser = () =>
  client.authorized.get(`${API_ENDPOINTS.User}`).then((res) => res.data);

/**
 * @returns {Promise<LocationsResponse>}
 */
export const getLocations = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(`${API_ENDPOINTS.Locations}`, { params: { ...params, pageSize } })
    .then((res) => res.data);
};

export const getPushNotifications = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(`${API_ENDPOINTS.PushNotifications}`, {
      params: { ...params, pageSize },
    })
    .then((res) => res.data);
};

export const getLocation = ({ queryKey }) => {
  const [, id] = queryKey;
  return client.authorized
    .get(`${API_ENDPOINTS.Locations}/${id}`)
    .then((res) => res.data);
};

/**
 * @returns {Promise<EventsResponse>}
 */
export const getEvents = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Events, { params: { ...params, pageSize } })
    .then((res) => res.data);
};

export const getEvent = ({ queryKey: [, id] }) =>
  client.authorized
    .get(`${API_ENDPOINTS.Events}/${id}`)
    .then((res) => res.data);

export const deleteEvent = (id) =>
  client.authorized.delete(`${API_ENDPOINTS.Events}/${id}`);

export const editEvent = (id, data) =>
  client.authorized.patch(`${API_ENDPOINTS.Events}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const publishEvent = (id) =>
  client.authorized.patch(`${API_ENDPOINTS.Events}/${id}/publish`);

export const unpublishEvent = (id) =>
  client.authorized.patch(`${API_ENDPOINTS.Events}/${id}/unpublish`);

export const getEventCategories = () =>
  client.authorized.get(API_ENDPOINTS.EventCategories).then((res) => res.data);

/**
 * @returns {Promise<EventAttendingsResponse>}
 */
export const getEventAttendings = (id, page) => {
  return client.authorized
    .get(`${API_ENDPOINTS.ReportsEvent}/${id}/attendings`, {
      params: { pageSize, page },
    })
    .then((res) => res.data);
};

/**
 * @returns {Promise<EventAvailableDatesResponse>}
 */
export const getEventAvailableDates = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.ReportsEvent}/${id}/available-dates`)
    .then((res) => res.data);

/**
 * @returns {Promise<EventAvailableDatesResponse>}
 */
export const getEventTotalAttendings = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.ReportsEvent}/${id}/attendings/total`)
    .then((res) => res.data);

/**
 * @returns {Promise<EventAttendingCSVResponse>}
 */
export const getEventAttendingsCSV = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.ReportsEvent}/${id}/attendings/csv`)
    .then((res) => res.data);

export const editLocation = (id, data) =>
  client.authorized.patch(`${API_ENDPOINTS.Locations}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const editLocationJSON = (id, data) =>
  client.authorized.patch(`${API_ENDPOINTS.Locations}/${id}`, data);

export const getAmenities = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Amenities, { params: { ...params, pageSize: 100 } })
    .then((res) => res.data);
};

/**
 * @returns {Promise<BenefitsResponse>}
 */
export const getBenefits = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Benefits, { params: { ...params, pageSize } })
    .then((res) => res.data);
};

/**
 * @returns {Promise<BenefitResponse>}
 */
export const getBenefit = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.Benefits}/${id}`)
    .then((res) => res.data);

export const addBenefit = (data) =>
  client.authorized.post(`${API_ENDPOINTS.Benefits}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const editBenefit = (id, data) =>
  client.authorized.patch(`${API_ENDPOINTS.Benefits}/${id}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

/**
 * @returns {Promise<RoomsResponse>}
 */
export const getRooms = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Rooms, { params: { ...params, pageSize } })
    .then((res) => res.data);
};

/* Dictionaries */

/**
 * @returns {Promise<PlaceWithLocationsResponse>}
 */
export const getPlacesWithLocations = () =>
  client.authorized
    .get(API_ENDPOINTS.PlacesWithLocations)
    .then((res) => res.data);

/**
 * @returns {Promise<BenefitCategoriesResponse>}
 */
export const getBenefitCategories = () =>
  client.authorized
    .get(`${API_ENDPOINTS.BenefitCategories}`)
    .then((res) => res.data);

export const getRoomDetails = (id) =>
  client.authorized.get(`${API_ENDPOINTS.Room}/${id}`).then((res) => res.data);

export const getCompanyNames = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.CompanyNames, { params })
    .then((res) => res.data);
};

/**
 * @returns {Promise<UsersRoomBookingsResponse>}
 */
export const getUsers = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.UserSearch, { params })
    .then((res) => res.data);
};

export const getManagers = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Managers, { params })
    .then((res) => res.data);
};

export const getCombineRooms = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(`${API_ENDPOINTS.Room}/list-for-combine`, { params })
    .then((res) => res.data);
};

export const getManagersSimple = () =>
  client.authorized.get(API_ENDPOINTS.Managers).then((res) => res.data);

export const updateRoomDetails = ({ id, data }) =>
  client.authorized.patch(`${API_ENDPOINTS.Room}/${id}`, data);

export const addCombo = ({ data }) =>
  client.authorized.post(`${API_ENDPOINTS.Room}/combine`, data);

export const postEvent = (data) =>
  client.authorized.post(`${API_ENDPOINTS.Events}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });

export const updateRoomGallery = ({ id, data }) =>
  client.authorized.post(`${API_ENDPOINTS.Room}/${id}/gallery`, data);

/**
 * @returns {Promise<PlacesResponse>}
 */
export const getPlaces = () =>
  client.authorized
    .get(API_ENDPOINTS.Places) // TODO: get info of how many places we will have
    .then((res) => res.data);

export const deleteRoomGallery = ({ id, position }) =>
  client.authorized.delete(`${API_ENDPOINTS.Room}/${id}/gallery/${position}`);

export const getFeatures = () =>
  client.authorized.get(API_ENDPOINTS.Features).then((res) => res.data);

export const sendTestPushNotification = (data) =>
  client.authorized.post(`${API_ENDPOINTS.PushNotifications}/send-test`, data);

export const createPushNotification = (data) =>
  client.authorized
    .post(API_ENDPOINTS.PushNotifications, data)
    .then((res) => res.data);

export const sendNowPushNotification = (id) =>
  client.authorized.post(`${API_ENDPOINTS.PushNotifications}/send-now/${id}`);

export const getPushNotificationById = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.PushNotifications}/${id}`)
    .then((res) => res.data);

export const updatePushNotification = ({ id, data }) =>
  client.authorized
    .patch(`${API_ENDPOINTS.PushNotifications}/${id}`, data)
    .then((res) => res.data);

/**
 * @returns {Promise<AnnouncementsResponse>}
 */
export const getAnnouncements = ({ queryKey }) => {
  const [, params] = queryKey;
  return client.authorized
    .get(API_ENDPOINTS.Announcements, {
      params: {
        ...params,
        pageSize,
      },
    })
    .then((res) => res.data);
};

export const getAnnouncement = (id) =>
  client.authorized
    .get(`${API_ENDPOINTS.Announcements}/${id}`)
    .then((res) => res.data);

export const addAnnouncement = (data) =>
  client.authorized.post(`${API_ENDPOINTS.Announcements}`, data);

export const editAnnouncement = (id, data) =>
  client.authorized.patch(`${API_ENDPOINTS.Announcements}/${id}`, data);
