import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  padding: "0 50px",
}));

export const GapWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "56px",
  marginBottom: "46px",
  borderBottom: `1px solid ${theme.palette.gray[500]}`,
}));
