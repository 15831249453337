import { Toolbar } from "@mui/material";
import { UserProfileMenu } from "@molecules";
import { ReactComponent as ContentManagerText } from "@assets/svgs/ContentManagerText.svg";
import { AppBar, Wrap } from "./styles";

const Header = () => (
  <AppBar position="absolute">
    <Toolbar>
      <Wrap>
        <ContentManagerText width="127" />
        <UserProfileMenu />
      </Wrap>
    </Toolbar>
  </AppBar>
);

export default Header;
