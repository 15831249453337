export const palette = {
  black: "#151515",
  dark: "#262424",
  gray: {
    50: "#C4C4C4",
    100: "#CDCDCD",
    200: "#8A8A8A",
    300: "#494949",
    400: "#505050",
    500: "#D9D9D9",
    600: "#FBFBFB",
    700: "rgba(21, 21, 21, 0.15)",
    800: "rgba(236, 236, 236, 0.25)",
  },
  white: "#FAFAFA",
  darkWhite: "#FAF8F6",
  cream: "#FAF9F6",
  darkGreen: "#326164",
  darkerGreen: "#114043",
  lightGreen: "#6BC2C8",
  lighterGreen: "#EBF9F1",
  red: "#E13550",
  darkRed: "#5B2029",
  lightRed: "#FBE7E8",
  mainBackground: "rgba(236, 236, 236, 0.25)",
  checkbox: "#378B91",
};
