import { useCallback } from "react";
import { bool, func } from "prop-types";
import { Close } from "@mui/icons-material";
import { Wrap } from "./styles";

const ClearFilter = ({ isVisible, onClick }) => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    onClick && onClick(event);
  }, []);

  return (
    <Wrap>
      <button className={isVisible ? "visible" : ""} onClick={handleClick}>
        <Close />
      </button>
    </Wrap>
  );
};

ClearFilter.propTypes = {
  isVisible: bool,
  onClick: func,
};

export default ClearFilter;
