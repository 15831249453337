import { useEffect } from "react";
import { func, number, string } from "prop-types";
import { LoginCarousel } from "@atoms";
import { PhoneNumberInput, TextInput } from "@atoms/Input";
import { Flex } from "@mixins";
import { palette } from "@styles/palette";
import { Error, PrimaryButon } from "./styles";

const RETURN_KEY_CODE = 13;

const WelcomeForm = ({
  onTabClick,
  activeTab,
  onFormSubmit,
  error,
  onEmailChange,
  onCountrySelect,
  selectedCountry,
  onPhoneNumberValueChange,
  phoneNumberValue,
  emailValue,
}) => {
  const tabs = [
    {
      name: "Email",
      component: (
        <TextInput
          hasError={!!error}
          placeholder="name@workemail.com"
          onChange={onEmailChange}
          value={emailValue}
        />
      ),
    },
    {
      name: "Phone",
      component: (
        <PhoneNumberInput
          hasError={!!error}
          selectedCountry={selectedCountry}
          onCountrySelect={onCountrySelect}
          phoneNumberValue={phoneNumberValue}
          onPhoneNumberValueChange={onPhoneNumberValueChange}
        />
      ),
    },
  ];

  useEffect(() => {
    const onKeyDown = ({ keyCode }) => {
      if (keyCode === RETURN_KEY_CODE) {
        onFormSubmit();
      }
      return;
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onFormSubmit]);

  return (
    <Flex
      width={{ xs: "241px", sm: "334px" }}
      flexDirection="column"
      alignItems="center"
    >
      <LoginCarousel
        activeTab={activeTab}
        tabs={tabs}
        onTabClick={onTabClick}
      />

      <Error
        my="10px"
        alignSelf="start"
        hasError={error}
        fontSize={{ xs: "12px", sm: "14px" }}
        color={palette.red}
      >
        {error}
      </Error>
      <PrimaryButon
        mt="15px"
        width="100%"
        py={{ xs: "8px", sm: "12px" }}
        onClick={onFormSubmit}
      >
        Send Code
      </PrimaryButon>
    </Flex>
  );
};

WelcomeForm.propTypes = {
  activeTab: number.isRequired,
  onTabClick: func.isRequired,
  onFormSubmit: func.isRequired,
  error: string.isRequired,
  onEmailChange: func.isRequired,
  onCountrySelect: func.isRequired,
  selectedCountry: string.isRequired,
  onPhoneNumberValueChange: func.isRequired,
  phoneNumberValue: string.isRequired,
  emailValue: string.isRequired,
};

export default WelcomeForm;
