import { number, func, string, arrayOf, exact, node } from "prop-types";
import { Flex, Typography } from "@mixins";
import { palette } from "@styles/palette";

const LoginCarousel = ({ activeTab, onTabClick, tabs }) => {
  const carousel = (
    <Flex mb="40px">
      {tabs.map(({ name }, index) => (
        <Flex
          key={name}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width={{ xs: "90px", sm: "130px" }}
          pointer
          onClick={onTabClick(index)}
        >
          <Typography
            fontSize={{ xs: "16px", sm: "20px" }}
            color={index === activeTab ? palette.black : palette.gray[200]}
            mb="10px"
            fontWeight={index === activeTab ? 600 : "normal"}
          >
            {name}
          </Typography>
          <Flex
            width="100%"
            backgroundColor={
              index === activeTab ? palette.black : palette.gray[200]
            }
            height={index === activeTab ? "3px" : "1px"}
          />
        </Flex>
      ))}
    </Flex>
  );

  return (
    <Flex flexDirection="column" alignItems="center" width="100%">
      {carousel}
      {tabs[activeTab].component}
    </Flex>
  );
};

LoginCarousel.propTypes = {
  activeTab: number.isRequired,
  onTabClick: func.isRequired,
  tabs: arrayOf(
    exact({
      name: string,
      component: node,
    })
  ).isRequired,
};

export default LoginCarousel;
