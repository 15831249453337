import { string, func } from "prop-types";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";

const ControlledCheckbox = ({
  name,
  label,
  defaultValue,
  onChange,
  ...props
}) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              {...field}
              checked={field.value}
              {...props}
              disableRipple
              onChange={({ target: { checked: targetChecked } }) => {
                field.onChange(targetChecked);
                if (onChange) {
                  onChange(targetChecked);
                }
              }}
            />
          }
        />
      )}
    />
  );
};

ControlledCheckbox.defaultProps = {
  onChange: undefined,
};

ControlledCheckbox.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  defaultValue: string,
  onChange: func,
};

export default ControlledCheckbox;
