import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { parseISOString } from "@helpers";
import { DATE_DEFAULT_FORMAT_TEMPLATE } from "@constants";

export const columns = [
  {
    id: 1,
    field: "title",
    headerName: "Package",
    sortable: false,
    renderCell: ({ value, row }) =>
      value ? (
        value !== "Total" ? (
          <a
            href="#"
            onClick={() => {
              window.open(row.link);
            }}
          >
            {value}
          </a>
        ) : (
          value
        )
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 2,
    field: "availableFromDate",
    headerName: "Start Date",
    width: 173,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseISOString(value, DATE_DEFAULT_FORMAT_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 3,
    field: "availableToDate",
    headerName: "Expire Date",
    width: 173,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseISOString(value, DATE_DEFAULT_FORMAT_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 4,
    field: "available",
    headerName: "Available",
    width: 150,
    sortable: false,
    renderCell: ({ value }) => value ?? <MissingValue />,
    flex: 1,
  },
  {
    id: 5,
    field: "used",
    headerName: "Used",
    width: 150,
    sortable: false,
    renderCell: ({ value }) => value ?? <MissingValue />,
    flex: 1,
  },
  {
    id: 6,
    field: "remaining",
    headerName: "Remaining",
    width: 150,
    sortable: false,
    renderCell: ({ value }) => value ?? <MissingValue />,
    flex: 0,
  },
];
