import { Location as View } from "@views";
import { Layout } from "@templates";

const Location = () => (
  <Layout withContainer={false}>
    <View />
  </Layout>
);

export default Location;
