import { useCallback, useMemo, useState } from "react";
import { func, string } from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { ClearFilter } from "@atoms";
import { NestedSingleSelect } from "@molecules";
import { usePlacesWithLocationsDictionary } from "@hooks";
import { transformLocationsStructure } from "@views/Event/utils";
import { findRecursively } from "@helpers";
import { Wrap } from "./styles";

// TODO: Use GenericSearchFilter
const LocationsFilter = ({ name, label, onChange }) => {
  const { control, setValue } = useFormContext();
  const { data: locations } = usePlacesWithLocationsDictionary();
  const [searchedLocations, setSearchedLocations] = useState(locations?.data);

  const handleSearchLocations = useCallback(
    (searchValue) => {
      const lowered = searchValue.toLowerCase();
      const searchedLocationsOptions = locations?.data.filter(
        ({ title, city, country }) =>
          title.toLowerCase().includes(lowered) ||
          city?.toLowerCase().includes(lowered) ||
          country?.toLowerCase().includes(lowered)
      );
      setSearchedLocations(searchedLocationsOptions);
    },
    [locations?.data]
  );

  const locationOptions = useMemo(
    () => transformLocationsStructure(searchedLocations),
    [searchedLocations]
  );

  const handleChange = useCallback((location) => {
    setValue(name, location.id);
    onChange && onChange(location);
  }, []);

  const handleReset = useCallback(() => {
    setValue(name, "");
    onChange && onChange(undefined);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Wrap>
          <NestedSingleSelect
            id="locationsFilter"
            name={name}
            customWidth="240px"
            option={findRecursively(
              locationOptions,
              "suboptions",
              "id",
              field.value
            )}
            options={locationOptions}
            onSearchChange={handleSearchLocations}
            onBlur={field.onBlur}
            onChange={handleChange}
            textFieldProps={{
              label,
            }}
            inputLabelProps={{
              shrink: true,
            }}
          />
          <ClearFilter isVisible={!!field.value} onClick={handleReset} />
        </Wrap>
      )}
    />
  );
};

LocationsFilter.propTypes = {
  name: string,
  label: string,
  onChange: func,
};

export default LocationsFilter;
