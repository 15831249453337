import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    padding: 0,
    "& .MuiAutocomplete-input": {
      padding: theme.spacing(1.5, 2),
    },
  },
}));
