import { node, func } from "prop-types";
import { Button } from "@mui/material";
import { ReactComponent as ButtonAddIcon } from "@assets/svgs/ButtonAddIcon.svg";

const AddButton = ({ children, onClick }) => (
  <Button
    startIcon={<ButtonAddIcon />}
    onClick={onClick}
    sx={{
      "&.MuiButton-root": {
        backgroundColor: "#326164",
      },
      "&.MuiButton-text": {
        color: "#fff",
      },
    }}
  >
    {children}
  </Button>
);

AddButton.propTypes = {
  children: node.isRequired,
  onClick: func,
};

AddButton.defaultProps = {
  onClick: undefined,
};

export default AddButton;
