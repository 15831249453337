import {
  LINKEDIN_URL,
  FACEBOOK_URL,
  UNSPLASH_URL,
  TWITTER_URL,
  YOUTUBE_URL,
} from "@constants/links";

import { ReactComponent as LinkedIn } from "@assets/svgs/LinkedIn.svg";
import { ReactComponent as Facebook } from "@assets/svgs/Facebook.svg";
import { ReactComponent as Unsplash } from "@assets/svgs/Unsplash.svg";
import { ReactComponent as Twitter } from "@assets/svgs/Twitter.svg";
import { ReactComponent as Youtube } from "@assets/svgs/Youtube.svg";

const socials = [
  {
    id: 1,
    icon: LinkedIn,
    handler: () => {
      window.open(LINKEDIN_URL);
    },
  },
  {
    id: 2,
    icon: Facebook,
    handler: () => {
      window.open(FACEBOOK_URL);
    },
  },
  {
    id: 3,
    icon: Unsplash,
    handler: () => {
      window.open(UNSPLASH_URL);
    },
  },
  {
    id: 4,
    icon: Twitter,
    handler: () => {
      window.open(TWITTER_URL);
    },
  },
  {
    id: 5,
    icon: Youtube,
    handler: () => {
      window.open(YOUTUBE_URL);
    },
  },
];

export default socials;
