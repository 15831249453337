import { Stack } from "@mui/material";
import { useCallback, useEffect, useState, useContext, useMemo } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "@atoms";
import { LeftSidabarSection } from "@molecules";
import { LeaveModalContainer } from "@templates";
import { ROUTES } from "@constants";
import { UserContext } from "@context/UserContext";
import { addAnnouncement, editAnnouncement, getAnnouncement } from "@requests";
import {
  displayToastSuccess,
  serverErrorHandler,
  trackInteraction,
} from "@helpers";
import { palette } from "@styles/palette";
import { AnnouncementInfoSection, AnnouncementSidebar } from "@features";
import { Column } from "./styles";
import { schema } from "./schema";
import {
  createRequestBody,
  defaultValues,
  generateDefaultFormValues,
} from "./utils";
import { useErrorsToast, usePlacesWithLocationsDictionary } from "@hooks";

const Announcement = () => {
  const { announcementId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const isEditMode = !!announcementId;
  const [defaultDates, setDefaultDates] = useState({
    startDate: "",
    endDate: "",
  });

  const [announcementConflict, setAnnouncementConflict] = useState(null);

  const { isLoading, data: announcementData } = useQuery({
    queryKey: ["announcement", announcementId],
    queryFn: () => (announcementId ? getAnnouncement(announcementId) : null),
  });
  const { data: locations } = usePlacesWithLocationsDictionary();

  const {
    userData: { locationId },
  } = useContext(UserContext);

  const userLocation = useMemo(
    () => locations?.data?.find((location) => location.id === locationId),
    [locations, locationId]
  );

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  useErrorsToast(errors, "Announcements");

  const hasEndDatePassed = useMemo(
    () =>
      announcementData?.data?.endDate <
      new Date().toISOString().substring(0, 10),
    [announcementData]
  );

  const errorHandler = (axiosError) => {
    const announcementConflictString =
      axiosError?.response?.data?.error?.response?.announcementConflict;
    if (announcementConflictString) {
      const fixedAnnouncementConflictString =
        announcementConflictString.replace("/announcement/", "/announcements/"); //fixing link from BE
      const fixedWithStyleAnnouncementConflictString =
        fixedAnnouncementConflictString
          .replaceAll("<p", `<p style='color: ${palette.red}'`)
          .replace("<a", `<a style='color: #326164'`); //fixing link from BE

      console.log(
        "fixedWithStyleAnnouncementConflictString: ",
        fixedWithStyleAnnouncementConflictString
      );
      setAnnouncementConflict(fixedWithStyleAnnouncementConflictString);
    } else {
      serverErrorHandler(axiosError);
    }
  };

  const { mutate: createAnnouncement, isLoading: isCreateAnnouncementLoading } =
    useMutation((formData) => addAnnouncement(formData), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["announcement"]);
        displayToastSuccess("Success", "Announcement is created");
        navigate(ROUTES.Announcements);
      },
      onError: errorHandler,
    });

  const { mutate: updateAnnouncement, isLoading: isUpdateAnnouncementLoading } =
    useMutation((formData) => editAnnouncement(announcementId, formData), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["announcement"]);
        displayToastSuccess("Success", "Announcement is updated");
        reset({}, { keepValues: true });
        navigate(ROUTES.Announcements);
      },
      onError: errorHandler,
    });

  const onSubmit = useCallback(
    async (formData) => {
      const announcementBody = createRequestBody({
        formData,
        locations: locations?.data,
      });

      if (isEditMode) {
        await updateAnnouncement(announcementBody);
      } else {
        await createAnnouncement(announcementBody);
      }

      trackInteraction("'Save' button clicked", {
        scope: "Announcements",
        newEntity: !isEditMode,
      });
    },
    [defaultDates, locations, isEditMode]
  );

  useEffect(() => {
    if (announcementData?.data && locations) {
      const formValues = generateDefaultFormValues(announcementData, locations);

      setDefaultDates({
        startDate: formValues.startDate,
        endDate: formValues.endDate,
      });

      reset(formValues);
    }
  }, [announcementData?.data, locations]);

  useEffect(() => {
    if (userLocation && !announcementId) {
      reset({
        ...defaultValues,
        locationIds: [
          {
            checked: true,
            label: userLocation?.title,
            locationId: userLocation?.id,
            value: userLocation?.title,
            _depth: 3,
          },
        ],
      });
    }
  }, [userLocation]);

  // TODO: create a HOC component and handle loader logic there
  if (isLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer
      when={
        isDirty && !(isCreateAnnouncementLoading || isUpdateAnnouncementLoading)
      }
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row">
            <LeftSidabarSection
              prevPageButtonContent="Back to Announcements"
              prevPageRoute={ROUTES.Announcements}
              title={
                announcementId
                  ? `${hasEndDatePassed ? "" : "Edit "} Announcement`
                  : "Add Announcement"
              }
            >
              <AnnouncementSidebar
                isSubmitButtonDisabled={
                  isCreateAnnouncementLoading || isUpdateAnnouncementLoading
                }
              />
            </LeftSidabarSection>
            <Stack spacing={10} pt={12} pb={12} pr={12} pl={12}>
              <Column>
                <AnnouncementInfoSection
                  announcement={announcementData}
                  announcementConflict={announcementConflict}
                />
              </Column>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default Announcement;
