import * as z from "zod";

export const notTimeSlottedSchema = z.object({
  name: z.string().trim().min(1, "Required"),
  description: z.string().trim().min(1, "Required"),
  locationDetails: z.string().trim(),
  image: z.union([z.string().min(1, "Required"), z.instanceof(File)]),
  date: z
    .object({
      eventType: z.union([z.literal("single"), z.literal("recurring")]),
      startDate: z.string().trim().min(1, "Required"),
      endDate: z.string().trim().optional(),
    })
    .refine((val) => (val.eventType === "recurring" ? val.endDate : true), {
      message: "Required",
      path: ["endDate"],
    }),
  startTime: z.union([
    z.object({
      $d: z.date(),
    }),
    z.null().refine((val) => val !== null, { message: "Required" }),
  ]),
  endTime: z.union([
    z.object({
      $d: z.date(),
    }),
    z.null().refine((val) => val !== null, { message: "Required" }),
  ]),
  frequency: z.string().trim().min(1, "Required"),
  isTimeSlotted: z.boolean(),
  capacityLimit: z.union([z.number(), z.string().trim()]),
  location: z
    .union([
      z.object({
        id: z.number(),
        name: z.string().min(1, "Required"),
        timezone: z.string(),
      }),
      z.null(),
    ])
    .refine((val) => val !== null, { message: "Required", path: ["name"] }),
  categories: z.string().array().min(1, "Required"),
});

export const timeSlottedSchema = notTimeSlottedSchema.extend({
  services: z.array(
    z.object({
      name: z.string().trim().min(1, "Required"),
      duration: z.number(),
      price: z.union([z.number(), z.string().trim()]),
      currency: z.string().trim(),
    })
  ),
});
