import { DialogContent, DialogActions, Button, Stack } from "@mui/material";
import { bool, func, node, string, number, shape } from "prop-types";

import { DialogTitle } from "@atoms";
import { StyledDialog, Title } from "./styles";

const renderButtons = (
  confirmButtonConfig,
  cancelButtonConfig,
  actionButtonConfig
) => {
  return (
    <>
      {cancelButtonConfig ? (
        <Button
          variant="text"
          onClick={cancelButtonConfig.onClick}
          startIcon={cancelButtonConfig.icon}
          disabled={cancelButtonConfig.disabled}
        >
          {cancelButtonConfig.title}
        </Button>
      ) : null}
      <Stack spacing={3} ml="auto" direction="row">
        {actionButtonConfig ? (
          <Button
            variant="text"
            onClick={actionButtonConfig.onClick}
            disabled={actionButtonConfig.disabled}
            startIcon={actionButtonConfig.icon}
          >
            {actionButtonConfig.title}
          </Button>
        ) : null}
        <Button
          variant="contained"
          onClick={confirmButtonConfig.onClick}
          disabled={confirmButtonConfig.disabled}
        >
          {confirmButtonConfig.title}
        </Button>
      </Stack>
    </>
  );
};

const ModalContainer = ({
  children,
  isOpen,
  onClose,
  title,
  titleAriaLabel,
  descriptionAriaLabel,
  maxWidth,
  confirmButtonConfig,
  cancelButtonConfig,
  actionButtonConfig,
}) => {
  return (
    <StyledDialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby={titleAriaLabel}
      aria-describedby={descriptionAriaLabel}
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: maxWidth || 620,
        },
      }}
    >
      <DialogTitle id={titleAriaLabel} onClose={onClose}>
        <Title>{title}</Title>
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        {renderButtons(
          confirmButtonConfig,
          cancelButtonConfig,
          actionButtonConfig
        )}
      </DialogActions>
    </StyledDialog>
  );
};

ModalContainer.defaultProps = {
  children: null,
  isOpen: false,
  onClose: () => {},
  title: "",
  titleAriaLabel: "",
  descriptionAriaLabel: "",
  maxWidth: null,
  cancelButtonConfig: null,
  actionButtonConfig: null,
};

ModalContainer.propTypes = {
  children: node,
  isOpen: bool,
  onClose: func,
  title: string,
  titleAriaLabel: string,
  descriptionAriaLabel: string,
  maxWidth: number,
  confirmButtonConfig: shape({
    title: string,
    icon: node,
    onClick: func,
    disabled: bool,
  }).isRequired,
  cancelButtonConfig: shape({
    title: string,
    icon: node,
    onClick: func,
    disabled: bool,
  }),
  actionButtonConfig: shape({
    title: string,
    icon: node,
    onClick: func,
    disabled: bool,
  }),
};

export default ModalContainer;
