import { Link } from "@mui/material";

import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { BootstrapTooltip, DataTableEllipsisCell, StatusLabel } from "@atoms";
import { MultiplyList } from "@molecules";
import { getCategories } from "@helpers";
import { ROUTES } from "@constants";

export const columns = [
  {
    id: 1,
    field: "eventName",
    headerName: "Event Name",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 2,
    field: "date",
    headerName: "Date",
    width: 173,
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 3,
    field: "location",
    headerName: "Location",
    width: 173,
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 4,
    field: "attending",
    headerName: "Attending",
    type: "actions",
    renderCell: ({ value, row }) =>
      value ? (
        <Link
          href={`${ROUTES.Events}/${row.eventMetadataId}?tab=attending&dateId=${row.id}`}
        >
          {value}
        </Link>
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 5,
    field: "category",
    headerName: "Category",
    renderCell: ({ value = [] }) =>
      value.length ? (
        <MultiplyList values={getCategories(value)} />
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 6,
    field: "status",
    headerName: "Status",
    sortable: false,
    renderCell: ({ value }) =>
      value ? <StatusLabel value={value} /> : <MissingValue />,
    flex: 1,
  },
];
