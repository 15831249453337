import DOMPurify from "dompurify";
import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { parseISOString } from "@helpers";
import { Flex } from "@mixins";
import { DATE_DEFAULT_FORMAT_TEMPLATE } from "@constants";
import { StatusLabel } from "@atoms";

export const columns = [
  {
    id: 1,
    field: "bookingRoomNumber",
    headerName: "Room",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 0,
  },
  {
    id: 2,
    field: "bookedAt",
    headerName: "Date Booked",
    width: 173,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseISOString(value, DATE_DEFAULT_FORMAT_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 3,
    field: "fromDateTime",
    headerName: "Meeting Date",
    width: 173,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseISOString(value, DATE_DEFAULT_FORMAT_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 4,
    field: "timeRange",
    headerName: "Meeting Time",
    width: 173,
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 5,
    field: "companyName",
    headerName: "Company Name",
    width: 200,
    sortable: false,
    renderCell: ({ value }) =>
      (
        <Flex
          display="block"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(value),
          }}
        />
      ) || <MissingValue />,
    flex: 2,
  },
  {
    id: 6,
    field: "bookedByName",
    headerName: "Booked By",
    width: 230,
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 2,
  },
  {
    id: 7,
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: ({ value }) => (
      <StatusLabel
        value={value}
        text={value === "active" ? "Booked" : "Cancelled, not refunded"}
      />
    ),
    flex: 2,
  },
  {
    id: 8,
    field: "payment",
    headerName: "Payment",
    width: 126,
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 0,
  },
];
