import { ReactComponent as BooleanIndicatorSVG } from "@assets/svgs/BooleanIndicator.svg";
import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { bool } from "prop-types";

const BooleanIndicator = ({ value }) =>
  value ? <BooleanIndicatorSVG /> : <MissingValue />;

BooleanIndicator.propTypes = {
  value: bool,
};

BooleanIndicator.defaultProps = {
  value: false,
};

export default BooleanIndicator;
