import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

import { VerificationCode as View } from "@views";
import { ROLES_ALLOWED_ON_CMS, ROUTES, USER_STORE_NAME } from "@constants";
import { login, setActivated } from "@store/user/duck";
import { storage } from "@utils";
import { getAmplifyConfig } from "@utils/amplify";

import { getMe } from "@requests";
import { useQuery } from "@tanstack/react-query";

const VerificationCode = () => {
  const { refetch: fetchMe } = useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    enabled: false,
  });

  const {
    user: { cognitoUser },
  } = useSelector((store) => store[USER_STORE_NAME]);

  const [isLoading, setIsLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState(
    Array.from({ length: 4 }).fill()
  );
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const handleAmplityCodeVerify = async (newCode) => {
    const sendCustomChallengeAnswerResponse =
      await Auth.sendCustomChallengeAnswer(cognitoUser, newCode.join(""));

    return sendCustomChallengeAnswerResponse?.signInUserSession?.accessToken
      ?.jwtToken;
  };

  const wait = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleCodeSubmit = async (newCode) => {
    await wait(250);
    setIsLoading(true);

    const token = await handleAmplityCodeVerify(newCode);

    if (!token) {
      setError("*Wrong security code");
      setIsLoading(false);
    } else {
      storage.setToken(token);

      const meResponse = await fetchMe();

      // TODO: get rid of this check when the BE work with Cognito Error will be ready
      if (!ROLES_ALLOWED_ON_CMS.includes(meResponse.data?.data?.role)) {
        setError("Forbidden resource");
        setIsLoading(false);
        storage.clear();
      } else {
        storage.setAmplifyAuthUser(location?.state?.source);
        dispatch(setActivated(true));
        navigate(ROUTES.PushNotifications);
      }
    }
  };

  const handlePinPaste = (e) => {
    const value = e.clipboardData.getData("text");
    const pastedPin = [];

    if (!Number.isNaN(parseInt(value, 10))) {
      Array.from({ length: value.length }).forEach((_, index) => {
        pastedPin[index] = Number(value[index]);
      });

      setVerificationCode(pastedPin);

      if (value.length === 4) {
        handleCodeSubmit(pastedPin);
      }
    }
  };

  useEffect(() => {
    if (!location?.state?.source || !cognitoUser.username) {
      navigate(ROUTES.Login);
    }

    const webClientId = location?.state?.source.includes("@")
      ? process.env.REACT_APP_AWS_EMAIL_CLIENT_ID
      : process.env.REACT_APP_AWS_PHONE_CLIENT_ID;

    Auth.configure(getAmplifyConfig(webClientId));

    window.addEventListener("paste", handlePinPaste);

    return () => {
      window.removeEventListener("paste", () => {});
    };
  }, []);

  const handleVerificationCodeChange = (pin, index) => {
    const newCode = [...verificationCode];

    newCode[index] = pin;

    setVerificationCode(newCode);

    if (newCode.every((value) => value || value === 0)) {
      handleCodeSubmit(newCode);
    }
  };

  const handleResendClick = async () => {
    dispatch(login({ cognitoUser: await Auth.signIn(location.state.source) }));
  };

  return (
    <View
      isLoading={isLoading}
      error={error}
      codeAddress={location?.state?.source}
      verificationCode={verificationCode}
      onVerificationCodeChange={handleVerificationCodeChange}
      onCodeSubmit={handleCodeSubmit}
      onResendClick={handleResendClick}
    />
  );
};

export default VerificationCode;
