import { ReactComponent as TrashBin } from "@assets/svgs/TrashBinX.svg";
import { func, node } from "prop-types";
import { Button } from "@mui/material";

const RemoveButton = ({ onClick, children, ...props }) => (
  <Button onClick={onClick} variant="text" startIcon={<TrashBin />} {...props}>
    {children}
  </Button>
);

RemoveButton.propTypes = {
  onClick: func,
  children: node.isRequired,
};

RemoveButton.defaultProps = {
  onClick: () => {},
};

export default RemoveButton;
