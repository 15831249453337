import { RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledRadioGroup = styled(RadioGroup)(({ theme }) => ({
  flexDirection: "row",
  "& .MuiFormControlLabel-root": {
    marginRight: theme.spacing(10),
    "&:last-child": {
      marginRight: 0,
    },
  },
}));
