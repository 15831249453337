import * as z from "zod";

export const schema = z.object({
  startDate: z.string().min(1, "Required field"),
  endDate: z.string().min(1, "Required field"),
  isHeadsUp: z.boolean(),
  title: z.string().trim().min(1, "Required field"),
  locationIds: z.array(z.any()).nonempty("Required field"),
  content: z.string().refine((value) => value && value !== "<p></p>", {
    message: "Required field",
  }),
  url: z.string().trim(),
  status: z.boolean(),
});
