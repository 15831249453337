import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { BootstrapTooltip } from "@atoms";
import { DatePickerField } from "@molecules";
import { parseStrictISOString, getTimeInMilliseconds } from "@helpers";
import { ReactComponent as InfoIcon } from "@assets/svgs/Info.svg";
import { EndDateLabel } from "./styles";

const DatePickersSection = () => {
  const {
    formState: { errors },
    getValues,
    setError,
    clearErrors,
  } = useFormContext();

  const onEndDateCheck = () => {
    const startDate = getValues("startDate");
    const endDate = getValues("endDate");

    if (endDate) {
      const formattedStartDate = new Date(startDate).getTime();
      const formattedEndDate = new Date(endDate).getTime();

      if (formattedStartDate > formattedEndDate) {
        setError("endDate", {
          type: "custom",
          message: "End date should be after the start date",
        });
      } else {
        clearErrors("endDate");
      }
    }
  };

  const onChangeDate = (selectedDate, name) => {
    const dateNowFormatted = getTimeInMilliseconds(
      parseStrictISOString(new Date())
    );
    const selectedDateFormatted = getTimeInMilliseconds(
      parseStrictISOString(new Date(selectedDate))
    );

    if (selectedDateFormatted < dateNowFormatted) {
      setError(name, {
        type: "custom",
        message: "Selected date could not be in past",
      });
    } else {
      clearErrors(name);
    }

    onEndDateCheck();
  };

  return (
    <>
      <Box sx={{ mb: 5 }}>
        <DatePickerField
          isLabelBold
          name="startDate"
          label="Start date *"
          errorText={errors?.startDate?.message}
          onChange={(date) => onChangeDate(date, "startDate")}
        />
      </Box>

      <Box sx={{ mb: 6 }}>
        <DatePickerField
          isLabelBold
          name="endDate"
          label={
            <EndDateLabel>
              End date&nbsp;
              <BootstrapTooltip
                title="Leave empty if Benefit doesn't have an expiration date"
                placement="top"
              >
                <InfoIcon />
              </BootstrapTooltip>
            </EndDateLabel>
          }
          errorText={errors?.endDate?.message}
          onChange={(date) => onChangeDate(date, "endDate")}
        />
      </Box>
    </>
  );
};

export default DatePickersSection;
