import { styled } from "@mui/material/styles";
import { Stack } from "@mui/material";

export const Wrap = styled(Stack)(({ theme }) => ({
  padding: "14px 0 21px 0",
  "& .dropdown-select": {
    backgroundColor: theme.palette.common.white,
  },
  "& .dropdown-trigger": {
    padding: "0 11px 0 4px !important",
    lineHeight: "18px !important",
    borderRadius: "4px",
  },
  "& .dropdown-trigger:not(.arrow)": {
    height: "32px",
  },
  "& .dropdown-select .dropdown-trigger": {
    border: "1px solid rgba(21,21,21,0.15)",
    borderRadius: "4px",
  },
  "& .react-datepicker-wrapper .MuiBox-root": {
    border: "1px solid rgba(21,21,21,0.15)",
    borderRadius: "4px",
  },
  "& .dropdown-select:hover .dropdown-trigger": {
    border: "1px solid #326164",
  },
  "& .react-datepicker-wrapper:hover .MuiBox-root": {
    border: "1px solid #326164",
  },
  "& .dropdown-select .dropdown-trigger:after": {
    right: "10px",
    top: "7px",
  },
  "& .dropdown-trigger input": {
    cursor: "pointer",
  },
  "& .dropdown-content": {
    top: "100% !important",
  },
  "& .react-dropdown-tree-select .dropdown .dropdown-content": {
    zIndex: 2,
  },
  "& .search": {
    padding: "2px 2px !important",
    height: "22px !important",
  },
  "& .MuiInputLabel-root": {
    top: "-9px",
  },
  "& .MuiInputLabel-shrink": {
    top: 0,
  },
  "& .MuiInputBase-input": {
    backgroundColor: theme.palette.common.white,
  },
  "& .MuiFormControlLabel-root": {
    height: "32px",
  },
  "& .tag": {
    height: "21px !important",
  },
  "& .react-datepicker__month-text--keyboard-selected": {
    backgroundColor: "#216ba5",
    color: theme.palette.common.white,
  },
  "& .react-datepicker__day--disabled": {
    color: "#ddd",
  },
}));
