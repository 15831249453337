import { useFormContext } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import { ControlledCheckbox, Toggle } from "@atoms";
import { Wrap } from "./styles";

const Controls = () => {
  const { setValue, getValues } = useFormContext();
  const defaultIsFeaturedValue = getValues("isFeatured");

  const [isFeaturedChecked, setFeaturedChecked] = useState(
    defaultIsFeaturedValue
  );

  const onChange = useCallback((checked) => {
    if (checked) {
      setValue("isFeatured", true);
      setFeaturedChecked(true);
    }
  }, []);

  useEffect(() => {
    setFeaturedChecked(defaultIsFeaturedValue);
  }, [defaultIsFeaturedValue]);

  return (
    <Wrap>
      <Toggle
        name="isFeatured"
        id="isFeaturedSwitch"
        label="Featured"
        defaultChecked={isFeaturedChecked}
        sx={{ mr: 13.5 }}
      />
      <ControlledCheckbox name="isNew" label="New" onChange={onChange} />
    </Wrap>
  );
};

export default Controls;
