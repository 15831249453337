import { bool, func, node, string } from "prop-types";
import { Loader } from "@atoms";
import { Footer } from "@molecules";
import { Flex, Typography } from "@mixins";
import AuthBackground from "@assets/images/AuthBackground.jpg";
import { ReactComponent as Dropdown } from "@assets/svgs/Dropdown.svg";
import { palette } from "@styles/palette";
import {
  ContentManagerTextWithMargin,
  LogoWithMargin,
  TextLink,
  BackButton,
} from "./styles";

const Auth = ({
  children,
  title,
  useLogo,
  text,
  isLoading,
  hasBackButton,
  onBackClick,
}) => {
  const onContactSupportClick = () => {
    const to = "app@mindspace.me";
    const subject = "Web App Support Request";

    window.location.href = `mailto:${to}?subject=${subject}`;
  };

  return (
    <>
      <Flex
        position="relative"
        flexDirection="column"
        pb={{ xs: 185, sm: 345, md: 295 }}
        pt={{ xs: 40, sm: 60 }}
        alignItems="center"
        minHeight="100vh"
        background={`linear-gradient(0.46deg, rgba(0, 0, 0, 0.8) 7.26%, rgba(0, 0, 0, 0) 99.61%), 
        url(${AuthBackground}) no-repeat center center fixed`}
        backgroundSize="cover"
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          p={{ xs: "30px", sm: "35px" }}
          mb={{ xs: "20px", sm: "40px" }}
          backgroundColor={palette.white}
          width={{ xs: "85%", sm: "450px" }}
          height="480px"
        >
          {hasBackButton && (
            <BackButton
              alignSelf="start"
              mb="20px"
              color={palette.black}
              width="15px"
              height="15px"
              SVG={Dropdown}
              pointer
              onClick={onBackClick}
            />
          )}
          {useLogo ? (
            <LogoWithMargin />
          ) : (
            <Typography mb="20px" fontSize={{ xs: "46px", sm: "60px" }}>
              {title}
            </Typography>
          )}
          <ContentManagerTextWithMargin />
          <Typography
            textAlign="center"
            color={palette.gray[300]}
            fontSize="16px"
            mb="30px"
          >
            {text}
          </Typography>
          {children}
        </Flex>
        <Flex flexDirection="column">
          <Typography
            color={palette.white}
            fontSize={{ xs: "12px", sm: "14px" }}
            width="100%"
            mb="6px"
          >
            Trouble logging in?
            <TextLink
              as="span"
              fontSize={{ xs: "12px", sm: "14px" }}
              onClick={onContactSupportClick}
              color={palette.white}
              pointer
            >
              {" "}
              Contact Support
            </TextLink>
          </Typography>
        </Flex>
        <Footer />
      </Flex>
      {isLoading && (
        <Loader
          position="fixed"
          background={palette.white}
          width={64}
          height={64}
        />
      )}
    </>
  );
};

Auth.propTypes = {
  hasBackButton: bool,
  onBackClick: func,
  children: node.isRequired,
  title: string,
  useLogo: bool,
  text: string.isRequired,
  isLoading: bool.isRequired,
};

Auth.defaultProps = {
  onBackClick: () => {},
  hasBackButton: false,
  title: undefined,
  useLogo: false,
};

export default Auth;
