import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useMemo, useContext } from "react";
import { string, bool, exact, number, arrayOf } from "prop-types";
import { RichTextEditorWithLimit, ControlledTextField, Label } from "@atoms";
import { TextFieldWithLimit, TreeSelect } from "@molecules";
import { UserContext } from "@context/UserContext";
import { usePlacesWithLocationsDictionary } from "@hooks";
import { Flex } from "@mixins";
import DOMPurify from "dompurify";
import { getDestinationsOptionsByLocationId } from "./utils";

const AnnouncementInfoSection = ({ announcementConflict, announcement }) => {
  const { data: locationsData } = usePlacesWithLocationsDictionary();

  const {
    userData: { locationId },
  } = useContext(UserContext);

  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const isHeadsUp = watch("isHeadsUp");

  const placesOptions = useMemo(
    () =>
      getDestinationsOptionsByLocationId(
        locationsData?.data,
        announcement && announcement?.data
          ? announcement?.data.locationIds
          : [locationId]
      ),
    [locationsData?.data]
  );

  return (
    <Box>
      <>
        <TextFieldWithLimit
          isLabelBold
          name="title"
          charactersLimit={120}
          label="Title"
          placeholder="Title of the announcement"
          error={errors?.title?.message}
          sx={{
            mb: 5,
          }}
        />
        <RichTextEditorWithLimit
          name="content"
          error={errors?.content?.message}
          label="Content"
          isLabelBold
          charactersLimit={350}
        />
        <Box sx={{ mt: 5, mb: 5 }}>
          <TreeSelect
            id="locations-select"
            name="locationIds"
            mode="multiSelect"
            options={placesOptions}
            label="Location"
            //TODO: investigate why validation fails after user selects any location and remove condition
            {...(errors?.locationIds?.type === "too_small" && {
              error: errors?.locationIds?.message,
            })}
          />
        </Box>
        {!isHeadsUp && (
          <Box sx={{ mb: 5 }}>
            <Label>Link URL</Label>
            <ControlledTextField name="url" placeholder="Link url" />
          </Box>
        )}
      </>

      {announcementConflict && (
        <Box sx={{ mb: 5 }}>
          <Flex
            display="block"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(announcementConflict),
            }}
          />
        </Box>
      )}
    </Box>
  );
};

AnnouncementInfoSection.defaultProps = {
  isEditable: true,
  announcementConflict: null,
  announcement: null,
};

AnnouncementInfoSection.propTypes = {
  isEditable: bool,
  announcementConflict: string,
  announcement: exact({
    data: exact({
      id: number,
      title: string,
      content: string,
      endDate: string,
      startDate: string,
      status: string,
      type: string,
      isWorldWide: bool,
      locationIds: arrayOf(number),
    }),
  }),
};

export default AnnouncementInfoSection;
