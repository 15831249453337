import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { arrayOf, bool, func, oneOfType, shape, string } from "prop-types";
import { InputAdornment, MenuItem } from "@mui/material";

import { Label, NestedItem } from "@atoms";
import { useDebounce } from "@hooks";
import { ReactComponent as Search } from "@assets/svgs/Search.svg";

import { StyledSearch, StyledSelect, Wrapper, SearchWrap } from "./styles";

const NestedSingleSelect = ({
  id,
  option,
  options,
  name,
  label,
  customWidth,
  textFieldProps,
  inputLabelProps,
  onSearchChange,
  onChange,
  isLabelBold,
  menuPosition,
}) => {
  const { control } = useFormContext();
  const [searchValue, setSearchValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const renderValue = () => options && option.name;

  const debouncedSearchValue = useDebounce(searchValue, 300);

  useEffect(() => {
    onSearchChange(debouncedSearchValue);
  }, [debouncedSearchValue, onSearchChange]);

  const toggleSelect = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Wrapper>
      {label ? (
        <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledSelect
            id={id}
            select
            SelectProps={{
              renderValue,
              value: option,
              open: isOpen,
              id: "select",
              onClose: toggleSelect,
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: menuPosition,
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: menuPosition,
                },
                getContentAnchorEl: null,
              },
            }}
            onClick={({ target: { id } }) => {
              if (id === "select") {
                toggleSelect();
              }
            }}
            customWidth={customWidth}
            {...textFieldProps}
            InputLabelProps={inputLabelProps}
          >
            <SearchWrap>
              {/* TODO: create separate component for search input if we have it not only in this nested select */}
              <StyledSearch
                id="search"
                type="search"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={`Search ${
                  textFieldProps?.placeholder ||
                  textFieldProps?.label ||
                  "Location"
                }...`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              {options.map((item) =>
                item?.suboptions?.length ? (
                  <MenuItem
                    key={item.id}
                    disableRipple
                    sx={{
                      p: 0,
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                  >
                    <NestedItem
                      option={item}
                      options={item.suboptions}
                      onClick={(location) => {
                        field.onChange(location);
                        toggleSelect();
                        onChange && onChange(location);
                      }}
                      searchValue={debouncedSearchValue}
                    />
                  </MenuItem>
                ) : (
                  <MenuItem
                    key={item?.id}
                    onClick={() => {
                      field.onChange(item);
                      toggleSelect();
                      onChange && onChange(item);
                    }}
                    disableRipple
                  >
                    {item?.name}
                  </MenuItem>
                )
              )}
            </SearchWrap>
          </StyledSelect>
        )}
      />
    </Wrapper>
  );
};

NestedSingleSelect.propTypes = {
  id: string.isRequired,
  option: oneOfType([string, shape({})]),
  options: arrayOf(shape({})),
  name: string.isRequired,
  label: string,
  customWidth: string,
  textFieldProps: shape({}),
  inputLabelProps: shape({}),
  onSearchChange: func,
  onChange: func,
  isLabelBold: bool,
  menuPosition: "right" | "left" | "center",
};

NestedSingleSelect.defaultProps = {
  // Should be an empty string as a default value for a controlled component for correctly rendering the label
  option: "",
  options: [],
  label: "",
  customWidth: "100%",
  textFieldProps: undefined,
  inputLabelProps: undefined,
  onSearchChange: () => {},
  onChange: () => {},
  isLabelBold: false,
  menuPosition: "center",
};

export default NestedSingleSelect;
