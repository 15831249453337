import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Layout } from "@templates";
import { useModal, usePlacesWithLocationsDictionary } from "@hooks";
import { CreatePushModal, SentPushModal } from "@features";
import {
  AddButton,
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { FiltersContainer } from "@organisms";
import { CreatedByFilter, DatePickerField, TreeSelect } from "@molecules";
import { getPushNotifications } from "@requests";
import { columns } from "./columns";
import { pushNotificationsStatusesItems } from "@constants/statusesItems";
import { getDestinationsOptionsByLocationId } from "@features/pushNotifications/CreatePushModal/utils";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import { getFiltersForPage, setFiltersForPage } from "@helpers";

const PushNotifications = () => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const { showModal, onOpen, onClose, payload, setPayload } = useModal();
  const {
    showModal: showSentPushModal,
    onOpen: onOpenSentPush,
    onClose: onCloseSentPush,
    payload: sentPushPayload,
    setPayload: setSentPushPayload,
  } = useModal();

  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    locationIds: [locationId],
    page: 1,
    ...getFiltersForPage("push-notifications"),
  });

  useEffect(() => {
    setFiltersForPage("push-notifications", filters);
  }, [filters]);

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["pushNotifications", filters],
    queryFn: getPushNotifications,
  });

  const onRowClick = useCallback(
    (id, row) => {
      if (row.status === "sent") {
        setSentPushPayload(row);
        onOpenSentPush();
      } else {
        setPayload(id);
        onOpen();
      }
    },
    [onOpen, setSentPushPayload, onOpenSentPush, setPayload]
  );

  const { data: locationsData, isLoading: isPlacesLoading } =
    usePlacesWithLocationsDictionary();

  const destinationsOptions = useMemo(
    () =>
      getDestinationsOptionsByLocationId(
        locationsData?.data,
        filters.locationIds,
        filters.world
      ),
    [locationsData]
  );

  const handleLocationChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationIds;
      delete newData.world;

      if (!data.length) {
        return newData;
      }

      if (data.length === 1) {
        const [{ locationId }] = data;
        if (locationId === -1) {
          newData.world = true;
          return newData;
        }
      }

      newData.locationIds = data.map(({ locationId }) => locationId);
      return newData;
    });
  }, []);

  const handleSearchChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.search = value;
      } else {
        delete newData.search;
      }
      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.status = value;
      } else {
        delete newData.status;
      }
      return newData;
    });
  }, []);

  const handleCreatedByChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.createdBy = parseInt(value.id);
      } else {
        delete newData.createdBy;
      }
      return newData;
    });
  }, []);

  const handleFromDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.startDate = value;
      } else {
        delete newData.startDate;
      }
      return newData;
    });
    setStartDate(new Date(value));
  }, []);

  const handleToDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.endDate = value;
      } else {
        delete newData.endDate;
      }
      return newData;
    });
    setEndDate(new Date(value));
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const queryClient = useQueryClient();

  const handleOnClose = useCallback(() => {
    queryClient.invalidateQueries(["pushNotifications"]).then(onClose);
  }, []);

  const isLoading = isDataLoading || isPlacesLoading;

  return (
    <Layout>
      <Stack direction="row" justifyContent="space-between">
        <Title>Push Notifications</Title>
        <AddButton onClick={onOpen}>Create new</AddButton>
      </Stack>

      <FiltersContainer defaultValues={filters}>
        <Box sx={{ "& > .MuiBox-root": { minWidth: "240px" } }}>
          <TreeSelect
            id="locationsFilter"
            name="locationsFilter"
            mode="multiSelect"
            options={destinationsOptions}
            onChange={handleLocationChange}
            texts={{ placeholder: "Location" }}
          />
        </Box>
        <SearchFieldFilter
          id="searchFilter"
          name="search"
          placeholder="Search labels or content..."
          onChange={handleSearchChange}
        />
        <CreatedByFilter
          name="createdBy"
          label="Created By"
          onChange={handleCreatedByChange}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={pushNotificationsStatusesItems}
          onChange={handleStatusChange}
        />
        <DatePickerField
          id="fromDateFilter"
          name="startDate"
          placeholder="From"
          onChange={handleFromDateChange}
          maxDate={endDate}
          showResetButton
        />
        <DatePickerField
          id="toDateFilter"
          name="endDate"
          placeholder="To"
          onChange={handleToDateChange}
          minDate={startDate}
          showResetButton
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={data?.data}
          columns={columns}
          superscription={
            <Superscription
              name="item"
              count={data?.pagination?.total}
              isLoading={isLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}

      <CreatePushModal
        isOpen={showModal}
        onClose={handleOnClose}
        payload={payload}
      />
      <SentPushModal
        isOpen={showSentPushModal}
        onClose={onCloseSentPush}
        payload={sentPushPayload}
      />
    </Layout>
  );
};

export default PushNotifications;
