import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { arrayOf, number, shape, string } from "prop-types";

import { MultipleSelect } from "@molecules";

const RoomLimitAccess = ({ companiesList, managersList }) => {
  const { watch } = useFormContext();

  return (
    <Stack spacing={5}>
      <Typography variant="h3">Limit to</Typography>
      <Stack direction="row" spacing={5}>
        <MultipleSelect
          label="Company"
          name="companyLimits"
          placeholder="Company"
          options={companiesList}
          values={watch("companyLimits")}
        />
        <MultipleSelect
          label="Community Manager"
          name="communityManagerLimits"
          placeholder="Community Manager"
          options={managersList}
          values={watch("communityManagerLimits")}
        />
      </Stack>
    </Stack>
  );
};

RoomLimitAccess.defaultProps = {
  companiesList: [],
  managersList: [],
};

RoomLimitAccess.propTypes = {
  companiesList: arrayOf(
    shape({
      id: number,
      name: string,
    })
  ),
  managersList: arrayOf(
    shape({
      id: number,
      name: string,
    })
  ),
};

export default RoomLimitAccess;
