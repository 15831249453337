import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import {
  BootstrapTooltip,
  DataTableEllipsisCell,
  StatusLabel,
  Icon,
} from "@atoms";
import { Flex } from "@mixins";
import { MultiplyList } from "@molecules";
import { parseStrictISOString } from "@helpers";
import { DATE_DAYS_TEMPLATE } from "@constants";
import { ReactComponent as AnnouncementsIcon } from "@assets/svgs/Speaker.svg";

export const columns = [
  {
    id: 1,
    field: "type",
    width: 50,
    headerName: "Type",
    sortable: false,
    renderCell: ({ value }) =>
      value === "heads_up" ? (
        <BootstrapTooltip title="Heads Up announcement" placement="top">
          <Flex
            flexDirection="row"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Icon SVG={AnnouncementsIcon} color="black" />
          </Flex>
        </BootstrapTooltip>
      ) : (
        <></>
      ),
  },
  {
    id: 2,
    field: "title",
    width: 200,
    headerName: "Title",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
  },
  {
    id: 3,
    field: "content",
    headerName: "Content",
    width: 215,
    sortable: false,
    renderCell: ({ value = [] }) =>
      value.length ? (
        <BootstrapTooltip
          title={value.replace(/(<([^>]+)>)/gi, "")}
          placement="top"
        >
          <DataTableEllipsisCell>
            {value.replace(/(<([^>]+)>)/gi, "")}
          </DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 4,
    field: "createdBy",
    headerName: "Created By",
    sortable: false,
    renderCell: ({ value }) =>
      <DataTableEllipsisCell>{value}</DataTableEllipsisCell> || (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 5,
    field: "startDate",
    headerName: "Start date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseStrictISOString(value, DATE_DAYS_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 6,
    field: "endDate",
    headerName: "End date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseStrictISOString(value, DATE_DAYS_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 7,
    field: "locations",
    headerName: "Location",
    sortable: false,
    renderCell: ({ value }) =>
      value.length ? <MultiplyList values={value} /> : "world",
    flex: 1,
  },
  {
    id: 8,
    field: "status",
    headerName: "Status",
    sortable: false,
    renderCell: ({ value }) =>
      value ? <StatusLabel value={value} /> : <MissingValue />,
  },
];
