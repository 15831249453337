export const announcementTypesItems = [
  {
    id: 1,
    name: "Heads Up",
    value: "heads_up",
  },
  {
    id: 2,
    name: "Announcement",
    value: "announcement",
  },
];
