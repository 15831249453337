const isSelected = (location, entity, parent) => {
  return parent?.checked ? true : entity?.includes(location);
};

export const getDestinationsOptions = (locations, selectedLocations = {}) => {
  const { countries, cities, locations: locationsIds } = selectedLocations;

  let world = {
    locationId: -1,
    label: "All",
    value: "world",
    checked: isSelected("world", countries),
  };

  world.children = locations?.reduce((acc, curr) => {
    const { id, title, country, city } = curr;

    let countryIdx = acc.findIndex((item) => item.value === country);

    if (countryIdx === -1) {
      acc.push({
        locationId: id,
        label: country || "",
        value: country,
        children: [],
        checked: isSelected(country, countries, world),
      });

      countryIdx = acc.length - 1;
    }

    let cityIdx = acc[countryIdx].children.findIndex(
      (item) => item.value === city
    );

    if (cityIdx === -1) {
      acc[countryIdx].children.push({
        locationId: id,
        label: city || "",
        value: city,
        children: [],
        checked: isSelected(city, cities, acc[countryIdx]),
      });

      cityIdx = acc[countryIdx].children.length - 1;
    }

    acc[countryIdx].children[cityIdx].children.push({
      locationId: id,
      label: title || "",
      value: id,
      checked: isSelected(
        id,
        locationsIds?.map((id) => +id),
        acc[countryIdx].children[cityIdx]
      ),
    });

    return acc;
  }, []);

  return world;
};

export const getDestinationsOptionsByLocationId = (
  locations,
  locationIds = [],
  isWorld = false
) => {
  let world = {
    locationId: -1,
    label: "All",
    value: "world",
    checked: isWorld,
  };

  world.children = locations?.reduce((acc, curr) => {
    const { id, title, country, city } = curr;

    let countryIdx = acc.findIndex((item) => item.value === country);

    if (countryIdx === -1) {
      acc.push({
        locationId: id,
        label: country || "",
        value: country,
        children: [],
        checked: false, // id === locationId,
      });

      countryIdx = acc.length - 1;
    }

    let cityIdx = acc[countryIdx].children.findIndex(
      (item) => item.value === city
    );

    if (cityIdx === -1) {
      acc[countryIdx].children.push({
        locationId: id,
        label: city || "",
        value: city,
        children: [],
        checked: false, // id === locationId,
      });

      cityIdx = acc[countryIdx].children.length - 1;
    }

    acc[countryIdx].children[cityIdx].children.push({
      locationId: id,
      label: title || "",
      value: id,
      checked: locationIds.includes(id),
    });

    return acc;
  }, []);

  return world;
};

export const transformLocationsData = (selectedNodes) =>
  selectedNodes.reduce(
    (acc, curr) => {
      if (curr._depth === 0) {
        acc.countries.push("world");
      }

      if (curr._depth === 1) {
        acc.countries.push(curr.value);
      }

      if (curr._depth === 2) {
        acc.cities.push(curr.value);
      }

      if (curr._depth === 3) {
        acc.locations.push(curr.value);
      }

      return acc;
    },
    { countries: [], cities: [], locations: [] }
  );

export const prepareFormValues = (values, toSave) => {
  const { date, time, destinations, ...data } = values;

  return {
    ...data,
    destinations: transformLocationsData(destinations),
    date: date?.split("T")[0],
    time: time?.$d.toTimeString().slice(0, 5),
    toSave,
  };
};

const searchCity = (options, matchingValue) => {
  const cities = options.reduce((acc, curr) => {
    acc.push(...curr.children);
    return acc;
  }, []);
  return cities.find((city) =>
    city.children.find((location) => location.value === matchingValue)
  );
};

export const checkMoreThanOneCity = (locations, destinationsOptions) => {
  const isWorld = locations?.find((item) => item._depth === 0);

  if (isWorld) return true;

  const cityIds = locations?.reduce((acc, curr) => {
    if (curr._depth === 1) {
      const country = destinationsOptions.children?.find(
        (item) => item.value === curr.value
      );
      acc.push(...country.children.map((item) => item.value));
    }

    if (curr._depth === 2) {
      acc.push(curr.value);
    }

    if (curr._depth === 3) {
      const city = searchCity(destinationsOptions.children, curr.value);
      acc.push(city?.value);
    }

    return acc;
  }, []);

  const cityIdsSet = new Set(cityIds);

  return cityIdsSet.size > 1;
};

export const transformDestinationsToFormValue = (
  destinations,
  locationsDictionary
) =>
  Object.keys(destinations).reduce((acc, key) => {
    if (!destinations[key].length) {
      return acc;
    }

    if (key === "countries") {
      const isWorld = destinations[key].includes("world");

      if (isWorld) {
        acc.push({
          label: "All",
          value: "world",
          _depth: 0,
        });
      } else {
        acc.push(
          ...destinations[key].map((country) => ({
            label: country,
            value: country,
            _depth: 1,
          }))
        );
      }
    }

    if (key === "cities") {
      acc.push(
        ...destinations[key].map((city) => ({
          label: city,
          value: city,
          _depth: 2,
        }))
      );
    }

    if (key === "locations") {
      acc.push(
        ...destinations[key].map((locationId) => ({
          label: locationsDictionary.find(
            (location) => location.id === +locationId
          )?.title,
          value: +locationId,
          _depth: 3,
        }))
      );
    }

    return acc;
  }, []);
