import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  DATE_DAYS_TEMPLATE,
  DATE_DEFAULT_FORMAT_TEMPLATE,
  REGEXP_EMAIL,
} from "@constants";

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const validateEmail = (email) =>
  !!String(email).toLowerCase().match(REGEXP_EMAIL);

export const adjustHTML = (html) =>
  html
    .replaceAll('<p style="text-align: justify"></p>', "<br/>")
    .replaceAll("<p></p>", "<br/>")
    .replaceAll(
      "<p><code",
      '<p style="white-space: break-spaces; line-height: 1.75;"><code style="color: rgb(97, 97, 97);"'
    )
    .replaceAll(
      '<p style="text-align: justify"><code',
      '<p style="text-align: justify; white-space: break-spaces; line-height: 1.75;"><code style="color: rgb(97, 97, 97);"'
    );

export const removeValuesFromArray = (valuesArray, value) => {
  const valueIndex = valuesArray.findIndex((entry) => entry === value);
  if (valueIndex === -1) {
    return;
  }
  valuesArray.splice(valueIndex, 1);
};

export const convertLocations = (data) =>
  data.reduce((acc, location) => {
    const locCountry = acc.find(
      (country) => country.name.toUpperCase() === location.country.toUpperCase()
    );

    if (!locCountry) {
      acc.push({
        name: location.country,
        cities: [
          {
            name: location.city,
            locations: [
              {
                name: location.title,
                id: location.id,
              },
            ],
          },
        ],
      });
    } else {
      const locCity = locCountry.cities.find(
        (city) => city.name.toUpperCase() === location.city.toUpperCase()
      );

      if (!locCity) {
        locCountry.cities.push({
          name: location.city,
          locations: [
            {
              id: location.id,
              name: location.title,
            },
          ],
        });
      } else {
        locCity.locations.push({
          id: location.id,
          name: location.title,
        });
      }
    }
    return acc;
  }, []);

export const checkShouldOpenNestedItem = (options, searchValue) => {
  return options.some((item) => {
    const isIncludesSearchValue =
      item.name?.toLowerCase()?.includes(searchValue?.toLowerCase()) &&
      searchValue !== "";
    if (isIncludesSearchValue) {
      return true;
    }
    if (item.suboptions) {
      return checkShouldOpenNestedItem(item.suboptions, searchValue);
    }
    return false;
  });
};

export const parseISOString = (date, format = DATE_DAYS_TEMPLATE) =>
  dayjs(date).utc().format(format);

export const parseStrictISOString = (date, format = DATE_DAYS_TEMPLATE) =>
  dayjs(date).utc(true).format(format);

export const formatDateToString = (
  date,
  format = DATE_DEFAULT_FORMAT_TEMPLATE
) => dayjs(date.toISOString()).format(format);

export const convertDateWithTimezone = (date, timezone) => {
  return new Date(dayjs(date).tz(timezone).$d);
};

export const getUTCDateAndTimeFromZoned = (date, timezone) => {
  const dateISO = dayjs(date).tz(timezone, true).toISOString();
  const [dateUTC, time] = dateISO.split("T");
  const timeUTC = time.split("Z")[0].slice(0, 5);
  return [dateUTC, timeUTC];
};

export const getZonedDateAndTimeFromUTC = (date, time, timezone) => {
  const zonedFullDate = dayjs
    .utc(`${date} ${time}`)
    .tz(timezone)
    .format("YYYY-MM-DD HH:mm");
  return zonedFullDate.split(" ");
};

export const getTimeInMilliseconds = (dateString) => +dayjs(dateString);

export function lazyFunction(f) {
  return function lazyFunctionInner() {
    // eslint-disable-next-line prefer-rest-params
    return f.apply(this, arguments);
  };
}

export const getLanguage = () =>
  navigator.userLanguage ||
  navigator.languages?.[0] ||
  navigator.language ||
  navigator.browserLanguage ||
  navigator.systemLanguage ||
  "en";
