import { useMemo } from "react";
import { Icon } from "@atoms";
import { Flex, Typography } from "@mixins";
import { palette } from "@styles/palette";
import {
  PRIVACY_POLICY_URL,
  TERMS_SERVICE_URL,
  APP_STORE_URL,
  PLAY_STORE_URL,
} from "@constants/links";

import { ReactComponent as Logo } from "@assets/svgs/Logo.svg";
import { ReactComponent as AppStore } from "@assets/svgs/AppStore.svg";
import { ReactComponent as PlayStore } from "@assets/svgs/PlayStore.svg";

import socials from "./socials";
import { LinkButon } from "./styles";

const Footer = () => {
  const onPrivacyPolicyClick = () => {
    window.open(PRIVACY_POLICY_URL);
  };

  const onTermsOfServiceClick = () => {
    window.open(TERMS_SERVICE_URL);
  };

  const onPlayStoreClick = () => {
    window.open(PLAY_STORE_URL);
  };

  const onAppStoreClick = () => {
    window.open(APP_STORE_URL);
  };

  const socialsBlock = useMemo(
    () =>
      socials.map(({ id, icon, handler }, index) => (
        <Icon
          mr={index === socials.length - 1 ? 0 : { xs: 15, sm: 30 }}
          SVG={icon}
          key={id}
          onClick={handler}
          height={{ xs: 12, sm: 16 }}
          pointer
        />
      )),
    []
  );

  return (
    <Flex
      backgroundColor={palette.white}
      position="absolute"
      bottom={0}
      right={0}
      left={0}
      zIndex={2}
      flexDirection="column"
      px={{ xs: 20, sm: 60 }}
    >
      <Flex
        alignItems="center"
        py={{ xs: 20, sm: 45 }}
        justifyContent="space-between"
      >
        <Icon
          SVG={Logo}
          width={{ xs: 100, sm: 173 }}
          height={{ xs: 15, sm: 22 }}
        />
        <Flex>{socialsBlock}</Flex>
      </Flex>
      <Flex
        alignItems="center"
        py={{ xs: 20, sm: 45 }}
        borderTop={`1px solid ${palette.gray[100]}`}
        justifyContent="space-between"
      >
        <Flex flexDirection={{ xs: "column", sm: "column", md: "row" }}>
          <Icon
            onClick={onAppStoreClick}
            pointer
            mr={{ xs: 0, sm: 0, md: 10 }}
            mb={{ xs: 10, sm: 10, md: 0 }}
            SVG={AppStore}
            width={{ xs: 90, sm: 147 }}
            height={{ xs: 28, sm: 47 }}
          />
          <Icon
            onClick={onPlayStoreClick}
            pointer
            SVG={PlayStore}
            width={{ xs: 90, sm: 147 }}
            height={{ xs: 28, sm: 47 }}
          />
        </Flex>
        <Flex>
          <Flex flexDirection="column">
            <Flex justifyContent="end" mb={{ xs: 2, sm: 12 }}>
              <LinkButon
                onClick={onTermsOfServiceClick}
                fontSize={{ xs: "10px", sm: 2 }}
                mr={{ xs: 13, sm: 26 }}
                color={palette.black}
              >
                Terms of Service
              </LinkButon>
              <LinkButon
                onClick={onPrivacyPolicyClick}
                fontSize={{ xs: "10px", sm: 2 }}
                color={palette.black}
              >
                Privacy Policy
              </LinkButon>
            </Flex>
            <Flex>
              <Typography
                fontSize={{ xs: "10px", sm: 1 }}
                color={palette.gray[400]}
              >
                Copyright © 2023 Mindspace Inc. All rights reserved
              </Typography>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
