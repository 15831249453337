import { adjustHTML } from "../../../helpers/common";

export const defaultValues = {
  startDate: "",
  endDate: "",
  isHeadsUp: false,
  title: "",
  locationIds: [],
  content: "",
  url: "",
  status: true,
  type: "",
};

const restorePlaces = (selectedLocations, locations) =>
  selectedLocations?.map((selectedLocationId) => {
    const location = locations?.find(
      (location) => location.id === selectedLocationId
    );

    return {
      label: location.title,
      value: location.title,
      parent: location.city ?? undefined,
      locationId: location.id,
      _depth: 3,
    };
  });

const setDefaultPlace = (place, isWorldWide, locations) => {
  if (place?.length) {
    return restorePlaces(place, locations);
  }
  if (isWorldWide) {
    return [
      {
        label: "All",
        value: "world",
        checked: true,
        _depth: 0,
      },
    ];
  }
  return [];
};

export const generateDefaultFormValues = (data, locations) => ({
  startDate: data?.data?.startDate || "",
  endDate: data?.data?.endDate || "",
  type: data?.data?.type || false,
  title: data?.data?.title ? data?.data?.title : "",
  locationIds: setDefaultPlace(
    data?.data?.locationIds,
    data?.data?.isWorldWide,
    locations.data
  ),
  content: data?.data?.content || "",
  url: data?.data?.url || "",
  status: data?.data?.status === "active",
});

export const appendLocations = (selectedLocations, locations = []) =>
  selectedLocations.reduce((acc, selectedLocation) => {
    if (selectedLocation._depth === 3) {
      acc.push(selectedLocation.locationId);
    }
    if (selectedLocation._depth === 2) {
      acc.push(
        ...locations
          .filter((location) => location.city === selectedLocation.label)
          .map((location) => location.id)
      );
    }
    if (selectedLocation._depth === 1) {
      acc.push(
        ...locations
          .filter((location) => location.country === selectedLocation.label)
          .map((location) => location.id)
      );
    }

    return acc;
  }, []);

export const createRequestBody = ({
  formData: {
    startDate,
    endDate,
    isHeadsUp,
    title,
    locationIds,
    content,
    status,
    url,
  },
  locations,
}) => {
  const body = {
    content: adjustHTML(content),
    type: isHeadsUp ? "heads_up" : "announcement",
    title,
    status: status ? "active" : "inactive",
    startDate,
    ...(url && { url }),
    ...(endDate && { endDate }),
  };

  if (locationIds.length) {
    const isWorldWide = locationIds.find(({ value }) => value === "world");

    if (isWorldWide) {
      body.locationIds = [];
      body.isWorldWide = true;
    } else {
      body.locationIds = appendLocations(locationIds, locations);
      body.isWorldWide = false;
    }
  }

  return body;
};
