export const paymentTypes = [
  {
    id: 1,
    name: "Credits",
    value: "credits",
  },
  {
    id: 2,
    name: "Credit Card",
    value: "money",
  },
];
