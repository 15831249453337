import { Stack, Typography } from "@mui/material";
import { arrayOf, number, shape, string } from "prop-types";

import { CheckboxesGroup } from "@molecules";

const RoomFeatures = ({ features }) => (
  <Stack spacing={5}>
    <Typography variant="h3">Room Features</Typography>
    <CheckboxesGroup
      options={features}
      name="features"
      gridSize={4}
      gridProps={{
        direction: "column",
        alignContent: "flex-start",
        columnSpacing: 16,
        sx: { height: "108px" },
      }}
    />
  </Stack>
);

RoomFeatures.defaultProps = {
  features: [],
};

RoomFeatures.propTypes = {
  features: arrayOf(
    shape({
      id: number,
      displayName: string,
    })
  ),
};

export default RoomFeatures;
