import { getBenefitCategories } from "@requests";
import { useDictionary } from "./useDictionary";

/**
 * @typedef BenefitCategoriesResponse
 * @property {Array<BenefitCategoryData>} data
 */

/**
 * @typedef BenefitCategoryData
 * @property {Number} id
 * @property {String} name
 */

/**
 * @returns {{isLoading: Boolean, data: BenefitCategoriesResponse, error: Error}}
 */
export const useBenefitCategoriesDictionary = () =>
  useDictionary(getBenefitCategories);
