import { Typography } from "@mui/material";
import { string } from "prop-types";
import { Wrapper } from "./styles";

const StatusLabel = ({ value, text, ...props }) => (
  <Wrapper status={value} {...props}>
    <Typography
      fontWeight={600}
      sx={{ textTransform: text ? "none" : "capitalize" }}
    >
      {text || value}
    </Typography>
  </Wrapper>
);

StatusLabel.propTypes = {
  value: string,
  text: string,
};

StatusLabel.defaultProps = {
  value: "Unknown",
  text: "",
};

export default StatusLabel;
