import mixpanel from "mixpanel-browser";

export const trackInteraction = (key, options = {}) => {
  try {
    mixpanel.track(key, options);
  } catch (e) {
    console.error(
      `MixPanel can't identify user, probably is it wasn't initialized well`
    );
  }
};
