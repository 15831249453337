/**
 * @param {LocationData | LocationFullData} location
 * @returns {String}
 */
export const getAddress = (location) => {
  return [
    location.address || "Address unknown",
    location.city,
    location.country,
  ]
    .filter((x) => x)
    .join(", ");
};
