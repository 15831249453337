import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Label } from "@atoms";

export const GridWrapper = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  columnGap: "36px",
  rowGap: "20px",
  maxWidth: "836px",
  marginBottom: "42px",
}));

export const ColumnWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(2),
  alignItems: "center",
}));

export const AmenitiesRow = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(3),
}));

export const StyledLabel = styled(Label)(() => ({
  width: 300,
  fontWeight: 400,
}));

export const DetailsWrap = styled(Box)(() => ({
  display: "flex",
  gap: "10px",
  maxWidth: 400,
}));
