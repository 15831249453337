import { Controller, useFormContext } from "react-hook-form";
import { bool, number, string } from "prop-types";
import { InputAdornment, TextField } from "@mui/material";
import { Label } from "@atoms";

import { Wrap } from "./styles";

const CHARACTERS_LIMIT = 20;

const TextFieldWithLimit = ({
  label,
  name,
  charactersLimit,
  multiline,
  isLabelBold,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <>
      {label ? (
        <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Wrap>
            <TextField
              {...field}
              {...props}
              multiline={multiline}
              rows={multiline ? 5 : undefined}
              onChange={(event) => {
                field.onChange(event);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{
                      ...(multiline && {
                        position: "absolute",
                        right: "10px",
                        bottom: "12px",
                        backgroundColor: "transparent",
                      }),
                    }}
                  >{`${
                    field.value?.length || 0
                  }/${charactersLimit}`}</InputAdornment>
                ),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                maxLength: charactersLimit,
              }}
            />
          </Wrap>
        )}
      />
    </>
  );
};

TextFieldWithLimit.defaultProps = {
  label: "",
  charactersLimit: CHARACTERS_LIMIT,
  multiline: false,
  rows: 1,
  isLabelBold: false,
};

TextFieldWithLimit.propTypes = {
  label: string,
  name: string.isRequired,
  charactersLimit: number,
  multiline: bool,
  rows: number,
  isLabelBold: bool,
};

export default TextFieldWithLimit;
