/**
 * @param {String} pageName
 * @returns {Object}
 */
export const getFiltersForPage = (pageName) => {
  if (typeof sessionStorage === "undefined" || !sessionStorage) {
    return null;
  }

  const storedDataString = sessionStorage.getItem(`${pageName}-filters`);

  if (!storedDataString) {
    return null;
  }

  try {
    return JSON.parse(storedDataString);
  } catch (e) {
    console.warn("There is corrupted saved filters data in localStorage");
  }

  return null;
};
