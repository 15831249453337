import { Switch, FormControlLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  width: "fit-content",
  "& .MuiFormControlLabel-label": {
    paddingLeft: theme.spacing(2.5),
    fontWeight: 400,
  },
}));

export const StyledSwitch = styled(Switch)(({ theme, isBig }) => ({
  width: isBig ? 36 : 24,
  height: isBig ? 18 : 12,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: isBig ? 10 : 9,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: isBig ? 4 : 1.6,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-checked": {
      transform: `translateX(${isBig ? 18 : 12}px)`,
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.darkGreen,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: isBig ? 10 : 9,
    height: isBig ? 10 : 9,
    borderRadius: "50%",
  },
  "& .MuiSwitch-track": {
    borderRadius: isBig ? 9 : 8,
    opacity: 1,
    backgroundColor: theme.palette.gray[50],
    boxSizing: "border-box",
  },
}));
