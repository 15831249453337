import { Box, Link, Typography } from "@mui/material";
import { number, string, bool } from "prop-types";

import { useFormContext } from "react-hook-form";
import { ReactComponent as LinkIcon } from "@assets/svgs/Link.svg";
import { StatusLabel, ControlledCheckbox, Toggle } from "@atoms";

import { Separator } from "./styles";
import { getLanguage } from "@helpers";

const RoomDetails = ({
  cmNotificationEnabled,
  isComboRoom,
  salesforceUrl,
  locationTitle,
  floor,
  workstationCount,
  creditsPerHour,
  pricePerHour,
  currency,
  status,
}) => {
  const { watch } = useFormContext();

  const notificationEnabled =
    watch("cmNotificationEnabled") ?? cmNotificationEnabled;

  return (
    <>
      <StatusLabel value={status} sx={{ mt: "25px", mb: "27px" }} />
      {!isComboRoom && (
        <Box mt={8} mb={8}>
          <Link variant="caption" target="_blank" href={salesforceUrl}>
            <Box mr={2} display="inline-flex">
              <LinkIcon />
            </Box>
            Salesforce Link
          </Link>
        </Box>
      )}
      <Typography variant="h3" mb={3}>
        Room Details
      </Typography>
      <Typography component="p" variant="caption" mb={2}>
        {locationTitle}
      </Typography>
      <Typography component="p" variant="caption" mb={2}>
        {floor} Floor
      </Typography>
      <Typography component="p" variant="caption">
        {workstationCount} Seats
      </Typography>
      <Separator />
      <Typography variant="h3" mb={3}>
        Room Pricing
      </Typography>
      <Typography component="p" variant="caption">
        {creditsPerHour} Credit / Hour
      </Typography>
      {pricePerHour ? (
        <Typography component="p" variant="caption" mt={2}>
          {currency && pricePerHour
            ? new Intl.NumberFormat(getLanguage(), {
                style: "currency",
                currency: currency,
              }).format(pricePerHour || 0) + " / Hour"
            : ""}
        </Typography>
      ) : null}
      <Box
        sx={{
          mt: "15px",
        }}
      >
        <ControlledCheckbox
          checked={notificationEnabled}
          name="cmNotificationEnabled"
          label="Notify CM team when booked"
        />
      </Box>
      {isComboRoom && (
        <>
          <Toggle
            checked={status === "active" ? true : false}
            isBig
            name="status"
            id="statusSwitch"
            label="Active"
            sx={{ mr: 13.5, my: 4 }}
          />
          <Typography
            sx={(theme) => ({ mb: "15px", color: theme.palette.gray[200] })}
          >
            Rooms marked as Active will be published when saved.
          </Typography>
        </>
      )}
    </>
  );
};

RoomDetails.defaultProps = {
  locationTitle: "",
  salesforceUrl: "",
  status: "",
};

RoomDetails.propTypes = {
  cmNotificationEnabled: bool,
  isComboRoom: bool,
  locationTitle: string,
  salesforceUrl: string,
  status: string,
  floor: number.isRequired,
  workstationCount: number.isRequired,
  creditsPerHour: number.isRequired,
  currency: string.isRequired,
  pricePerHour: number.isRequired,
};

export default RoomDetails;
