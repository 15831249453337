import { BACKSPACE_KEY } from "@constants";
import { removeValuesFromArray } from "@helpers/common";
import { Flex } from "@mixins";
import { palette } from "@styles/palette";
import { arrayOf, bool, func, number } from "prop-types";
import { useEffect, useRef } from "react";
import Input from "../styles";

const getColor = (hasError, value) => {
  switch (true) {
    case hasError:
      return palette.red;
    case !!value:
      return palette.black;
    default:
      return palette.gray[200];
  }
};

const VerificationCodeInput = ({
  quantityOfDigits,
  verificationCode,
  onVerificationCodeChange,
  hasError,
}) => {
  const inputRefs = useRef([]);

  const changePinFocus = (pinIndex) => {
    const ref = inputRefs.current[pinIndex];
    if (ref) {
      ref.focus();
    }
  };

  const onChange = (index) => (event) => {
    const previousValue = event.target.defaultValue;
    const valuesArray = event.target.value.split("");

    removeValuesFromArray(valuesArray, previousValue);

    const value = valuesArray.pop();

    if (!value) {
      return;
    }

    const pinNumber = Number(value.trim());
    if (Number.isNaN(pinNumber) || value.length === 0) {
      return;
    }

    onVerificationCodeChange(pinNumber, index);

    if (index < quantityOfDigits - 1) {
      changePinFocus(index + 1);
    }
  };

  const onKeyDown = (index) => (event) => {
    const keyboardKeyCode = event.nativeEvent.code;
    if (keyboardKeyCode !== BACKSPACE_KEY) {
      return;
    }

    if (verificationCode[index] === undefined) {
      changePinFocus(index - 1);
    } else {
      onVerificationCodeChange(undefined, index);
    }
  };

  useEffect(() => {
    if (inputRefs?.current) inputRefs.current[0].focus();
  }, [inputRefs]);

  return (
    <Flex mt="25px">
      {Array.from({ length: quantityOfDigits }, (_, index) => (
        <Input
          pattern="\d*"
          border="0px"
          maxLength={1}
          borderBottom={`1px solid ${getColor(
            hasError,
            verificationCode[index]
          )}`}
          key={index}
          fontSize={32}
          fontWeight={600}
          textAlign="center"
          width="55px"
          color={palette.black}
          {...(index !== quantityOfDigits - 1 && {
            marginRight: "16px",
          })}
          onKeyDown={onKeyDown(index)}
          ref={(el) => {
            if (el) {
              inputRefs.current[index] = el;
            }
          }}
          onChange={onChange(index)}
          value={verificationCode[index] ?? ""}
        />
      ))}
    </Flex>
  );
};

VerificationCodeInput.propTypes = {
  quantityOfDigits: number.isRequired,
  verificationCode: arrayOf(number).isRequired,
  onVerificationCodeChange: func.isRequired,
  hasError: bool.isRequired,
};

export default VerificationCodeInput;
