import { useMemo } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { Box, Button, Typography } from "@mui/material";

import {
  ControlledTextField,
  InfoTooltip,
  Label,
  PlacesAutocomplete,
  RichTextEditor,
  SingleSelect,
  Subtitle,
} from "@atoms";
import { ImageUpload, RemoveButton } from "@molecules";
import { getAmenities } from "@requests";
import { palette } from "@styles/palette";

import {
  AmenitiesRow,
  ColumnWrapper,
  DetailsWrap,
  GridWrapper,
  StyledLabel,
  Wrapper,
} from "./styles";
import { printingDetails } from "./utils";

const LocationDetails = () => {
  const {
    control,
    getValues,
    watch,
    formState: { errors },
  } = useFormContext();

  const homepageImage = watch("homepageImage");
  const splashImage = watch("splashImage");
  const isActivateLocation = getValues("isActivateLocation");
  const selectedAmenities = watch("locationToAmenity");
  const selectedAmenitiesIds =
    selectedAmenities?.map((selectedAmenity) => selectedAmenity?.amenity?.id) ||
    [];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "locationToAmenity",
  });

  const { data: amenities } = useQuery({
    queryKey: ["amenities"],
    queryFn: getAmenities,
  });

  const amenityOptions = useMemo(() => {
    return amenities?.data?.reduce((options, { id, displayName }) => {
      const isAmenityAlreadySelected = selectedAmenitiesIds.includes(id);
      if (!isAmenityAlreadySelected) {
        options.push({
          id,
          name: displayName,
        });
      }
      return options;
    }, []);
  }, [amenities, selectedAmenitiesIds]);

  const renderPrintingDetails = printingDetails.map(
    ({ id, title, name, optional }) => (
      <ColumnWrapper key={id}>
        <Typography mb="4px">{`${title}${
          isActivateLocation ? "" : optional ? "" : "*"
        }`}</Typography>
        <Controller
          name={`details.${name}`}
          control={control}
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              onChange={onChange}
              value={value?.value}
              withEmptyState
              error={errors?.details?.[name]?.isEmpty?.message}
            />
          )}
        />
      </ColumnWrapper>
    )
  );

  const onAddAmenity = () =>
    append({
      amenity: {
        id: "",
        name: "",
      },
      description: "",
    });

  return (
    <Box sx={{ p: "0 50px" }}>
      <Subtitle>Location Images</Subtitle>
      <Box sx={{ display: "flex", mb: "25px", gap: "20px" }}>
        <ColumnWrapper>
          <Wrapper>
            <Typography mr="2px">
              {isActivateLocation ? "Building image*" : "Location image*"}
            </Typography>
            <InfoTooltip title="Default image for the web homepage if no event featured" />
          </Wrapper>
          <ImageUpload
            imageURL={homepageImage}
            fieldName="homepageImage"
            errorText={errors?.homepageImage?.message}
            infoText={
              isActivateLocation
                ? "Suggested Size : 1600px (w) by 900px (h) and less than 10MB"
                : "Suggested Size : 650px (w) by 550px (h) and less than 10MB"
            }
            isCover
          />
        </ColumnWrapper>
        {isActivateLocation && (
          <ColumnWrapper>
            <Wrapper>
              <Typography mr="2px">Building logo</Typography>
              <InfoTooltip title="Transparent gif of the building logo" />
            </Wrapper>
            <ImageUpload
              imageURL={splashImage}
              fieldName="splashImage"
              errorText={errors?.splashImage?.message}
              infoText="Maximum file height 40px"
              isCover
            />
          </ColumnWrapper>
        )}
      </Box>
      <Box sx={{ mb: 5, width: "380px" }}>
        <Label>Address*</Label>
        <PlacesAutocomplete
          name="address"
          placeholder="example: 1480 Mainstraße, Berlin, Germany"
          id="locations-places-autocomplete"
          error={errors?.address?.message}
        />
      </Box>
      <Subtitle sx={{ mb: "10px" }}>Amenities*</Subtitle>
      {fields?.length ? (
        <Box sx={{ display: "flex" }}>
          <StyledLabel sx={{ mr: 2.5 }}>Amenity name</StyledLabel>
          <StyledLabel>Location and details</StyledLabel>
        </Box>
      ) : null}
      {fields.map(({ id }, index) => (
        <AmenitiesRow key={id}>
          <SingleSelect
            placeholder="Amenities"
            name={`locationToAmenity.${index}.amenity`}
            error={errors?.locationToAmenity?.[index]?.amenity?.id?.message}
            options={amenityOptions}
            sx={{
              mr: 2.5,
              width: 300,
            }}
          />
          <ControlledTextField
            sx={{ width: 300 }}
            name={`locationToAmenity.${index}.description`}
            placeholder="Floor 9, etc..."
            error={!!errors?.locationToAmenity?.[index]?.description?.message}
          />
          {fields.length > 1 ? (
            <RemoveButton
              onClick={() => remove(index)}
              sx={{
                color: `${palette.gray[200]} !important`,
                fontWeight: "400 !important",
              }}
            >
              Remove
            </RemoveButton>
          ) : null}
        </AmenitiesRow>
      ))}
      <Button
        type="button"
        variant="outlined"
        onClick={onAddAmenity}
        sx={{ fontSize: 13 }}
      >
        + Add amenity
      </Button>
      <Subtitle sx={{ mb: "10px", mt: "25px" }}>Wifi details</Subtitle>
      <Label sx={{ mb: 1, fontWeight: 400 }}>Members*</Label>
      <DetailsWrap sx={{ mb: "20px" }}>
        <ControlledTextField
          name="wifiSettings.members.network"
          placeholder="Members Network"
          error={!!errors?.wifiSettings?.members?.network?.message}
        />
        <ControlledTextField
          name="wifiSettings.members.password"
          placeholder="Password"
          error={!!errors?.wifiSettings?.members?.password?.message}
        />
      </DetailsWrap>
      <Label sx={{ mb: 1, fontWeight: 400 }}>Guests*</Label>
      <DetailsWrap sx={{ mb: "50px" }}>
        <ControlledTextField
          name="wifiSettings.guests.network"
          placeholder="Guests Network"
          error={!!errors?.wifiSettings?.guests?.network?.message}
        />
        <ControlledTextField
          name="wifiSettings.guests.password"
          placeholder="Password"
          error={!!errors?.wifiSettings?.guests?.password?.message}
        />
      </DetailsWrap>
      <Subtitle sx={{ mb: "10px" }}>Printer Details</Subtitle>
      <GridWrapper>{renderPrintingDetails}</GridWrapper>
      <Box sx={{ flexDirection: "column", maxWidth: "400px" }}>
        <Subtitle sx={{ mb: "10px" }}>Fax Numbers</Subtitle>
        <Controller
          name="details.faxNumbers"
          control={control}
          render={({ field: { onChange, value } }) => (
            <RichTextEditor
              onChange={onChange}
              value={value?.value}
              withEmptyState
            />
          )}
        />
      </Box>
    </Box>
  );
};

export default LocationDetails;
