import { getPlacesWithLocations } from "@requests";
import { useDictionary } from "./useDictionary";

/**
 * @typedef PlaceWithLocationsResponse
 * @property {Pagination} pagination
 * @property {Array<PlaceWithLocationsData>} data
 */

/**
 * @typedef PlaceWithLocationsData
 * @property {Number} id
 * @property {String} title
 * @property {String} city
 * @property {String} country
 */

/**
 * @returns {{isLoading: Boolean, data: PlaceWithLocationsResponse, error: Error}}
 */
const usePlacesWithLocationsDictionary = () =>
  useDictionary(getPlacesWithLocations);

export default usePlacesWithLocationsDictionary;
