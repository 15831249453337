import { useEffect } from "react";
import { bool, func, number, string } from "prop-types";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Link from "@tiptap/extension-link";
import TextAlign from "@tiptap/extension-text-align";
import CharacterCount from "@tiptap/extension-character-count";

import { EMPTY_CONTENT } from "@constants";

import MenuBar from "./MenuBar";
import { Editor, Wrap } from "./styles";
import { InputAdornment } from "@mui/material";

const RichTextEditor = ({
  initialValue,
  onChange,
  value,
  withEmptyState,
  error,
  charactersLimit,
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link.configure({
        autolink: false,
      }),
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      ...(charactersLimit
        ? [
            CharacterCount.configure({
              limit: charactersLimit,
            }),
          ]
        : []),
    ],
    content: value,
    onUpdate: ({ editor: editorEl }) => {
      const currentValue = editorEl.getHTML();
      onChange(
        withEmptyState
          ? {
              value: currentValue,
              isEmpty:
                currentValue !== EMPTY_CONTENT
                  ? !editorEl.getText().trim()
                  : true,
            }
          : currentValue
      );
    },
    onCreate: ({ editor: editorEl }) => {
      const currentValue = editorEl.getHTML();

      if (withEmptyState) {
        onChange({
          value: currentValue !== EMPTY_CONTENT ? currentValue : "",
          isEmpty:
            currentValue !== EMPTY_CONTENT ? !editorEl.getText().trim() : true,
        });
      }
      if (initialValue) {
        onChange(
          withEmptyState
            ? {
                value: initialValue !== EMPTY_CONTENT ? initialValue : "",
                isEmpty:
                  currentValue !== EMPTY_CONTENT
                    ? !editorEl.getText().trim()
                    : true,
              }
            : initialValue !== EMPTY_CONTENT
            ? initialValue
            : ""
        );
      }
    },
  });

  useEffect(() => {
    if (!editor) return;
    let { from, to } = editor.state.selection;
    editor.commands.setContent(value, false, {
      preserveWhitespace: "full",
    });
    editor.commands.setTextSelection({ from, to });
  }, [editor, value]);

  return (
    <Wrap>
      <Editor error={!!error}>
        {editor && <MenuBar editor={editor} />}
        <EditorContent className="editor__content" editor={editor} />
      </Editor>
      {charactersLimit ? (
        <div className="input-adornment-wrapper">
          <InputAdornment position="end">
            {editor?.storage.characterCount.characters() || 0}/{charactersLimit}
          </InputAdornment>
        </div>
      ) : null}
    </Wrap>
  );
};

RichTextEditor.defaultProps = {
  initialValue: "",
  withEmptyState: false,
  error: "",
  charactersLimit: 0,
};

RichTextEditor.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
  initialValue: string,
  withEmptyState: bool,
  error: string,
  charactersLimit: number,
};

export default RichTextEditor;
