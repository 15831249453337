import {
  FILE_NAME_MAX_LENGTH,
  FILE_MAX_SIZE,
  ALLOWED_FILE_TYPES,
  MAX_FILE_SIZE,
  MAX_FILE_NAME_LENGTH,
} from "@constants";

export const getFileErrors = (file) => {
  const errors = [];

  if (file.type !== "image/jpeg" && file.type !== "image/png") {
    errors.push(ALLOWED_FILE_TYPES);
  }
  if (file.size > FILE_MAX_SIZE) {
    errors.push(MAX_FILE_SIZE);
  }
  if (file.name.length > FILE_NAME_MAX_LENGTH) {
    errors.push(MAX_FILE_NAME_LENGTH);
  }

  return errors;
};
