import { applyMiddleware, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import { USER_STORE_NAME, LOCATION_STORE_NAME } from "@constants";
import { initialState as userInitialState } from "@store/user/duck";
import { initialState as locationInitialState } from "@store/location/duck";
import rootReducer from "./rootReducer";

const initialState = {
  [USER_STORE_NAME]: userInitialState,
  [LOCATION_STORE_NAME]: locationInitialState,
};

const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );

  return { store };
};

export default configureStore;
