import { bool, func, node } from "prop-types";
import { useCallbackPrompt } from "@hooks";
import { LeaveModal } from "@organisms";
import { useCallback, useEffect } from "react";

const LeaveModalContainer = ({
  when,
  showByTrigger,
  resetTrigger,
  handleConfirm,
  children,
}) => {
  const { showModal, closeModal, openModal, confirm } = useCallbackPrompt(when);

  useEffect(() => {
    if (showByTrigger) {
      openModal();
    }
  }, [showByTrigger, resetTrigger]);

  const onHandleClose = useCallback(() => {
    closeModal();
    if (resetTrigger) {
      resetTrigger();
    }
  }, [resetTrigger]);

  const onHandleConfirm = useCallback(() => {
    confirm();
    if (resetTrigger) {
      resetTrigger();
    }
    if (handleConfirm) {
      handleConfirm();
    }
  }, [resetTrigger, handleConfirm]);

  return (
    <>
      {children}
      <LeaveModal
        isOpen={showModal}
        onClose={onHandleClose}
        onConfirm={onHandleConfirm}
      />
    </>
  );
};

LeaveModalContainer.defaultProps = {
  when: false,
  showByTrigger: false,
  resetTrigger: undefined,
  handleConfirm: undefined,
};

LeaveModalContainer.propTypes = {
  children: node.isRequired,
  when: bool,
  showByTrigger: bool,
  resetTrigger: func,
  handleConfirm: func,
};

export default LeaveModalContainer;
