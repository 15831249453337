import { Radio, FormControlLabel, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const commonRadioIconStyles = {
  display: "inline-block",
  width: 13,
  height: 13,
  borderRadius: "50%",
};

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: 0,
}));

export const StyledRadio = styled(Radio)(() => ({
  "& .MuiSvgIcon-root": {
    fontSize: 13,
  },
  "&.Mui-checked": {
    "& + .MuiFormControlLabel-label": {
      fontWeight: 600,
    },
  },
}));

export const RadioIcon = styled(Box)(({ theme }) => ({
  ...commonRadioIconStyles,
  border: `1px solid ${theme.palette.gray[200]}`,
}));

export const CheckedRadioIcon = styled(Box)(({ theme }) => ({
  ...commonRadioIconStyles,
  border: `1px solid ${theme.palette.dark}`,
  "&:after": {
    content: '""',
    width: 7,
    height: 7,
    backgroundColor: theme.palette.lightGreen,
    position: "absolute",
    borderRadius: "50%",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));
