import { useCallback, useMemo, useState } from "react";
import { func, object, string, bool } from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { NestedSingleSelect } from "@molecules";
import { ClearFilter } from "@atoms";
import { findRecursively } from "@helpers";
import { Wrap } from "./styles";

const GenericSearchFilter = ({
  error,
  searchPlaceholder,
  name,
  label,
  itemsData,
  transformFunction,
  filterItemsFunction,
  onChange,
  customWidth,
  menuPosition,
}) => {
  const { control, setValue } = useFormContext();
  const [searchedItems, setSearchedItems] = useState(itemsData?.data);

  const handleSearchItems = useCallback(
    (searchValue) => {
      setSearchedItems(filterItemsFunction(searchValue));
    },
    [itemsData?.data]
  );

  const itemsOptions = useMemo(
    () => transformFunction(searchedItems),
    [searchedItems]
  );

  const handleChange = useCallback((item) => {
    setValue(name, item.id);
    onChange && onChange(item);
  }, []);

  const handleReset = useCallback(() => {
    setValue(name, "");
    onChange && onChange(undefined);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Wrap>
          <NestedSingleSelect
            id={name}
            name={name}
            customWidth={customWidth}
            option={findRecursively(
              itemsOptions,
              "suboptions",
              "id",
              field.value
            )}
            options={itemsOptions}
            onSearchChange={handleSearchItems}
            onChange={handleChange}
            textFieldProps={{
              error,
              label,
              placeholder: searchPlaceholder,
            }}
            inputLabelProps={{
              shrink: true,
            }}
            menuPosition={menuPosition}
          />
          <ClearFilter isVisible={!!field.value} onClick={handleReset} />
        </Wrap>
      )}
    />
  );
};

GenericSearchFilter.propTypes = {
  error: bool,
  name: string,
  label: string,
  itemsData: object,
  transformFunction: func,
  filterItemsFunction: func,
  onChange: func,
  customWidth: string,
  menuPosition: "right" | "left" | "center",
  searchPlaceholder: string,
};

GenericSearchFilter.defaultProps = {
  searchPlaceholder: "",
  customWidth: "180px",
  menuPosition: "center",
};

export default GenericSearchFilter;
