import { HEADER_HEIGHT } from "@constants";

export const mixins = {
  toolbar: {
    minHeight: HEADER_HEIGHT,
    "@media (min-width: 0px) and (orientation: landscape)": {
      minHeight: HEADER_HEIGHT,
    },
    "@media (min-width: 600px)": {
      minHeight: HEADER_HEIGHT,
    },
  },
};
