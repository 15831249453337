import { FormProvider, useForm } from "react-hook-form";
import { node, object } from "prop-types";
import { Wrap } from "./styles";

const FiltersContainer = ({ defaultValues, children, ...props }) => {
  const methods = useForm({
    defaultValues: {
      locationsFilter: "",
      ...defaultValues,
    },
  });

  return (
    <FormProvider {...methods}>
      <Wrap direction="row" alignItems="flex-start" gap="11px" {...props}>
        {children}
      </Wrap>
    </FormProvider>
  );
};

FiltersContainer.propTypes = {
  defaultValues: object,
  children: node.isRequired,
};

export default FiltersContainer;
