import styled, { css } from "styled-components";

import { Typography, Button } from "@mixins";
import { palette } from "@styles/palette";

export const Error = styled(Typography)`
  ${({ hasError }) => css`
    visibility: ${hasError ? "visible" : "hidden"};
    height: 8px;
  `}
`;

export const PrimaryButon = styled(Button)`
  background-color: ${palette.darkGreen};
  color: ${palette.white};
  border-radius: 2px;
  font-size: 16px;
`;
