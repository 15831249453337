import { Typography } from "@mui/material";
import { bool, number, string } from "prop-types";

const Superscription = ({ name, count, isLoading }) => {
  const superscription = isLoading
    ? "Loading..."
    : !count || count === 0
    ? "No rows"
    : `${count} ${name}${count === 1 ? "" : "s"}`;

  return (
    <Typography variant="body1" fontWeight={600}>
      <span>{superscription}</span>
    </Typography>
  );
};

Superscription.propTypes = {
  count: number,
  name: string.isRequired,
  isLoading: bool.isRequired,
};

export default Superscription;
