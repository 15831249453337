import { Box, Typography } from "@mui/material";
import { string } from "prop-types";
import { Title } from "./styles";

const Toast = ({ title, description }) => (
  <Box>
    <Title variant="h3">{title}</Title>
    <Typography>{description}</Typography>
  </Box>
);

Toast.propTypes = {
  title: string.isRequired,
  description: string.isRequired,
};

export default Toast;
