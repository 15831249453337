export const PRIVACY_POLICY_URL = "https://www.mindspace.me/privacy";
export const TERMS_SERVICE_URL = "https://www.mindspace.me/terms";
export const LINKEDIN_URL = "https://www.linkedin.com/company/3526820";
export const FACEBOOK_URL = "https://www.facebook.com/mindspace.me";
export const UNSPLASH_URL = "https://unsplash.com/@mindspacestudio";
export const TWITTER_URL = "https://twitter.com/mindspaceme?lang=en";
export const YOUTUBE_URL = "https://www.youtube.com/c/Mindspaceglobal";
export const PLAY_STORE_URL =
  "https://play.google.com/store/apps/details?id=me.mindspace.android";
export const APP_STORE_URL =
  "https://apps.apple.com/us/app/mindspace-flex-office-space/id1088953717";
