import { useEffect, useState } from "react";

const dictionaryMap = new Map();

/**
 * Fetches dictionary data and caches it
 * @param {Function} requestFn
 * @returns {{isLoading: Boolean, data: *, error: Error}}
 */
export const useDictionary = (requestFn) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let promiseFn;
    if (dictionaryMap.has(requestFn)) {
      promiseFn = dictionaryMap.get(requestFn);
    } else {
      promiseFn = requestFn();
      dictionaryMap.set(requestFn, promiseFn);
    }

    setIsLoading(true);
    promiseFn
      .then((gotData) => setData(gotData))
      .catch((caughtError) => setError(caughtError))
      .finally(() => setIsLoading(false));
  }, [requestFn]);

  return { isLoading, data, error };
};
