import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { FiltersContainer } from "@organisms";
import { DatePickerField, TreeSelect } from "@molecules";
import {
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
} from "@atoms";
import { paymentTypes } from "@constants";
import { ReactComponent as ExportIcon } from "@assets/svgs/Export.svg";
import {
  getRoomsReportsCSV,
  getRoomsReportsList,
  getRoomsReportsTotal,
} from "@requests";
import {
  downloadFile,
  getFiltersForPage,
  parseTimeRange,
  setFiltersForPage,
} from "@helpers";
import { usePlacesWithLocationsDictionary } from "@hooks";
import { getDestinationsOptionsByLocationId } from "@features/pushNotifications/CreatePushModal/utils";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import CompaniesFilter from "../CompaniesFilter/CompaniesFilter";
import BookedByFilter from "../BookedByFilter/BookedByFilter";
import {
  IconWrap,
  ItemTitle,
  StyledLink,
  Superscription,
  SuperscriptionItem,
  TotalItemsWrap,
} from "../styles";
import { columns } from "./columns";

const RoomsReport = () => {
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    page: 1,
    locationIds: [locationId],
    ...getFiltersForPage("rooms-report"),
  });

  useEffect(() => {
    setFilters((oldFilters) => ({
      ...oldFilters,
      ...getFiltersForPage("rooms-report"),
    }));
  }, []);

  useEffect(() => {
    setFiltersForPage("rooms-report", filters);
  }, [filters]);

  const [isCSVLoading, setIsCSVLoading] = useState(false);

  const { data: reportsListData, isLoading: isReportsListDataLoading } =
    useQuery({
      queryKey: ["reportsList", filters],
      queryFn: getRoomsReportsList,
    });

  const { data: reportsTotalData, isLoading: isReportsTotalDataLoading } =
    useQuery({
      queryKey: ["reportsTotal", filters],
      queryFn: getRoomsReportsTotal,
    });

  const { data: locationsData } = usePlacesWithLocationsDictionary();

  const rows = useMemo(
    () =>
      reportsListData?.data.map((booking) => ({
        ...booking,
        id: booking.bookingId,
        payment:
          booking.paymentType === "credits"
            ? `${booking.payment} Credits`
            : `${booking.payment} ${booking.paymentCurrency}`,
        timeRange:
          booking.fromDateTime && booking.toDateTime
            ? parseTimeRange(booking.fromDateTime, booking.toDateTime)
            : "",
      })),
    [reportsListData?.data]
  );

  const destinationsOptions = useMemo(
    () =>
      getDestinationsOptionsByLocationId(
        locationsData?.data,
        filters.locationIds
      ),
    [locationsData]
  );

  const handleLocationChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationIds;

      if (data.length) {
        const [{ locationId }] = data;

        if (data.length !== 1 || locationId !== -1) {
          newData.locationIds = data.map(({ locationId }) => locationId);
        }
      }

      return newData;
    });
  }, []);

  const handleCompanyChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.companyId;

      if (!data) {
        return newData;
      }

      const { id } = data;
      if (id) {
        newData.companyId = parseInt(id);
      }

      return newData;
    });
  }, []);

  const handleBookedByChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.bookedByUserId;

      if (!data) {
        return newData;
      }

      const { id } = data;
      if (id) {
        newData.bookedByUserId = parseInt(id);
      }

      return newData;
    });
  }, []);

  const handlePaymentTypeChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (data) {
        newData.paymentType = data;
      } else {
        delete newData.paymentType;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const handleCSV = useCallback(
    async (event) => {
      event.preventDefault();

      if (!isCSVLoading) {
        setIsCSVLoading(true);
        const data = await getRoomsReportsCSV({
          queryKey: ["reportsCSV", filters],
        });
        setIsCSVLoading(false);
        downloadFile(data);
      }
    },
    [filters, isCSVLoading]
  );

  const handleRoomNumberChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.roomNumberSearch = value;
      } else {
        delete newData.roomNumberSearch;
      }
      return newData;
    });
  }, []);

  const handleMeetingDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.bookingDay = value;
      } else {
        delete newData.bookingDay;
      }
      return newData;
    });
  }, []);

  const handleFromDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.fromCreatedAt = value;
      } else {
        delete newData.fromCreatedAt;
      }
      return newData;
    });
  }, []);

  const handleToDateChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.toCreatedAt = value;
      } else {
        delete newData.toCreatedAt;
      }
      return newData;
    });
  }, []);

  const superscription = (
    <Superscription>
      <TotalItemsWrap>
        <SuperscriptionItem>
          <ItemTitle>Total bookings&nbsp;</ItemTitle>
          <span>{reportsListData?.pagination?.total}</span>
        </SuperscriptionItem>
        <SuperscriptionItem>
          <ItemTitle>Total Credits Used&nbsp;</ItemTitle>
          <span>{reportsTotalData?.data?.totalCredits}</span>
        </SuperscriptionItem>
        {reportsTotalData?.data?.totalMoney?.length
          ? reportsTotalData.data.totalMoney.map(({ amount, currency }) => (
              <SuperscriptionItem key={currency}>
                <ItemTitle>Total paid&nbsp;</ItemTitle>
                <span>{`${amount} ${currency}`}</span>
              </SuperscriptionItem>
            ))
          : null}
      </TotalItemsWrap>
      <StyledLink href="#csv" target="_blank" onClick={handleCSV}>
        {isCSVLoading && "⏳ "}
        <IconWrap>
          <ExportIcon />
        </IconWrap>
        Export
      </StyledLink>
    </Superscription>
  );

  const isLoading = isReportsListDataLoading || isReportsTotalDataLoading;

  return (
    <>
      <FiltersContainer
        defaultValues={filters}
        sx={{ pt: 0, flexWrap: "wrap" }}
      >
        <Box sx={{ "& > div": { minWidth: "230px" } }}>
          <TreeSelect
            id="locationsFilter"
            name="locationsIds"
            mode="multiSelect"
            options={destinationsOptions}
            onChange={handleLocationChange}
            texts={{ placeholder: "Location" }}
          />
        </Box>
        <SearchFieldFilter
          id="searchRoomNumberFilter"
          name="roomNumberSearch"
          placeholder="Room number"
          onChange={handleRoomNumberChange}
          customWidth="140px"
        />
        <CompaniesFilter label="Company" onChange={handleCompanyChange} />
        <DatePickerField
          id="meetingDateFilter"
          name="bookingDay"
          placeholder="Meeting date"
          onChange={handleMeetingDateChange}
          customWidth="140px"
          showResetButton
        />
        <DatePickerField
          id="fromDateFilter"
          name="fromCreatedAt"
          placeholder="From (Date Booked)"
          onChange={handleFromDateChange}
          customWidth="160px"
          showResetButton
        />
        <DatePickerField
          id="toDateFilter"
          name="toCreatedAt"
          placeholder="To (Date Booked)"
          onChange={handleToDateChange}
          customWidth="160px"
          showResetButton
        />
        <BookedByFilter
          label="Booked by"
          onChange={handleBookedByChange}
          // locationIds={filters.locationIds} TODO: update this prop
        />
        <DropdownSimpleFilter
          id="paymentTypeFilter"
          name="paymentType"
          label="Payment method"
          options={paymentTypes}
          onChange={handlePaymentTypeChange}
          customWidth="140px"
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={rows}
          columns={columns}
          superscription={superscription}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={reportsListData?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default RoomsReport;
