import { combineReducers } from "redux";

import { USER_STORE_NAME, LOCATION_STORE_NAME } from "@constants";
import userStore from "./user/duck";
import locationStore from "./location/duck";

const rootReducer = combineReducers({
  [USER_STORE_NAME]: userStore,
  [LOCATION_STORE_NAME]: locationStore,
});

export default rootReducer;
