import { HEADER_HEIGHT } from "@constants";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  display: "flex",
  minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
}));

export const ContentWrapper = styled(Box)(({ isEditMode }) => ({
  padding: isEditMode ? "40px 0 100px " : "82px 125px 100px 50px",
  width: "100%",
}));

export const ButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "10px",
  paddingTop: "25px",
  marginTop: "24px",
  borderTop: `1px solid ${theme.palette.gray[500]}`,
}));

export const CategoriesContainer = styled(Box)(({ theme, error }) => ({
  backgroundColor: theme.palette.white,
  padding: "14px",
  border: `1px solid ${error ? theme.palette.red : theme.palette.gray[700]}`,
  borderRadius: "6px",
}));

export const RemoveButtonContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  borderTop: `1px solid ${theme.palette.gray[500]}`,
  height: "72px",
  padding: "25px 30px",
}));
