import { arrayOf, func, number, shape } from "prop-types";
import { Tab, Tabs } from "@mui/material";

import { TabPanel } from "@atoms";

const TabsContainer = ({ tabs, activeTab, setActiveTab, ...props }) => (
  <>
    <Tabs
      {...props}
      value={activeTab}
      onChange={(_, newValue) => setActiveTab(newValue)}
    >
      {tabs.map(({ id, label }) => (
        <Tab key={id} label={label} sx={{ fontSize: 13 }} />
      ))}
    </Tabs>
    {tabs.map(({ component, id }, i) => (
      <TabPanel key={id} value={activeTab} index={i}>
        {component}
      </TabPanel>
    ))}
  </>
);

TabsContainer.propTypes = {
  tabs: arrayOf(shape({})).isRequired,
  activeTab: number.isRequired,
  setActiveTab: func.isRequired,
};

export default TabsContainer;
