import styled from "styled-components";

import { ReactComponent as Logo } from "@assets/svgs/LogoWithoutCopyright.svg";
import { ReactComponent as ContentManagerText } from "@assets/svgs/ContentManagerText.svg";

import { Icon } from "@atoms";
import { Typography } from "@mixins";

export const TextLink = styled(Typography)`
  font-weight: 600;
`;

export const LogoWithMargin = styled(Logo)`
  margin-bottom: 21px;
`;

export const ContentManagerTextWithMargin = styled(ContentManagerText)`
  margin-bottom: 50px;
`;

export const BackButton = styled(Icon)`
  transform: rotate(90deg);
`;
