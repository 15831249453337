import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  AddButton,
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { ROUTES } from "@constants";
import { getAnnouncements } from "@requests";
import { columns } from "./columns";
import { CreatedByFilter, LocationsFilter } from "@molecules";
import { FiltersContainer } from "@organisms";
import { statusesItems } from "@constants/statusesItems";
import { announcementTypesItems } from "@constants/typesItems";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import { getFiltersForPage, setFiltersForPage } from "@helpers";

const Announcements = () => {
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    locationId,
    page: 1,
    ...getFiltersForPage("announcements"),
  });

  useEffect(() => {
    setFiltersForPage("announcements", filters);
  }, [filters]);

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["announcements", filters],
    queryFn: getAnnouncements,
  });

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id) => navigate(`${ROUTES.Announcements}/${id}`),
    [navigate]
  );

  const onAddAnnouncementClick = useCallback(
    () => navigate(ROUTES.AddAnnouncement),
    [navigate]
  );

  const handleLocationChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationId;

      if (!value) {
        return newData;
      }

      if (value.id) {
        newData.locationId = value.id;
      }

      return newData;
    });
  }, []);

  const handleSearchChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.search = value;
      } else {
        delete newData.search;
      }
      return newData;
    });
  }, []);

  const handleTypeChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.type = value;
      } else {
        delete newData.type;
      }
      return newData;
    });
  }, []);

  const handleCreatedByIdChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.createdById = parseInt(value.id);
      } else {
        delete newData.createdById;
      }
      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.status;
      if (value) {
        newData.status = value;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const rows = useMemo(
    () =>
      data?.data?.map((location) => ({
        ...location,
        activityStatus:
          location.status === "published"
            ? location.activityStatus
            : location.status === "draft"
            ? "draft"
            : "",
      })),
    [data?.data]
  );

  const isLoading = isDataLoading;

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Title>Announcements</Title>

        <AddButton onClick={onAddAnnouncementClick}>Create new</AddButton>
      </Stack>

      <FiltersContainer defaultValues={filters}>
        <LocationsFilter
          name="locationId"
          label="Location"
          onChange={handleLocationChange}
        />
        <SearchFieldFilter
          id="searchAnnouncementNameFilter"
          name="search"
          placeholder="Search labels or content..."
          onChange={handleSearchChange}
        />
        <CreatedByFilter
          name="createdBy"
          label="Created By"
          onChange={handleCreatedByIdChange}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={statusesItems}
          onChange={handleStatusChange}
        />
        <DropdownSimpleFilter
          id="typesFilter"
          name="typeId"
          label="Type"
          options={announcementTypesItems}
          onChange={handleTypeChange}
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={rows}
          columns={columns}
          superscription={
            <Superscription
              name="item"
              count={data?.pagination?.total}
              isLoading={isLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Announcements;
