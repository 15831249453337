import { Controller, useFormContext } from "react-hook-form";
import { bool, func, node, string } from "prop-types";
import { InputAdornment, TextField } from "@mui/material";

import { Label } from "@atoms";

import { FlexWrapper } from "./styles";

const ControlledTextField = ({
  label,
  name,
  isLabelBold,
  titleMargin,
  icon,
  onChange,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <FlexWrapper>
      {label ? (
        <Label fontWeight={isLabelBold ? 600 : 400} sx={{ mb: titleMargin }}>
          {label}
        </Label>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            {...props}
            onChange={(...args) => {
              field.onChange(...args);
              onChange && onChange(...args);
            }}
            InputProps={{
              endAdornment: icon ? (
                <InputAdornment position="end">{icon}</InputAdornment>
              ) : null,
            }}
          />
        )}
      />
    </FlexWrapper>
  );
};

ControlledTextField.propTypes = {
  name: string.isRequired,
  label: string,
  isLabelBold: bool,
  titleMargin: string,
  icon: node,
  onChange: func,
};

ControlledTextField.defaultProps = {
  label: "",
  isLabelBold: false,
  titleMargin: "0",
  icon: null,
};

export default ControlledTextField;
