import { Announcement as View } from "@views";
import { Layout } from "@templates";

const Announcement = () => (
  <Layout withContainer={false}>
    <View />
  </Layout>
);

export default Announcement;
