import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Benefit,
  Benefits,
  Event,
  Events,
  Location,
  Locations,
  Login,
  Oops,
  PushNotifications,
  Reports,
  Room,
  Rooms,
  VerificationCode,
  Announcements,
  Announcement,
  AddAnnouncement,
  CombineRooms,
} from "@pages";
import { ErrorBoundary } from "@views";
import { ROUTES } from "@constants";
import { UserContextWrapper } from "@atoms";

const Router = () => (
  <BrowserRouter>
    <ErrorBoundary>
      <Routes>
        <Route path={ROUTES.Login} element={<Login />} />
        <Route path="" element={<Navigate to={ROUTES.PushNotifications} />} />
        <Route path={ROUTES.LoginFailure} element={<Oops />} />
        <Route path={ROUTES.VerificationCode} element={<VerificationCode />} />
        <Route
          path={ROUTES.PushNotifications}
          element={
            <UserContextWrapper>
              <PushNotifications />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Locations}
          element={
            <UserContextWrapper>
              <Locations />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Location}
          element={
            <UserContextWrapper>
              <Location />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Events}
          element={
            <UserContextWrapper>
              <Events />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Event}
          element={
            <UserContextWrapper>
              <Event />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.AddEvent}
          element={
            <UserContextWrapper>
              <Event />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Benefits}
          element={
            <UserContextWrapper>
              <Benefits />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Rooms}
          element={
            <UserContextWrapper>
              <Rooms />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.CombineRooms}
          element={
            <UserContextWrapper>
              <CombineRooms />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Room}
          element={
            <UserContextWrapper>
              <Room />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Benefit}
          element={
            <UserContextWrapper>
              <Benefit />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.AddBenefit}
          element={
            <UserContextWrapper>
              <Benefit />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Reports}
          element={
            <UserContextWrapper>
              <Reports />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Announcements}
          element={
            <UserContextWrapper>
              <Announcements />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.Announcement}
          element={
            <UserContextWrapper>
              <Announcement />
            </UserContextWrapper>
          }
        />
        <Route
          path={ROUTES.AddAnnouncement}
          element={
            <UserContextWrapper>
              <AddAnnouncement />
            </UserContextWrapper>
          }
        />
        <Route path="*" element={<h1>Not found</h1>} />
      </Routes>
    </ErrorBoundary>
  </BrowserRouter>
);

export default Router;
