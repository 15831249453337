import { func, string } from "prop-types";
import { Avatar as MuiAvatar } from "@mui/material";

const Avatar = ({ firstName, lastName, onClick, imageUrl }) => (
  <MuiAvatar
    src={imageUrl}
    onClick={onClick}
    sx={(theme) => ({
      bgcolor: theme.palette.grey[500],
      width: 32,
      height: 32,
    })}
  >
    {!imageUrl
      ? `${firstName ? firstName.trim().toUpperCase()[0] : ""}${
          lastName ? lastName.trim().toUpperCase()[0] : ""
        }`
      : null}
  </MuiAvatar>
);

Avatar.defaultProps = {
  firstName: "",
  lastName: "",
  imageUrl: "",
  onClick: () => {},
};

Avatar.propTypes = {
  firstName: string,
  lastName: string,
  onClick: func,
  imageUrl: string,
};

export default Avatar;
