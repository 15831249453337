import { useState } from "react";
import { arrayOf, func, number, shape, string } from "prop-types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { checkShouldOpenNestedItem } from "@helpers";

const NestedItem = ({ option, options, level, onClick, searchValue }) => {
  const [isOpen, setIsOpen] = useState(
    checkShouldOpenNestedItem(options, searchValue)
  );

  const handleOpenListClick = (e) => {
    e.stopPropagation();
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <ListItemButton onClick={handleOpenListClick}>
        <ListItemText primary={option.name} sx={{ pl: 2 * level }} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {option.suboptions.map(({ id: suboptionId }) => {
            const item = options.find(({ id }) => suboptionId === id);

            return item?.suboptions?.length ? (
              <NestedItem
                key={item.id}
                option={item}
                options={item.suboptions}
                onClick={onClick}
                level={level + 1}
                searchValue={searchValue}
              />
            ) : (
              <ListItemButton
                key={item.id}
                selected={option.id === item.id}
                onClick={() => onClick(item)}
              >
                <ListItemText
                  primary={item.name}
                  sx={{ pl: 2 * level, ml: 2 }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </Box>
  );
};

NestedItem.propTypes = {
  option: shape({}).isRequired,
  onClick: func,
  options: arrayOf(shape({})).isRequired,
  level: number,
  searchValue: string,
};

NestedItem.defaultProps = {
  level: 0,
  onClick: undefined,
  searchValue: "",
};

export default NestedItem;
