import { ReactComponent as RightArrow } from "@assets/svgs/RightArrow.svg";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { string, node } from "prop-types";
import { palette } from "@styles/palette";
import { Wrap, IconWrap } from "./styles";

const BackToPrevPage = ({ to, children }) => (
  <Wrap>
    <Link to={to}>
      <IconWrap>
        <RightArrow />
      </IconWrap>
      <Typography variant="body2" color={palette.black}>
        {children}
      </Typography>
    </Link>
  </Wrap>
);

BackToPrevPage.defaultProps = {
  to: undefined,
  children: null,
};

BackToPrevPage.propTypes = {
  to: string,
  children: node,
};

export default BackToPrevPage;
