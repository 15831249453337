import { ROUTES } from "@constants";
import { ReactComponent as PushNotificationsIcon } from "@assets/svgs/PushNotifications.svg";
import { ReactComponent as EventsIcon } from "@assets/svgs/Events.svg";
import { ReactComponent as LocationsIcon } from "@assets/svgs/Locations.svg";
import { ReactComponent as BenefitsIcon } from "@assets/svgs/Benefits.svg";
import { ReactComponent as RoomsIcon } from "@assets/svgs/Rooms.svg";
import { ReactComponent as ReportsIcon } from "@assets/svgs/Reports.svg";
import { ReactComponent as AnnouncementsIcon } from "@assets/svgs/Speaker.svg";

export const navigationItems = [
  {
    route: ROUTES.PushNotifications,
    icon: PushNotificationsIcon,
    name: "Push Notifications",
  },
  {
    route: ROUTES.Announcements,
    icon: AnnouncementsIcon,
    name: "Announcements",
  },
  {
    route: ROUTES.Events,
    icon: EventsIcon,
    name: "Events",
  },
  {
    route: ROUTES.Benefits,
    icon: BenefitsIcon,
    name: "Benefits",
  },
  {
    route: ROUTES.Rooms,
    icon: RoomsIcon,
    name: "Meeting Rooms",
  },
  {
    route: ROUTES.Reports,
    icon: ReportsIcon,
    name: "Reports",
  },
  {
    route: ROUTES.Locations,
    icon: LocationsIcon,
    name: "Locations",
  },
];
