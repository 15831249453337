import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrap = styled(Box)(() => ({
  "& .link-wrapper": {
    marginTop: "8px !important",
    "& > a": {
      color: "#326164",
      display: "inline-block",
      textDecoration: "none",

      "& > svg": {
        verticalAlign: "middle",
      },

      "& > p": {
        color: "#326164",
        display: "inline-block",
        lineHeight: "12px",
        marginLeft: "6px",
        verticalAlign: "middle",
      },
    },
  },

  "& .picker-wrapper": {
    position: "relative",
    marginTop: "0 !important",
    zIndex: 6,
    "& > div": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
    },
  },
}));
