import { BuildingProcedures, LocationDetails } from "@features";
import { EMPTY_CONTENT } from "@constants";

const checkIsEmptyProp = (value) => value === EMPTY_CONTENT || !value;
// Replacing redundant special symbols from the editor content
const checkEmptyValue = (value) =>
  value && value !== EMPTY_CONTENT ? value.replace(/[\r]/g, "") : "";

export const createLocationFormValues = (data) => ({
  homepageImage: data?.data?.image || "",
  splashImage: data?.data?.logo || "",
  address: data?.data?.address || "",
  locationToAmenity: data?.data?.locationToAmenity?.length
    ? data?.data?.locationToAmenity.map(
        ({ amenity: { id, displayName }, description }) => ({
          amenity: {
            id,
            name: displayName,
          },
          description,
        })
      )
    : [
        {
          amenity: null,
          description: "",
        },
      ],
  details: {
    printerInstructionsPC: {
      value: checkEmptyValue(data?.data?.details?.printerInstructionsPC),
      isEmpty: checkIsEmptyProp(data?.data?.details?.printerInstructionsPC),
    },
    printerInstructionsMAC: {
      value: checkEmptyValue(data?.data?.details?.printerInstructionsMAC),
      isEmpty: checkIsEmptyProp(data?.data?.details?.printerInstructionsMAC),
    },
    securePrinting: {
      value: checkEmptyValue(data?.data?.details?.securePrinting),
      isEmpty: checkIsEmptyProp(data?.data?.details?.securePrinting),
    },
    scanningInstructions: {
      value: checkEmptyValue(data?.data?.details?.scanningInstructions),
      isEmpty: checkIsEmptyProp(data?.data?.details?.scanningInstructions),
    },
    faxNumbers: {
      value: checkEmptyValue(data?.data?.details?.faxNumbers),
      isEmpty: checkIsEmptyProp(data?.data?.details?.faxNumbers),
    },
    buildingProcedures: {
      value: checkEmptyValue(data?.data?.details?.buildingProcedures),
      isEmpty: checkIsEmptyProp(data?.data?.details?.buildingProcedures),
    },
  },
  wifiSettings: {
    members: {
      network: data?.data?.wifiSettings?.members?.network || "",
      password: data?.data?.wifiSettings?.members?.password || "",
    },
    guests: {
      network: data?.data?.wifiSettings?.guests?.network || "",
      password: data?.data?.wifiSettings?.guests?.password || "",
    },
  },
  isActivateLocation: !!data?.data?.isWhiteLabeled,
});

export const createRequestBody = ({
  address,
  homepageImage,
  splashImage,
  isActivateLocation,
  wifiSettings: { members, guests },
  details,
  locationToAmenity,
}) => {
  const formData = new FormData();
  formData.append("address", address);
  if (typeof homepageImage === "object") {
    formData.append("image", homepageImage);
  }

  if (
    typeof splashImage === "object" &&
    splashImage !== null &&
    isActivateLocation
  ) {
    formData.append("logo", splashImage);
  }

  Object.entries(members).forEach((item) => {
    const [key, value] = item;
    formData.append(`wifiSettings[members][${key}]`, value);
  });

  Object.entries(guests).forEach((item) => {
    const [key, value] = item;
    formData.append(`wifiSettings[guests][${key}]`, value);
  });

  Object.entries(details).forEach((item) => {
    const [key, value] = item;
    formData.append(`details[${key}]`, value.isEmpty ? "" : value.value);
  });

  locationToAmenity.forEach((amenity, index) =>
    Object.entries(amenity).forEach((item) => {
      const [key, value] = item;
      if (key === "amenity") {
        const amenityId = value.id;
        const keyName = "amenityId";
        formData.append(`locationToAmenity[${index}][${keyName}]`, amenityId);
      } else {
        formData.append(`locationToAmenity[${index}][${key}]`, value);
      }
    })
  );

  return formData;
};

export const tabs = [
  {
    id: 1,
    label: "Location Details",
    component: <LocationDetails />,
  },
  {
    id: 2,
    label: "Rules & Procedures",
    component: <BuildingProcedures />,
  },
];
