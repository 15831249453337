import { string } from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as InfoIcon } from "@assets/svgs/Info.svg";

const InfoTooltip = ({ title }) => (
  <Tooltip title={title} placement="bottom-start">
    <InfoIcon />
  </Tooltip>
);

InfoTooltip.propTypes = {
  title: string.isRequired,
};

export default InfoTooltip;
