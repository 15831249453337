import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  width: "260px",
  height: "197px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  border: `1px dashed ${theme.palette.gray[200]}`,
  backgroundColor: theme.palette.gray[600],
  borderRadius: "6px",
}));
