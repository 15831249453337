import { useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { func, string, arrayOf, number, bool } from "prop-types";

import styled, { css } from "styled-components";
import { formatNumber } from "libphonenumber-js";

import { Auth } from "@templates";
import { VerificationCodeInput } from "@atoms/Input";

import { Button, Typography, Flex } from "@mixins";
import { ROUTES } from "@constants";
import { palette } from "@styles/palette";

const VerificationCode = ({
  isLoading,
  error,
  onCodeSubmit,
  onVerificationCodeChange,
  verificationCode,
  codeAddress,
  onResendClick,
}) => {
  const navigate = useNavigate();

  const text = useMemo(
    () =>
      `We just send a 4-digit code to\n${
        formatNumber(codeAddress, "INTERNATIONAL") || codeAddress
      }`,
    [codeAddress]
  );

  const handleBackClick = useCallback(() => {
    navigate(ROUTES.Login);
  });

  return (
    <Auth
      hasBackButton
      onBackClick={handleBackClick}
      useLogo
      text={text}
      isLoading={isLoading}
    >
      <Flex flexDirection="column">
        <VerificationCodeInput
          quantityOfDigits={4}
          verificationCode={verificationCode}
          onVerificationCodeChange={onVerificationCodeChange}
          hasError={!!error}
          onCodeSubmit={onCodeSubmit}
        />
        <Error
          mt="15px"
          mb="65px"
          alignSelf="start"
          hasError={error}
          fontSize={{ xs: "12px", sm: "14px" }}
          color={palette.red}
        >
          {error}
        </Error>
      </Flex>
      <Flex alignItems="center">
        <Typography mr="3px" color={palette.gray[200]} fontSize="14px">
          Haven&apos;t received the code?
        </Typography>
        <Button
          onClick={onResendClick}
          fontSize="14px"
          color={palette.darkGreen}
          backgroundColor="transparent"
        >
          Resend
        </Button>
      </Flex>
    </Auth>
  );
};

const Error = styled(Typography)`
  ${({ hasError }) => css`
    visibility: ${hasError ? "visible" : "hidden"};
    height: 8px;
  `}
`;

VerificationCode.propTypes = {
  isLoading: bool.isRequired,
  error: string.isRequired,
  onCodeSubmit: func.isRequired,
  onVerificationCodeChange: func.isRequired,
  verificationCode: arrayOf(number).isRequired,
  codeAddress: string.isRequired,
  onResendClick: func.isRequired,
};

export default VerificationCode;
