import { DialogTitle as MuiDialogTitle } from "@mui/material";
import { Close } from "@mui/icons-material";
import { func, node } from "prop-types";

import { IconButtonStyled } from "./styles";

const DialogTitle = ({ children, onClose, ...props }) => (
  <MuiDialogTitle {...props}>
    {children}
    {onClose ? (
      <IconButtonStyled aria-label="close" onClick={onClose}>
        <Close />
      </IconButtonStyled>
    ) : null}
  </MuiDialogTitle>
);

DialogTitle.defaultProps = {
  children: null,
  onClose: () => {},
};

DialogTitle.propTypes = {
  children: node,
  onClose: func,
};

export default DialogTitle;
