import { forwardRef } from "react";
import { border, flexbox, layout, position, space } from "styled-system";
import styled, { css } from "styled-components";
import { elementType, func, string } from "prop-types";

const Icon = forwardRef(({ SVG, className, onClick }, ref) => (
  <SVG ref={ref} onClick={onClick} className={className} />
));

Icon.defaultProps = {
  onClick: () => {},
};

Icon.propTypes = {
  SVG: elementType.isRequired,
  className: string.isRequired,
  onClick: func,
};

Icon.displayName = "Icon";

export default styled(Icon)`
  ${({ pointer, color }) => css`
    ${pointer && "cursor: pointer;"}

    path:first-child {
      fill: ${color};
    }

    ${space};
    ${border};
    ${flexbox};
    ${layout};
    ${position};
  `}
`;
