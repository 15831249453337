import { string, func } from "prop-types";
import { useCallback } from "react";
import {
  StyledRadio,
  StyledFormControlLabel,
  CheckedRadioIcon,
  RadioIcon,
} from "./styles";

const RadioButtonWithLabel = ({ label, value, onChange, ...props }) => {
  const onHandleChange = useCallback(
    ({ target: { value: fieldValue } }) => {
      if (onChange) {
        onChange(fieldValue);
      }
    },
    [onChange]
  );

  return (
    <StyledFormControlLabel
      label={label}
      value={value}
      control={
        <StyledRadio
          disableRipple
          icon={<RadioIcon />}
          checkedIcon={<CheckedRadioIcon />}
        />
      }
      onChange={onHandleChange}
      {...props}
    />
  );
};

RadioButtonWithLabel.defaultProps = {
  onChange: undefined,
};

RadioButtonWithLabel.propTypes = {
  label: string.isRequired,
  value: string.isRequired,
  onChange: func,
};

export default RadioButtonWithLabel;
