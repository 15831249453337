import { v4 as uuidv4 } from "uuid";

export const getFeaturesList = (features) =>
  features?.map((item) => ({
    label: item.displayName,
    value: String(item.id),
  }));

export const transformRoomsByStructure = (data = []) =>
  data.map(({ id, roomNumber }) => ({
    id: String(id),
    name: roomNumber,
  }));

export const transformLocationsStructure = (data) => {
  const res = [];
  data?.forEach((item) => {
    const foundCountry = res.find((country) => item.country === country.name);
    if (foundCountry) {
      const foundCity = foundCountry.suboptions.find(
        (city) => item.city === city.name
      );
      if (foundCity) {
        const foundLocation = foundCity.suboptions.find(
          (location) => item.title === location.name
        );
        if (!foundLocation) {
          foundCity.suboptions.push({
            id: item.id,
            name: item.title,
            timezone: item.timeZone,
            city: item.city,
            country: item.country,
          });
        }
      } else {
        foundCountry.suboptions.push({
          id: uuidv4(),
          name: item.city,
          suboptions: [
            { id: item.id, name: item.title, timezone: item.timeZone },
          ],
          city: item.city,
          country: item.country,
        });
      }
    } else {
      res.push({
        id: uuidv4(),
        name: item.country,
        city: item.city,
        country: item.country,
        suboptions: [
          {
            id: uuidv4(),
            name: item.city,
            city: item.city,
            country: item.country,
            suboptions: [
              {
                id: item.id,
                name: item.title,
                timezone: item.timeZone,
                city: item.city,
                country: item.country,
              },
            ],
          },
        ],
      });
    }
  });
  return res;
};

const prepareGallery = (values) =>
  values
    .map((file, index) => {
      let formData;

      if (typeof file === "object") {
        formData = new FormData();
        if (file === null) {
          formData.append("image", file);
        } else {
          formData.append("image", file, file.name);
        }
        formData.append("isCover", index === 0);
        formData.append("position", index);
      }

      return formData;
    })
    .filter((formData) => !!formData);

export const prepareFormValues = (values) => {
  const {
    features,
    cmNotificationEnabled,
    firstMeetingRoom,
    secondMeetingRoom,
    gallery0,
    gallery1,
    gallery2,
    status,
    ...data
  } = values;

  return {
    ...data,
    cmNotificationEnabled,
    firstMeetingRoomId: parseInt(firstMeetingRoom, 10) || 0,
    secondMeetingRoomId: parseInt(secondMeetingRoom, 10) || 0,
    gallery: prepareGallery([gallery0, gallery1, gallery2]),
    status: status ? "active" : "inactive",
    features: features?.map((item) => +item) || [],
  };
};

export const createRoomFormValues = (featuresList, userLocation) => {
  const features =
    featuresList?.reduce((acc, feature) => {
      if (feature.label !== "Video Conferencing") {
        acc.push(feature.value);
      }
      return acc;
    }, []) ?? [];

  return {
    features,
    firstMeetingRoom: 0,
    secondMeetingRoom: 0,
    cmNotificationEnabled: true,
    status: true,
    location: {
      id: userLocation?.id,
      name: userLocation?.title,
      timezone: userLocation?.timeZone,
    },
  };
};
