/* eslint-disable react/prop-types */
import { Component } from "react";
import DropdownTreeSelect from "react-dropdown-tree-select";
import isEqual from "lodash.isequal";

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = { data: props.data };
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEqual(props.data, state.data)) {
      return {
        data: props.data,
      };
    }

    return null;
  }

  // eslint-disable-next-line react/no-arrow-function-lifecycle
  shouldComponentUpdate = (nextProps) => {
    const { data } = this.state;
    return !isEqual(nextProps.data, data);
  };

  render() {
    // eslint-disable-next-line
    const { data, ...rest } = this.props;
    const { data: stateData } = this.state;
    return <DropdownTreeSelect data={stateData} {...rest} />;
  }
}
