export const breakpoints = {
  values: {
    xs: 0,
    sm: 576,
    md: 992,
    lg: 1200,
    xl: 1400,
  },
};

export const getBreakpoint = (value) =>
  `@media (min-width:${breakpoints.values[value]}px)`;
