import { useLocation } from "react-router-dom";

import { Oops as View } from "@views";

const Oops = () => {
  const location = useLocation();

  return <View codeAddress={location?.state?.source || "test@user.com"} />;
};

export default Oops;
