import { arrayOf, string } from "prop-types";
import { BootstrapTooltip } from "@atoms";

const MultiplyList = ({ values = [] }) => {
  if (!values.length) {
    return null;
  }

  if (values.length === 1) {
    const [firstReceiver] = values;
    return firstReceiver;
  }

  const title = (
    <>
      {values.map((value) => (
        <p key={value}>{value}</p>
      ))}
    </>
  );

  return (
    <BootstrapTooltip title={title} placement="top">
      <p>Multiply ({values.length})</p>
    </BootstrapTooltip>
  );
};

MultiplyList.propTypes = {
  values: arrayOf(string).isRequired,
};

export default MultiplyList;
