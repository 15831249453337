import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  padding: "0 50px",
}));

export const DropdownWrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(8),
  "& .MuiInputBase-input": {
    height: "32px",
    lineHeight: "32px",
  },
  "& .MuiInputLabel-root": {
    top: "-9px",
  },
}));

export const DataTableWrap = styled(Box)(() => ({
  position: "relative",
  minHeight: "400px",
}));
