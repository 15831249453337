import { Room as View } from "@views";
import { Layout } from "@templates";

const Room = () => (
  <Layout withContainer={false}>
    <View />
  </Layout>
);

export default Room;
