import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  position: "relative",

  ".ProseMirror": {
    "> * + *": {
      marginTop: "0.75em",
    },

    ":focus": {
      outline: "none",
    },

    "ul, ol": {
      padding: " 0 1rem",
    },

    "h1,h2,h3,h4,h5,h6": {
      lineHeight: "1.1",
    },

    code: {
      backgroundColor: "rgba(#616161, 0.1)",
      color: "#616161",
    },

    pre: {
      background: "#0d0d0d",
      borderRadius: " 0.5rem",
      color: theme.palette.common.white,
      fontFamily: "'JetBrainsMono', monospace",
      padding: "0.75rem 1rem",

      code: {
        background: "none",
        color: "inherit",
        fontSize: "0.8rem",
        padding: 0,
      },
    },

    mark: {
      backgroundColor: "#faf594",
    },

    img: {
      height: "auto",
      maxWidth: "100%",
    },

    blockquote: {
      borderLeft: "2px solid rgba(#0d0d0d, 0.1)",
      paddingLeft: "1rem",
    },

    hr: {
      border: "none",
      borderTop: "2px solid rgba(#0d0d0d, 0.1)",
      margin: "2rem 0",
    },

    "ul[data-type='taskList']": {
      listStyle: "none",
      padding: 0,
      li: {
        alignItems: "center",
        display: "flex",

        "> label ": {
          flex: "0 0 auto",
          marginRight: " 0.5rem",
          userSelect: "none",
        },

        "> div": {
          flex: "1 1 auto",
        },
      },
    },
  },

  "& .input-adornment-wrapper": {
    position: "absolute",
    bottom: "15px",
    right: "12px",
  },
}));

export const Editor = styled(Box)(({ theme, error }) => ({
  backgroundColor: theme.palette.common.white,
  border: `1px solid ${error ? theme.palette.red : theme.palette.gray[700]}`,
  borderRadius: "6px",
  color: theme.palette.black,
  display: "flex",
  flexDirection: "column",
  maxHeight: "26rem",

  ".editor__header": {
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.gray[700]}`,
    display: "flex",
    flex: "0 0 auto",
    flexWrap: "wrap",
    padding: "0.25rem",
  },

  ".editor__content": {
    flex: "1 1 auto",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "1.25rem 1rem",
  },
}));

export const MenuItemButton = styled("button")(({ theme }) => ({
  backgroundColor: "transparent",
  border: "none",
  borderRadius: "0.4rem",
  color: theme.palette.black,
  height: "1.75rem",
  marginRight: "0.25rem",
  padding: "0.25rem",
  width: "1.75rem",
  cursor: "pointer",

  svg: {
    fill: "rgba(21, 21, 21, .3)",
    height: "100%",
    width: "100%",
  },

  "&:hover, &.is-active": {
    backgroundColor: theme.palette.black,
    color: theme.palette.common.white,
    svg: {
      fill: theme.palette.common.white,
    },
  },
}));
