import { Link as MuiLink } from "@mui/material";
import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { ReactComponent as LinkIcon } from "@assets/svgs/Link.svg";
import { getImage } from "@helpers";
import { BootstrapTooltip, DataTableEllipsisCell, StatusLabel } from "@atoms";

export const columns = [
  {
    id: 1,
    field: "salesforceUrl",
    headerName: "",
    width: 20,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <div onClick={(e) => e.stopPropagation()}>
          <MuiLink href={value} target="_blank" rel="noreferrer">
            <LinkIcon />
          </MuiLink>
        </div>
      ) : (
        <MissingValue />
      ),
    headerAlign: "center",
    align: "center",
    flex: 0,
  },
  {
    id: 2,
    field: "image",
    headerName: "Image",
    sortable: false,
    renderCell: ({ value }) => (value ? getImage(value) : <MissingValue />),
    flex: 0,
  },
  {
    id: 3,
    field: "locationName",
    headerName: "Location Name",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 4,
    field: "locationDisplayName",
    headerName: "Location Display Name",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 5,
    field: "address",
    headerName: "Address",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 6,
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <StatusLabel value={value} />,
    flex: 0,
  },
];
