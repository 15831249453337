import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import { SIDEBAR_WIDTH } from "@constants";
import { styled } from "@mui/material/styles";

export const AppBar = styled(MuiAppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  marginLeft: SIDEBAR_WIDTH,
  width: `calc(100% - ${SIDEBAR_WIDTH}px)`,
  minHeight: 50,
  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
}));

export const Wrap = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: theme.palette.black,
  paddingLeft: theme.spacing(1),
}));
