import { useCallback, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Stack } from "@mui/material";
import {
  AddButton,
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { getRooms } from "@requests";
import { ROUTES, statusesItems } from "@constants";
import { FiltersContainer } from "@organisms";
import { LocationsFilter } from "@molecules";
import { columns } from "./columns";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import { getFiltersForPage, setFiltersForPage } from "@helpers";

const Rooms = () => {
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    locationId,
    page: 1,
    ...getFiltersForPage("rooms"),
  });

  useEffect(() => {
    setFiltersForPage("rooms", filters);
  }, [filters]);

  const { isLoading, data } = useQuery({
    queryKey: ["rooms", filters],
    queryFn: getRooms,
  });

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id) => navigate(`${ROUTES.Rooms}/${id}`),
    [navigate]
  );

  const onCombineRoomsClick = useCallback(
    () => navigate(ROUTES.CombineRooms),
    [navigate]
  );

  const handleLocationChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationId;

      if (!value) {
        return newData;
      }

      if (value.id) {
        newData.locationId = value.id;
      }

      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.status = value;
      } else {
        delete newData.status;
      }
      return newData;
    });
  }, []);

  const handleRoomNumberChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.roomNumber = value;
      } else {
        delete newData.roomNumber;
      }
      return newData;
    });
  }, []);

  const handleSalesforceIDChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.salesforceId = value;
      } else {
        delete newData.salesforceId;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Title>Meeting Rooms</Title>
        <AddButton onClick={onCombineRoomsClick}>Add Combo Room</AddButton>
      </Stack>

      <FiltersContainer defaultValues={filters}>
        <LocationsFilter
          name="locationId"
          label="Location"
          onChange={handleLocationChange}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={statusesItems}
          onChange={handleStatusChange}
        />
        <SearchFieldFilter
          id="searchRoomNumberFilter"
          name="roomNumber"
          placeholder="Room number"
          onChange={handleRoomNumberChange}
        />
        <SearchFieldFilter
          id="searchSalesforceIDFilter"
          name="salesforceId"
          placeholder="Salesforce ID"
          onChange={handleSalesforceIDChange}
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={data?.data}
          columns={columns}
          superscription={
            <Superscription
              name="room"
              count={data?.pagination?.total}
              isLoading={isLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Rooms;
