import { Controller, useFormContext } from "react-hook-form";
import { bool, number, string } from "prop-types";
import { Label, RichTextEditor } from "@atoms";

const RichTextEditorWithLimit = ({
  name,
  error,
  label,
  isLabelBold,
  charactersLimit,
}) => {
  const { control } = useFormContext();

  return (
    <>
      {label ? (
        <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
      ) : null}
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <RichTextEditor
            error={error}
            onChange={onChange}
            value={value}
            charactersLimit={charactersLimit}
          />
        )}
      />
    </>
  );
};

RichTextEditorWithLimit.defaultProps = {
  name: "description",
  error: "",
  label: "",
  isLabelBold: false,
  charactersLimit: 200,
};

RichTextEditorWithLimit.propTypes = {
  error: string,
  name: string.isRequired,
  label: string,
  isLabelBold: bool,
  charactersLimit: number,
};

export default RichTextEditorWithLimit;
