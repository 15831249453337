import { adjustHTML } from "../../../helpers/common";

const mapCategoriesToDefaultOptions = (data) =>
  data.map(({ id }) => id.toString());

const restorePlaces = (places) =>
  places.map(({ country, city }) => ({
    label: city || country,
    value: city || country,
    parent: city && country ? country : undefined,
  }));

const setDefaultPlace = (place, isWorldWide) => {
  if (place?.length) {
    return restorePlaces(place);
  }
  if (isWorldWide) {
    return [
      {
        label: "all",
        value: "all",
        parent: undefined,
      },
    ];
  }
  return [];
};

const checkCouponData = (couponDescription, couponCode) => {
  if (couponDescription) {
    return "couponDescription";
  }

  if (couponCode) {
    return "couponCode";
  }

  return "";
};

export const defaultValues = {
  category: [],
  startDate: "",
  endDate: "",
  isFeatured: false,
  isNew: false,
  status: true,
  title: "",
  tagline: "",
  place: [],
  image: "",
  description: "",
  howToGet: "couponDescription",
  couponDescription: "",
  couponCode: "",
  website: "",
  instagram: "",
  facebook: "",
  phone: "",
  address: "",
};

export const generateDefaultFormValues = (data) => ({
  category: data?.data?.category
    ? mapCategoriesToDefaultOptions(data?.data?.category)
    : [],
  startDate: data?.data?.startDate || "",
  endDate: data?.data?.endDate || "",
  isFeatured: data?.data?.isFeatured || false,
  isNew: data?.data?.isNew || false,
  status: data?.data?.status ? data?.data?.status === "active" || false : true,
  title: data?.data?.title ? data?.data?.title : "",
  tagline: data?.data?.tagline ? data?.data?.tagline : "",
  place: setDefaultPlace(data?.data?.place, data?.data?.isWorldWide),
  image: data?.data?.image || "",
  description: data?.data?.description || "",
  howToGet: checkCouponData(
    data?.data?.couponDescription,
    data?.data?.couponCode
  ),
  couponDescription: data?.data?.couponDescription || "",
  couponCode: data?.data?.couponCode || "",
  website: data?.data?.website || "",
  instagram: data?.data?.instagram || "",
  facebook: data?.data?.facebook || "",
  phone: data?.data?.phone || "",
  address: data?.data?.address || "",
  latitude: data?.data?.latitude,
  longitude: data?.data?.longitude,
});

export const createRequestBody = (
  {
    category,
    startDate,
    endDate,
    isFeatured,
    isNew,
    status,
    title,
    tagline,
    place,
    image,
    description,
    howToGet,
    couponDescription,
    couponCode,
    website,
    instagram,
    facebook,
    phone,
    address,
    latitude,
    longitude,
  },
  isEditMode,
  { startDate: defaultStartDate, endDate: defaultEndDate }
) => {
  const fd = new FormData();

  category.forEach((categoryItem, index) =>
    fd.append(`category[${index}]`, categoryItem)
  );

  if (isEditMode) {
    const convertedDefaultStartDate = new Date(defaultStartDate).getTime();
    const convertedDefaultEndDate = new Date(defaultEndDate).getTime();
    const convertedStartDate = new Date(startDate).getTime();
    const convertedEndDate = new Date(endDate).getTime();

    if (status) {
      if (convertedDefaultStartDate !== convertedStartDate) {
        fd.append("startDate", startDate);
      }

      if (endDate && convertedDefaultEndDate !== convertedEndDate) {
        fd.append("endDate", endDate);
      }
    }
  } else {
    fd.append("startDate", startDate);

    if (endDate) {
      fd.append("endDate", endDate);
    }
  }

  fd.append("isFeatured", isFeatured);
  fd.append("isNew", isNew);
  fd.append("status", status ? "active" : "inactive");

  fd.append("title", title);
  fd.append("tagline", tagline);

  if (place.length) {
    const isWorldWide = place.find(({ value }) => value === "all");

    if (isWorldWide) {
      fd.append("isWorldWide", true);
    } else {
      fd.append("isWorldWide", false);

      place.forEach((placeItem, index) => {
        if (placeItem.parent) {
          fd.append(`place[${index}][country]`, placeItem.parent);
          fd.append(`place[${index}][city]`, placeItem.value);
        } else {
          fd.append(`place[${index}][country]`, placeItem.value);
        }
      });
    }
  }

  fd.append("image", image);
  fd.append("description", adjustHTML(description));

  if (couponDescription && howToGet === "couponDescription") {
    fd.append("couponDescription", adjustHTML(couponDescription));
    fd.append("couponCode", "");
  }

  if (couponCode && howToGet === "couponCode") {
    fd.append("couponCode", couponCode);
    fd.append("couponDescription", "");
  }

  fd.append("website", website);
  fd.append("instagram", instagram);
  fd.append("facebook", facebook);
  fd.append("phone", phone);
  fd.append("address", address);
  fd.append("latitude", address ? latitude : null);
  fd.append("longitude", address ? longitude : null);

  return fd;
};
