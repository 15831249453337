import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { parseStrictISOString, getLanguage } from "@helpers";
import { DATE_DEFAULT_FORMAT_TEMPLATE } from "@constants";

export const getBaseColumns = (withSlots) => [
  {
    id: 1,
    field: "fullName",
    headerName: "Name",
    sortable: false,
    renderCell: (params) =>
      `${params?.row?.user?.firstName || ""} ${
        params?.row?.user?.lastName || ""
      }`,
    flex: 1,
    width: 144,
  },
  {
    id: 2,
    field: "email",
    headerName: "Email",
    sortable: false,
    renderCell: (params) => params?.row?.user?.email || <MissingValue />,
    flex: withSlots ? 2 : 1,
    width: 200,
  },
  {
    id: 3,
    field: "phone",
    headerName: "Phone",
    sortable: false,
    renderCell: (params) => params?.row?.user?.phone || <MissingValue />,
    flex: 1,
    width: 111,
  },
  {
    id: 4,
    field: "date",
    headerName: "Date",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseStrictISOString(value, DATE_DEFAULT_FORMAT_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 1,
    width: 106,
  },
];

export const slottedEventColumns = [
  {
    id: 5,
    field: "timeInterval",
    headerName: "Time Slot",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
    width: 104,
  },
  {
    id: 6,
    field: "service",
    headerName: "Service",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
    width: 116,
  },
  {
    id: 7,
    field: "price",
    headerName: "Price",
    sortable: false,
    renderCell: (params) =>
      new Intl.NumberFormat(getLanguage(), {
        style: "currency",
        currency: params?.row?.currency,
      }).format(params?.row?.price),
    flex: 1,
    width: 84,
  },
];
