import { ReactComponent as NoImage } from "@assets/svgs/NoImage.svg";

/**
 * @param {String} image
 * @returns {JSX.Element}
 */
export const getImage = (image) =>
  image ? (
    <img
      src={image}
      style={{
        height: "64px",
        width: "100%",
        objectFit: "cover",
        borderRadius: "2px",
      }}
      alt="Event"
    />
  ) : (
    <NoImage />
  );
