import * as z from "zod";

export const schema = z
  .object({
    label: z.string().min(1, "Required field"),
    content: z.string().min(1, "Required field"),
    destinations: z
      .array(
        z.object({
          label: z.string(),
          value: z.string().or(z.number()),
          _depth: z.number(),
        })
      )
      .nonempty("Required field")
      .optional(),
    scheduled: z.boolean(),
    time: z.union([
      z.object({
        $d: z.date(),
      }),
      z.null(),
    ]),
    date: z.string(),
  })
  .superRefine((input, ctx) => {
    const { scheduled, time, date } = input;

    if (scheduled && time === null) {
      ctx.addIssue({
        path: ["time"],
        code: z.ZodIssueCode.invalid_date,
        message: "Required field",
      });
    }

    if (scheduled && !date) {
      ctx.addIssue({
        path: ["date"],
        code: z.ZodIssueCode.custom,
        message: "Required field",
      });
    }
  });
