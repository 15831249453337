import { useCallback, useEffect, useMemo, useState } from "react";
import { func, number } from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { FiltersContainer } from "@organisms";
import { DatePickerField } from "@molecules";
import { DataTable, Loader } from "@atoms";
import { getCreditsReportsList } from "@requests";
import {
  getFiltersForPage,
  getTimeInMilliseconds,
  setFiltersForPage,
} from "@helpers";
import { palette } from "@styles";
import CompaniesFilter from "../CompaniesFilter/CompaniesFilter";
import { columns } from "./columns";
import { Box } from "@mui/material";

const CreditsReport = ({ onSetBookingsActiveTab }) => {
  const [filters, setFilters] = useState({
    companyId: undefined,
    month: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1,
      -new Date().getTimezoneOffset() / 60
    )
      .toISOString()
      .substring(0, 10),
    ...getFiltersForPage("credits-report"),
  });

  useEffect(() => {
    setFiltersForPage("credits-report", filters);
  }, [filters]);

  const { data: reportsListData, isFetching: isReportsListDataLoading } =
    useQuery({
      queryKey: ["reportsList", filters],
      queryFn: getCreditsReportsList,
      enabled: !!filters.companyId && !!filters.month,
    });

  const CustomTotalUsedElement = ({ totalUsed }) => (
    <a
      href="#"
      onClick={() => {
        handleOpenBookingsTab();
      }}
    >
      {totalUsed}
    </a>
  );

  CustomTotalUsedElement.propTypes = {
    totalUsed: number,
  };

  const rows = useMemo(() => {
    if (reportsListData) {
      if (reportsListData.data.creditPackages.length) {
        if (
          getTimeInMilliseconds(new Date()) <
          getTimeInMilliseconds(filters.month)
        ) {
          return [];
        }

        return [
          ...reportsListData.data.creditPackages,
          {
            id: "Total",
            type: "Total",
            title: "Total",
            link: "",
            available: reportsListData.data?.totals?.available,
            used: (
              <CustomTotalUsedElement
                totalUsed={reportsListData.data?.totals?.used}
              />
            ),
            remaining: reportsListData.data?.totals?.remaining,
            availableFromDate: "",
            availableToDate: "",
          },
        ];
      } else {
        return [];
      }
    }
  }, [reportsListData, CustomTotalUsedElement, filters.month]);

  const handleCompanyChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.companyId;

      if (!data) {
        return newData;
      }

      const { id } = data;
      if (id) {
        newData.companyId = parseInt(id);
      }

      return newData;
    });
  }, []);

  const handleMonthChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.month = new Date(
          new Date(value).getFullYear(),
          new Date(value).getMonth(),
          new Date(value).getDate(),
          -new Date().getTimezoneOffset() / 60
        )
          .toISOString()
          .substring(0, 10);
      } else {
        delete newData.month;
      }
      return newData;
    });
  }, []);

  const handleOpenBookingsTab = () => {
    if (filters.month && filters.companyId) {
      const date = new Date(filters.month);
      setFiltersForPage("rooms-report", {
        companyId: filters.companyId,
        locationIds: [],
        fromCreatedAt: new Date(date.getFullYear(), date.getMonth(), 1),
        toCreatedAt: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      });
      onSetBookingsActiveTab();
    }
  };

  const CustomNoRowsOverlay = () => {
    const isNoData =
      !filters.month ||
      !filters.companyId ||
      getTimeInMilliseconds(new Date()) > getTimeInMilliseconds(filters.month);

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        {isNoData ? (
          <Box sx={{ mt: 1 }}>No data</Box>
        ) : (
          <Box sx={{ mt: 1 }}>
            Report is available for current and past months.
          </Box>
        )}
      </Box>
    );
  };

  const isLoading = isReportsListDataLoading;

  return (
    <>
      <FiltersContainer
        defaultValues={filters}
        sx={{ pt: 0, flexWrap: "wrap" }}
      >
        <CompaniesFilter label="Company" onChange={handleCompanyChange} />
        <DatePickerField
          id="monthFilter"
          name="month"
          placeholder="Month"
          onChange={handleMonthChange}
          customWidth="160px"
          showMonthYearPicker
          showResetButton
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <Box
          sx={{
            height: 400,
            width: "100%",
            "& .style-Total": {
              fontWeight: "bold",
              borderTop: "2px solid black",
            },
          }}
        >
          <DataTable
            hideFooterPagination
            rows={rows}
            columns={columns}
            paginationMode="server"
            rowCount={reportsListData?.pagination?.total}
            getRowClassName={(params) => `style-${params.row.type}`}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
          />
        </Box>
      )}
    </>
  );
};

CreditsReport.propTypes = {
  onSetBookingsActiveTab: func.isRequired,
};

export default CreditsReport;
