import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  display: "flex",
}));

export const ContentWrapper = styled(Box)(() => ({
  padding: "40px 0 100px 0",
  width: "100%",
}));

export const CategoriesWrap = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const BoldTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  marginBottom: theme.spacing(2.5),
}));

export const FieldValue = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
}));

export const FieldsWrap = styled(Box)(({ theme }) => ({
  minWidth: "390px",
  marginRight: theme.spacing(26),
}));

export const Container = styled(Box)(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(11.5),
  paddingBottom: theme.spacing(7.5),
  borderBottom: `1px solid ${theme.palette.gray[500]}`,
}));

export const TimeWrap = styled("span")(({ theme }) => ({
  display: "inline-block",
  marginLeft: theme.spacing(5),
}));

export const ImageWrap = styled(Box)(() => ({
  width: 260,
  height: 196,
  "& > img": {
    width: 260,
    height: 196,
    objectFit: "cover",
    borderRadius: 6,
  },
}));
