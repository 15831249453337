import styled, { css } from "styled-components";
import {
  layout,
  border,
  space,
  position,
  typography,
  variant,
  color,
  flexbox,
} from "styled-system";
import { palette } from "@styles/palette";

export const Button = styled.button`
  ${({ disabled }) => css`
    border: none;
    cursor: pointer;
    font-weight: 600;
    padding: 0;
    white-space: nowrap;
    color: ${palette.black};

    ${variant({
      variants: {
        primary: {
          backgroundColor: disabled ? palette.gray[100] : palette.darkGreen,
          color: palette.white,
          borderRadius: "2px",
        },
        secondary: {
          color: palette.black,
          border: `1px solid ${palette.gray[400]}`,

          svg: {
            path: {
              fill: palette.black,
            },
          },

          "&:hover": {
            backgroundColor: palette.darkGreen,
            border: `1px solid ${palette.darkGreen}`,
            color: palette.white,

            svg: {
              path: {
                fill: palette.white,
              },
            },
          },
        },
        tertiary: {
          backgroundColor: palette.pink,

          "&:hover": {
            backgroundColor: palette.darkPink,
          },
        },
        burger: {
          backgroundColor: palette.darkRed,
        },
      },
    })}

    ${color}
    ${position}
    ${typography}
    ${border}
    ${layout}
    ${space}
    ${flexbox}
  `}
`;
