import { Divider, Stack, Typography } from "@mui/material";
import { arrayOf, string, shape, number, bool } from "prop-types";
import { useFormContext } from "react-hook-form";

import { InfoTooltip } from "@atoms";
import { ImageUpload } from "@molecules";

const RoomGallery = () => {
  const { getValues } = useFormContext();

  return (
    <Stack spacing={5}>
      <Stack direction="row" spacing={3} alignItems="center">
        <Typography variant="h3">Room Images</Typography>
        <InfoTooltip title="3 images per room. The first image if the cover image." />
      </Stack>
      <Stack
        direction="row"
        spacing={5}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <ImageUpload
          imageURL={getValues("gallery0")}
          fieldName="gallery0"
          isCover
        />
        <Stack direction="row" spacing={5}>
          <ImageUpload imageURL={getValues("gallery1")} fieldName="gallery1" />
          <ImageUpload imageURL={getValues("gallery2")} fieldName="gallery2" />
        </Stack>
      </Stack>
    </Stack>
  );
};

RoomGallery.defaultProps = {
  gallery: [],
};

RoomGallery.propTypes = {
  gallery: arrayOf(
    shape({
      image: string,
      position: number,
      isCover: bool,
    })
  ),
};

export default RoomGallery;
