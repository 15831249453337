import { useCallback, useState } from "react";
import {
  func,
  string,
  // number, arrayOf
} from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { GenericSearchFilter } from "@molecules";
import { getUsers } from "@requests";
import { transformUsersByStructure } from "@views/Event/utils";

const BookedByFilter = ({ label, onChange }) => {
  const [currentValue, setCurrentValue] = useState("");
  const { data: itemsData } = useQuery({
    queryKey: [
      "users-room-bookings-report",
      {
        // locationId: locationIds,
        pageSize: 1000, // TODO: update page size
        search: currentValue,
      },
    ],
    queryFn: getUsers,
    // enabled: !!locationIds,
  });

  const filterItemsFunction = useCallback(
    (searchValue) => {
      setCurrentValue(searchValue);
      return itemsData?.data;
    },
    [itemsData?.data]
  );

  return (
    <GenericSearchFilter
      name="bookedByUserId"
      label={label}
      itemsData={itemsData}
      transformFunction={transformUsersByStructure}
      filterItemsFunction={filterItemsFunction}
      onChange={onChange}
      customWidth="140px"
      menuPosition="right"
    />
  );
};

BookedByFilter.propTypes = {
  label: string,
  onChange: func,
  // locationIds: arrayOf(number),
};

export default BookedByFilter;
