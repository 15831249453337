import { useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { func, string } from "prop-types";
import { TextField } from "@mui/material";
import { useDebounce } from "use-debounce";
import { ClearFilter } from "@atoms";
import { Wrap } from "./styles";

const SearchFieldFilter = ({ name, onChange, customWidth, ...restProps }) => {
  const { control, setValue, watch } = useFormContext();
  const searchValue = watch(name);
  const [debouncedValue] = useDebounce(searchValue, 400);

  const handleOnChange = useCallback(({ target: { value } }) => {
    setValue(name, value);
  }, []);

  const handleReset = useCallback(() => {
    setValue(name, "");
  }, []);

  useEffect(() => {
    onChange && onChange(debouncedValue);
  }, [debouncedValue]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Wrap customWidth={customWidth}>
          <TextField {...field} {...restProps} onChange={handleOnChange} />
          <ClearFilter isVisible={!!field.value} onClick={handleReset} />
        </Wrap>
      )}
    />
  );
};

SearchFieldFilter.defaultProps = {
  defaultValue: "",
  customWidth: "280px",
};

SearchFieldFilter.propTypes = {
  name: string,
  defaultValue: string,
  onChange: func,
  customWidth: string,
};

export default SearchFieldFilter;
