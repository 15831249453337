import { useCallback } from "react";
import { arrayOf, bool, func, oneOf, shape, string } from "prop-types";
import "react-dropdown-tree-select/dist/styles.css";
import { Controller, useFormContext } from "react-hook-form";
import { Box } from "@mui/material";
import { ClearFilter } from "@atoms";
import { lazyFunction } from "@helpers";
import { StyledFormLabel, Wrap } from "./styles";
import Container from "./Container";

const TreeSelect = ({
  id,
  name,
  options,
  mode,
  label,
  onChange,
  error,
  showResetButton,
  ...props
}) => {
  const { control, setValue } = useFormContext();

  const onHandleChange = (currentNode, selectedNodes) => {
    if (mode === "multiSelect") {
      setValue(name, selectedNodes);
      if (onChange) {
        onChange(selectedNodes);
      }
    } else {
      const { locationId, value, label, parent } = currentNode;
      const nodeData = {
        id: locationId,
        value,
        label,
        parent,
      };

      const nodeSelected = selectedNodes.some(
        ({ value }) => value === currentNode.value
      );

      setValue(name, nodeSelected ? nodeData : undefined);
      if (onChange) {
        onChange(nodeSelected ? nodeData : undefined);
      }
    }
  };

  const handleReset = useCallback(() => {
    const value = mode === "multiSelect" ? [] : undefined;
    // TODO: FIX: it doesn't remove tags from component, but state is cleared
    setValue(name, value);
    onChange && onChange(value);
  }, []);

  return (
    <Box>
      {label ? <StyledFormLabel>{label}</StyledFormLabel> : null}
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Wrap error={error}>
            <Container
              // inlineSearchInput
              showPartiallySelected
              keepOpenOnSelect
              keepChildrenOnSearch
              keepTreeOnSearch
              id={id}
              data={options}
              onChange={(currentNode, selectedNodes) => {
                field.onChange();
                onHandleChange(currentNode, selectedNodes);
              }}
              mode={mode}
              className="dropdown-select"
              {...props}
            />
            {showResetButton ? (
              <ClearFilter isVisible={!!field.value} onClick={handleReset} />
            ) : null}
          </Wrap>
        )}
      />
    </Box>
  );
};

const treeType = shape({
  label: string.isRequired,
  value: string.isRequired,
  parent: string,
  children: arrayOf(lazyFunction(() => treeType)),
});

TreeSelect.defaultProps = {
  mode: "multiSelect",
  onChange: undefined,
  label: "",
  error: "",
};

TreeSelect.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  options: arrayOf(treeType).isRequired,
  mode: oneOf(["multiSelect", "hierarchical", "simpleSelect", "radioSelect"]),
  label: string,
  onChange: func,
  error: string,
  showResetButton: bool,
};

export default TreeSelect;
