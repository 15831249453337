import { useCallback, useEffect, useMemo, useState } from "react";
import { string, number } from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { DataTable, DropdownSimpleFilter, Loader } from "@atoms";
import { ReactComponent as ExportIcon } from "@assets/svgs/Export.svg";
import {
  getEventAttendings,
  getEventAttendingsCSV,
  getEventAvailableDates,
  getEventTotalAttendings,
} from "@requests";
import { palette } from "@styles/palette";
import { downloadFile } from "@helpers";
import {
  IconWrap,
  ItemTitle,
  StyledLink,
  Superscription,
  SuperscriptionItem,
  TotalItemsWrap,
} from "../../Reports/styles";
import { DataTableWrap, DropdownWrap, Wrap } from "./styles";
import { getBaseColumns, slottedEventColumns } from "./columns";
import { getAvailableDates } from "./utils";

const EventReport = ({ id, dateId }) => {
  const [currentDateId, setCurrentDateId] = useState(dateId);
  const [page, setPage] = useState(1);

  const { data: availableDatesData } = useQuery({
    queryKey: ["availableDates", id],
    queryFn: () => getEventAvailableDates(id),
  });

  const { isLoading, data: eventAttendingsData } = useQuery({
    queryKey: ["eventAttendings", currentDateId, page],
    queryFn: () =>
      currentDateId ? getEventAttendings(currentDateId, page) : null,
  });

  const { isLoading: isTotalLoading, data: eventAttendingsTotalData } =
    useQuery({
      queryKey: ["eventAttendingsTotal", currentDateId],
      queryFn: () =>
        currentDateId ? getEventTotalAttendings(currentDateId) : null,
    });

  const { data: reportsCSVData } = useQuery({
    queryKey: ["reportsCSV", currentDateId],
    queryFn: () =>
      currentDateId ? getEventAttendingsCSV(currentDateId) : null,
  });

  const dateOptions = useMemo(
    () => getAvailableDates(availableDatesData?.data || []),
    [availableDatesData]
  );

  const handlePageChange = useCallback((value) => {
    setPage(value || value === 0 ? value + 1 : null);
  }, []);

  const handleCSV = useCallback(
    (event) => {
      event.preventDefault();
      if (reportsCSVData) {
        downloadFile(reportsCSVData);
      }
    },
    [reportsCSVData]
  );

  const withSlots = eventAttendingsData?.data.every(({ hasSlots }) => hasSlots);

  const extendedColumns = [
    ...getBaseColumns(withSlots),
    ...slottedEventColumns,
  ];

  useEffect(() => {
    if (dateOptions.length === 1) {
      setCurrentDateId(dateOptions[0].id);
    }
  }, [dateOptions]);

  const superscription = (
    <Superscription>
      <TotalItemsWrap>
        <SuperscriptionItem>
          <ItemTitle>Attending&nbsp;</ItemTitle>
          <span>{eventAttendingsData?.pagination?.total}</span>
        </SuperscriptionItem>
        {withSlots && eventAttendingsTotalData?.data?.length
          ? eventAttendingsTotalData.data.map(({ amount, currency }) => (
              <SuperscriptionItem key={currency}>
                <ItemTitle>Total paid&nbsp;</ItemTitle>
                <span>{`${amount} ${currency}`}</span>
              </SuperscriptionItem>
            ))
          : null}
      </TotalItemsWrap>
      <StyledLink href="/" target="_blank" onClick={handleCSV}>
        <IconWrap>
          <ExportIcon />
        </IconWrap>
        Export
      </StyledLink>
    </Superscription>
  );

  return (
    <Wrap>
      {dateOptions.length > 1 ? (
        <DropdownWrap>
          <DropdownSimpleFilter
            id="datesFilter"
            label="Date"
            options={dateOptions}
            onChange={setCurrentDateId}
            defaultValue={dateId}
          />
        </DropdownWrap>
      ) : null}
      <DataTableWrap>
        {isLoading || isTotalLoading ? (
          <Loader
            position="absolute"
            background={palette.white}
            width={64}
            height={64}
          />
        ) : eventAttendingsData?.data ? (
          <DataTable
            rows={eventAttendingsData.data}
            columns={withSlots ? extendedColumns : getBaseColumns(withSlots)}
            superscription={superscription}
            withDefaultRowHeight={false}
            withDefaultHeaderHeight={false}
            paginationMode="server"
            page={page - 1}
            rowCount={eventAttendingsData?.pagination?.total}
            onPageChange={handlePageChange}
          />
        ) : null}
      </DataTableWrap>
    </Wrap>
  );
};

EventReport.defaultProps = {
  dateId: null,
};

EventReport.propTypes = {
  id: number.isRequired,
  dateId: string,
};

export default EventReport;
