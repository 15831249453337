export const ROUTES = {
  Login: "/login",
  LoginFailure: "/login/failure",
  VerificationCode: "/verification-code",
  PushNotifications: "/push-notifications",
  Locations: "/locations",
  Location: "/locations/:locationId",
  Benefits: "/benefits",
  Benefit: "/benefits/:benefitId",
  AddBenefit: "/benefits/add-benefit",
  Rooms: "/rooms",
  CombineRooms: "/rooms/combine-rooms",
  Room: "/rooms/:roomId",
  Events: "/events",
  Event: "/events/:eventId",
  AddEvent: "/events/add-event",
  Reports: "/reports",
  Announcements: "/announcements",
  Announcement: "/announcements/:announcementId",
  AddAnnouncement: "/announcements/add-announcement",
  Home: "/",
};
