export const transformPlacesDataToOptions = (data, defaultOptions = []) =>
  data.reduce((accumulator, { city, country }) => {
    const countryMatchIndex = accumulator.findIndex(
      ({ value }) => value === country
    );

    const cityObject = {
      label: city,
      value: city,
      parent: country,
    };

    if (countryMatchIndex !== -1) {
      accumulator[countryMatchIndex].children.push({
        ...cityObject,
        checked: defaultOptions.some(
          ({ city: cityDefault }) => city === cityDefault
        ),
      });
    } else {
      accumulator.push({
        label: country,
        value: country,
        children: [
          {
            ...cityObject,
            checked: defaultOptions.some(
              ({ city: cityDefault }) => city === cityDefault
            ),
          },
        ],
        checked: defaultOptions.some(
          ({ country: countryDefault }) => country === countryDefault
        ),
      });
    }

    return accumulator;
  }, []);

export const transformPlacesToOptions = (data, defaultValues, isWorldWide) => {
  const countriesTree = data.reduce((accumulator, { city, country }) => {
    const countryMatchIndex = accumulator.findIndex(
      ({ value }) => value === country
    );

    const placeMatch = defaultValues?.find((defaultItem) => {
      if (!defaultItem?.parent) {
        return defaultItem?.value === country;
      }
      return defaultItem?.value === city;
    });

    const cityObject = {
      label: city,
      value: city,
      parent: country,
    };

    if (countryMatchIndex !== -1) {
      accumulator[countryMatchIndex].children.push({
        ...cityObject,
        checked: city === placeMatch?.value,
      });
    } else {
      accumulator.push({
        label: country,
        value: country,
        children: [
          {
            ...cityObject,
            checked: city === placeMatch?.value,
          },
        ],
        checked: country === placeMatch?.value,
      });
    }

    return accumulator;
  }, []);

  return [
    {
      label: "All",
      value: "all",
      children: countriesTree,
      checked: isWorldWide,
    },
  ];
};

/**
 * @param {Array<BenefitCategoryData>} data
 * @param {Array<any>} defaultPlaces
 */
export const transformBenefitsDataToOptions = (data, defaultPlaces = []) => {
  return data.reduce((accumulator, { id, name }) => {
    accumulator.push({
      label: name,
      value: String(id),
      checked: defaultPlaces.includes(id),
    });
    return accumulator;
  }, []);
};

export const getDestinationsOptionsByLocationId = (
  locations,
  locationIds = []
) => {
  let world = {
    locationId: -1,
    label: "All",
    value: "world",
    checked: !locationIds.length,
  };

  world.children = locations?.reduce((acc, curr) => {
    const { id, title, country, city } = curr;

    let countryIdx = acc.findIndex((item) => item.value === country);

    if (countryIdx === -1) {
      acc.push({
        locationId: id,
        label: country || "",
        value: country,
        children: [],
        checked: false, // id === locationId,
      });

      countryIdx = acc.length - 1;
    }

    let cityIdx = acc[countryIdx].children.findIndex(
      (item) => item.value === city
    );

    if (cityIdx === -1) {
      acc[countryIdx].children.push({
        locationId: id,
        label: city || "",
        value: city,
        children: [],
        checked: false, // id === locationId,
      });

      cityIdx = acc[countryIdx].children.length - 1;
    }

    acc[countryIdx].children[cityIdx].children.push({
      locationId: id,
      label: title || "",
      value: id,
      checked: locationIds.includes(id),
    });

    return acc;
  }, []);

  return world;
};
