export const durationOptions = [
  { id: 1, name: "15 minutes", value: 15 },
  { id: 2, name: "30 minutes", value: 30 },
  { id: 3, name: "45 minutes", value: 45 },
  { id: 4, name: "1 hour", value: 60 },
  { id: 5, name: "1 hour and 15 minutes", value: 75 },
  { id: 6, name: "1 hour and 30 minutes", value: 90 },
  { id: 7, name: "1 hour and 45 minutes", value: 105 },
  { id: 8, name: "2 hours", value: 120 },
];

export const currencyOptions = [
  { id: 1, name: "€ EUR", value: "EUR" },
  { id: 2, name: "$ USD", value: "USD" },
  { id: 3, name: "₪ ILS", value: "ILS" },
  { id: 4, name: "L RON", value: "RON" },
  { id: 5, name: "zł PLN", value: "PLN" },
  { id: 6, name: "£ GBP", value: "GBP" },
];

export const frequencyOptions = [
  {
    id: 1,
    name: "Weekly",
    value: "weekly",
  },
  {
    id: 2,
    name: "Bi-weekly",
    value: "biweekly",
  },
];

export const emptyService = {
  name: "",
  duration: 15,
  price: 0,
  currency: "EUR",
  action: "create",
};

export const eventTypeOptions = [
  {
    value: "single",
    label: "Single event",
  },
  {
    value: "recurring",
    label: "Recurring event",
  },
];
