import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useFormContext } from "react-hook-form";
import { string, bool, shape, oneOfType } from "prop-types";
import { ReactComponent as UploadImage } from "@assets/svgs/UploadImage.svg";
import { ReactComponent as Refresh } from "@assets/svgs/Refresh.svg";
import { ReactComponent as TrashBin } from "@assets/svgs/TrashBin.svg";
import { ALLOWED_FILE_TYPES, FILE_UPLOAD_ERROR } from "@constants";
import { displayToastError } from "@helpers";
import { Typography } from "@mui/material";
import { InfoTooltip } from "@atoms";
import {
  ControlsWrap,
  IconWrap,
  ImageWrap,
  Inner,
  LoadedFileWrap,
  StyledButton,
  TagBlock,
  Text,
  TitleWrapper,
  Wrap,
  UploadWrap,
  InfoText,
} from "./styles";
import { getFileErrors } from "./utils";

const ImageUpload = ({
  imageURL,
  fieldName,
  isCover,
  errorText,
  title,
  tooltipText,
  infoText,
  customHeight,
  disabled,
}) => {
  const { register, setValue } = useFormContext();
  const [loadedImageURL, setLoadedImageURL] = useState(null);

  // This useEffect hooks were added because we need to send images to backend as form data.
  // Base64 format wasn't accepted
  useEffect(() => {
    if (imageURL) {
      const image =
        typeof imageURL === "object"
          ? window.URL.createObjectURL(imageURL)
          : imageURL;
      setLoadedImageURL(image);
    }
  }, [imageURL]);

  useEffect(
    () => () => {
      if (loadedImageURL) {
        window.URL.revokeObjectURL(loadedImageURL);
      }
    },
    [loadedImageURL]
  );

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          const binaryUrl = e.target.result;
          const errors = getFileErrors(file);

          if (errors.length > 0) {
            errors.forEach((error) =>
              displayToastError(FILE_UPLOAD_ERROR, error)
            );
          } else {
            setLoadedImageURL(binaryUrl);
            // TODO: check API endpoint and review this function call
            setValue(fieldName, file);
          }
        }
      };
      reader.readAsDataURL(file);
    } else {
      displayToastError(FILE_UPLOAD_ERROR, ALLOWED_FILE_TYPES);
    }
  };

  const deleteFile = () => {
    setLoadedImageURL(null);
    setValue(fieldName, null, {
      shouldDirty: true,
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    noClick: true,
    maxFiles: 1,
    onDrop,
  });

  return (
    <Wrap
      sx={{ height: customHeight }}
      {...getRootProps({ className: "dropzone" })}
    >
      {title && (
        <TitleWrapper>
          <Typography>{title}</Typography>
          {tooltipText && <InfoTooltip title={tooltipText} />}
        </TitleWrapper>
      )}
      {loadedImageURL ? (
        <LoadedFileWrap>
          {isCover ? <TagBlock>Cover</TagBlock> : null}
          {disabled ? null : (
            <ControlsWrap>
              <StyledButton onClick={open}>
                <Refresh />
              </StyledButton>
              <StyledButton onClick={deleteFile}>
                <TrashBin />
              </StyledButton>
            </ControlsWrap>
          )}
          <ImageWrap>
            <img src={loadedImageURL} alt="preview" />
          </ImageWrap>
        </LoadedFileWrap>
      ) : (
        <UploadWrap>
          {isCover ? <TagBlock>Cover</TagBlock> : null}
          <Inner onClick={open} error={errorText}>
            <IconWrap>
              <UploadImage />
            </IconWrap>
            <Text>Upload image</Text>
            {infoText && (
              <InfoText sx={{ textAlign: "center" }}>{infoText}</InfoText>
            )}
          </Inner>
        </UploadWrap>
      )}
      <input {...getInputProps()} />
      <input type="hidden" {...register(fieldName)} />
    </Wrap>
  );
};

ImageUpload.propTypes = {
  fieldName: string.isRequired,
  imageURL: oneOfType([string, shape({})]),
  isCover: bool,
  errorText: string,
  title: string,
  tooltipText: string,
  customHeight: string,
  disabled: bool,
  infoText: string,
};

ImageUpload.defaultProps = {
  imageURL: "",
  isCover: false,
  errorText: "",
  title: "",
  tooltipText: "",
  customHeight: "150px",
  disabled: false,
  infoText: "",
};

export default ImageUpload;
