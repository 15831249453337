import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Box, Button, Link } from "@mui/material";
import { editLocation, editLocationJSON, getLocation } from "@requests";
import { displayToastSuccess, serverErrorHandler } from "@helpers";
import { ROUTES } from "@constants";
import { Loader, StatusLabel } from "@atoms";
import { TabsContainer } from "@organisms";
import { LeftSidabarSection } from "@molecules";
import { LeaveModalContainer } from "@templates";
import { palette } from "@styles/palette";
import { ReactComponent as LinkIcon } from "@assets/svgs/Link.svg";

import { nonPrintingDetailsSchema, printingDetailsSchema } from "./schema";
import { createLocationFormValues, createRequestBody, tabs } from "./utils";
import { Wrap } from "./styles";
import { useErrorsToast } from "@hooks";

const Location = () => {
  const { locationId } = useParams();
  const [activeTab, setActiveTab] = useState(0);

  const { isLoading, data } = useQuery({
    queryKey: ["location", locationId],
    queryFn: getLocation,
    cacheTime: 0,
  });

  const { mutate: updateLocation, isLoading: isUpdateLocationLoading } =
    useMutation((formData) => editLocation(locationId, formData), {
      onSuccess: () => {
        displayToastSuccess("Success", "Location is updated");
        reset({}, { keepValues: true });
      },
      onError: serverErrorHandler,
    });

  const { mutate: deleteLocationLogo } = useMutation(
    () => editLocationJSON(locationId, { logo: null }),
    {
      onSuccess: () => {
        displayToastSuccess("Success", "Building logo is deleted");
      },
      onError: serverErrorHandler,
    }
  );

  const defaultValues = useMemo(() => createLocationFormValues(data), [data]);

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(
      data?.data?.isWhiteLabeled
        ? nonPrintingDetailsSchema
        : printingDetailsSchema
    ),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  useErrorsToast(errors, "Locations");

  const onSubmit = (formData) => {
    const requestBody = createRequestBody(formData);
    if (formData.splashImage === null) {
      deleteLocationLogo();
    }
    updateLocation(requestBody);
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // TODO: create a HOC component and handle loader logic there
  if (isLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer when={isDirty && !isUpdateLocationLoading}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Wrap>
            <LeftSidabarSection
              title={data?.data?.title}
              prevPageButtonContent="Back to Locations"
              prevPageRoute={ROUTES.Locations}
            >
              <StatusLabel
                value={data?.data?.status}
                sx={{ mt: "25px", mb: "27px" }}
              />
              <Box mt={8} mb={8}>
                <Link
                  variant="caption"
                  target="_blank"
                  href={data?.data?.salesforceUrl}
                >
                  <Box mr={2} display="inline-flex">
                    <LinkIcon />
                  </Box>
                  Salesforce Link
                </Link>
              </Box>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </LeftSidabarSection>
            <Box sx={{ p: "50px 0 100px", width: "100%" }}>
              <TabsContainer
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                sx={{ p: "0 50px" }}
              />
            </Box>
          </Wrap>
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default Location;
