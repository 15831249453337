import { string, arrayOf, shape, func } from "prop-types";
import { Controller, useFormContext } from "react-hook-form";
import { RadioButtonWithLabel } from "@atoms";
import { StyledRadioGroup } from "./styles";

const RadioButtonsGroup = ({ options, name, onChange }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <StyledRadioGroup
          {...field}
          aria-label={name}
          onChange={(event) => {
            field.onChange(event);
            if (onChange) {
              onChange();
            }
          }}
        >
          {options.map(({ value: optionValue, label }) => (
            <RadioButtonWithLabel
              key={label}
              label={label}
              value={optionValue}
            />
          ))}
        </StyledRadioGroup>
      )}
    />
  );
};

RadioButtonsGroup.defaultProps = {
  onChange: undefined,
};

RadioButtonsGroup.propTypes = {
  options: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    })
  ).isRequired,
  name: string.isRequired,
  onChange: func,
};

export default RadioButtonsGroup;
