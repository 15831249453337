import { useCallback, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { func, string } from "prop-types";
import { NestedSingleSelect } from "@molecules";
import { transformCreatedByStructure } from "@views/Event/utils";
import { useManagersDictionary } from "@hooks";
import { ClearFilter } from "@atoms";
import { Wrap } from "./styles";

// TODO: Use GenericSearchFilter
const CreatedByFilter = ({ name, label, onChange }) => {
  const { control, setValue } = useFormContext();
  const { data: managersData } = useManagersDictionary();
  const [searchedManagers, setSearchedManagers] = useState(managersData?.data);

  const handleSearchManagers = useCallback(
    (searchValue) => {
      setSearchedManagers(
        managersData?.data.filter(({ firstName, lastName }) =>
          `${firstName} ${lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
    },
    [managersData?.data]
  );

  const managersOptions = useMemo(
    () => transformCreatedByStructure(searchedManagers),
    [searchedManagers]
  );

  const handleChange = useCallback((manager) => {
    setValue(name, Number(manager.id));
    onChange && onChange(manager);
  }, []);

  const handleReset = useCallback(() => {
    setValue(name, "");
    onChange && onChange(undefined);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Wrap>
          <NestedSingleSelect
            id="createdBySingleSelect"
            customWidth="180px"
            option={managersOptions.find(
              ({ id }) => id === String(field.value)
            )}
            options={managersOptions}
            onSearchChange={handleSearchManagers}
            {...field}
            onChange={handleChange}
            textFieldProps={{
              label,
            }}
            inputLabelProps={{
              shrink: true,
            }}
          />
          <ClearFilter isVisible={!!field.value} onClick={handleReset} />
        </Wrap>
      )}
    />
  );
};

CreatedByFilter.propTypes = {
  name: string,
  label: string,
  onChange: func,
};

export default CreatedByFilter;
