export const printingDetails = [
  {
    id: 1,
    title: "Printing instructions for PC",
    name: "printerInstructionsPC",
  },
  {
    id: 2,
    title: "Printing instructions for Mac",
    name: "printerInstructionsMAC",
  },
  {
    id: 3,
    title: "Secure printing",
    name: "securePrinting",
    optional: true,
  },
  {
    id: 4,
    title: "Scanning instructions",
    name: "scanningInstructions",
  },
];
