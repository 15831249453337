import { Button, Typography, Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useCallback } from "react";
import { bool, exact, string, number, arrayOf } from "prop-types";
import { Toggle, ControlledCheckbox, InfoTooltip, Flex } from "@atoms";
import { DatePickerField } from "@molecules";
import { Separator } from "./styles";

const AnnouncementSidebar = ({ isSubmitButtonDisabled }) => {
  const {
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const onChange = useCallback((checked) => {
    setValue("type", checked ? "heads_up" : "announcement");
  }, []);

  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const type = watch("type");

  return (
    <>
      <Box
        sx={{
          mb: "15px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <ControlledCheckbox
          defaultValue={type === "heads_up"}
          name="isHeadsUp"
          label="Heads Up"
          onChange={onChange}
        />
        <InfoTooltip title="Heads Up announcement" />
      </Box>
      <Flex flexDirection="column">
        <Typography sx={{ fontWeight: "600", mb: "10px" }}>
          Start date
        </Typography>
        <DatePickerField
          name="startDate"
          selectsStart
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          minDate={new Date()}
          maxDate={new Date(endDate)}
          errorText={errors?.startDate?.message}
        />
      </Flex>
      <Flex flexDirection="column">
        <Typography sx={{ fontWeight: "600", mt: "20px", mb: "10px" }}>
          End date
        </Typography>
        <DatePickerField
          name="endDate"
          selectsEnd
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          minDate={new Date(startDate)}
          errorText={errors?.endDate?.message}
          disabled={!startDate}
        />
      </Flex>
      <Separator />
      <Toggle
        isBig
        name="status"
        id="statusSwitch"
        label="Active"
        sx={{ mr: 13.5, mb: 4 }}
      />
      <Typography sx={(theme) => ({ mb: 8.5, color: theme.palette.dark })}>
        Announcements marked as Active will be published when saved.
      </Typography>

      <Button
        type="submit"
        variant="contained"
        disabled={isSubmitButtonDisabled}
      >
        Save
      </Button>
    </>
  );
};

AnnouncementSidebar.defaultProps = {
  isSubmitButtonDisabled: false,
  announcement: null,
};

AnnouncementSidebar.propTypes = {
  isSubmitButtonDisabled: bool,
  announcement: exact({
    data: exact({
      id: number,
      title: string,
      content: string,
      endDate: string,
      startDate: string,
      status: string,
      type: string,
      isWorldWide: bool,
      locationIds: arrayOf(number),
    }),
  }),
};

export default AnnouncementSidebar;
