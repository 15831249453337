import { styled } from "@mui/material/styles";
import { Drawer, Box, List } from "@mui/material";
import { SIDEBAR_WIDTH, HEADER_HEIGHT } from "@constants";

export const NavList = styled("ul")(() => ({
  listStyle: "none",
}));

export const StyledDrawer = styled(Drawer)(({ theme }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: SIDEBAR_WIDTH,
    boxSizing: "border-box",
    backgroundColor: theme.palette.dark,
    borderRight: "none",
    zIndex: theme.zIndex.drawer + 2,
  },
}));

export const StyledList = styled(List)(({ theme }) => ({
  padding: theme.spacing(0, 2, 0, 1.5),
  marginTop: theme.spacing(7.5),
}));

export const LogoWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  width: `${SIDEBAR_WIDTH}px`,
  padding: "16px 24px",
  backgroundColor: "#fff",
  height: HEADER_HEIGHT,
  position: "relative",
  "& > svg": {
    height: "100%",
  },
  "&:after": {
    content: "''",
    display: "inline-block",
    position: "absolute",
    top: "15px",
    right: 0,
    width: "1px",
    height: "20px",
    backgroundColor: theme.palette.gray[500],
  },
}));
