import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Controller, useFormContext } from "react-hook-form";
import { Box, ClickAwayListener, TextField } from "@mui/material";
import { func, string } from "prop-types";

import { Menu, MenuItem } from "./styles";

const PlacesAutocomplete = ({
  name,
  id,
  placeholder,
  error,
  onSelectCoordinates,
  ...props
}) => {
  const { control, setValue: setFormValue, clearErrors } = useFormContext();
  const {
    ready,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    debounce: 300,
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setFormValue(name, e.target.value);
    clearErrors(name);
    onSelectCoordinates && onSelectCoordinates(0, 0);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      setFormValue(name, description);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        onSelectCoordinates && onSelectCoordinates(lat, lng);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <MenuItem key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </MenuItem>
      );
    });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ClickAwayListener onClickAway={clearSuggestions}>
          <Box>
            <TextField
              {...field}
              {...props}
              id={id}
              onChange={handleInput}
              disabled={!ready}
              placeholder={placeholder}
              error={!!error}
            />
            {status === "OK" && <Menu>{renderSuggestions()}</Menu>}
          </Box>
        </ClickAwayListener>
      )}
    />
  );
};

PlacesAutocomplete.defaultProps = {
  label: "",
  error: "",
  onSelectCoordinates: () => {},
};

PlacesAutocomplete.propTypes = {
  name: string.isRequired,
  placeholder: string.isRequired,
  id: string.isRequired,
  error: string,
  onSelectCoordinates: func,
};

export default PlacesAutocomplete;
