import { useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Typography } from "@mui/material";
import { shape } from "prop-types";

import { StatusLabel } from "@atoms";
import { LeftSidabarSection } from "@molecules";
import { ROUTES } from "@constants";

import { CategoriesWrap, ContentWrapper, Wrap } from "./styles";
import { TabsContainer } from "@organisms";
import { EventReport, PastEventDetails } from "@features";

const PastEvent = ({ details }) => {
  const { id: eventId, name, eventCategories } = details;

  const [searchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") === "attending" ? 1 : 0
  );

  const tabs = useMemo(
    () => [
      {
        id: 1,
        label: "Event details",
        component: <PastEventDetails details={details} />,
      },
      {
        id: 2,
        label: "Attending",
        component: (
          <EventReport id={eventId} dateId={searchParams.get("dateId")} />
        ),
      },
    ],
    [eventId]
  );

  return (
    <Wrap>
      <LeftSidabarSection
        title={name}
        prevPageButtonContent="Back to Events"
        prevPageRoute={ROUTES.Events}
      >
        <StatusLabel
          value="past"
          sx={{
            mt: "25px",
            mb: "27px",
            "& > .MuiTypography-root": { fontSize: 16 },
          }}
        />
        <Typography sx={{ mb: "7px", fontWeight: "600" }}>Category</Typography>
        {eventCategories.length ? (
          <CategoriesWrap>
            {eventCategories.map(({ id, name: categoryName }) => (
              <Typography key={id} sx={{ mb: 2 }}>
                {categoryName}
              </Typography>
            ))}
          </CategoriesWrap>
        ) : null}
      </LeftSidabarSection>
      <ContentWrapper>
        <TabsContainer
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          sx={{ p: "0 50px" }}
        />
      </ContentWrapper>
    </Wrap>
  );
};

PastEvent.defaultProps = {
  details: {},
};

PastEvent.propTypes = {
  details: shape({}),
};

export default PastEvent;
