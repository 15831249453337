import { FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledFormLabel = styled(FormLabel)(
  ({ theme, fontWeight, customColor, marginBottom }) => ({
    display: "flex",
    fontWeight: fontWeight || 600,
    color: customColor || theme.palette.black,
    marginBottom: theme.spacing(marginBottom || 2),
  })
);
