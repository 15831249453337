import { useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { string } from "prop-types";
import { formatNumber } from "libphonenumber-js";

import { Auth } from "@templates";

import Gif from "@assets/gifs/failure.gif";

import { ROUTES } from "@constants";
import { Flex, Button } from "@mixins";
import { palette } from "@styles/palette";

const Oops = ({ codeAddress }) => {
  const text = useMemo(
    () =>
      `Looks like we can’t find\n${
        formatNumber(codeAddress, "INTERNATIONAL") || codeAddress
      }`,
    [codeAddress]
  );

  const navigate = useNavigate();

  const handleTryAgain = () => {
    navigate(ROUTES.Login);
  };

  return (
    <Auth useLogo text={text} isLoading={false}>
      <Flex
        mb={25}
        as="img"
        width={{ xs: 140, sm: 188 }}
        height={{ xs: 140, sm: 188 }}
        src={Gif}
      />
      <Button
        onClick={handleTryAgain}
        fontSize="14px"
        color={palette.darkGreen}
        backgroundColor="transparent"
      >
        Try again
      </Button>
    </Auth>
  );
};

Oops.propTypes = {
  codeAddress: string.isRequired,
};

export default Oops;
