import styled, { css } from "styled-components";
import {
  color,
  flexbox,
  layout,
  space,
  typography,
  variant,
  border,
  shadow,
} from "styled-system";
import { palette } from "@styles/palette";

export const Typography = styled.p`
  ${({
    textTransform = "none",
    pointer,
    textDecoration = "none",
    whiteSpace = "normal",
  }) => css`
    text-transform: ${textTransform};
    text-decoration: ${textDecoration};
    white-space: ${whiteSpace};
    color: ${palette.black};
    ${pointer && "cursor: pointer;"}
    fontWeight: 400,

    ${variant({
      variants: {
        "proximaNova-400": {
          fontWeight: 400,
        },
        "proximaNova-600": {
          fontWeight: 600,
        },
        "garamond-500": {
          fontFamily: "EB Garamond",
          fontWeight: 500,
        },
      },
    })}
    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${space};
    ${typography};
    ${shadow};
  `}
`;
