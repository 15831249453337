import { Fragment, useCallback } from "react";
import { object } from "prop-types";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import CodeIcon from "@mui/icons-material/Code";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import MenuItem from "./MenuItem";

const boldIcon = <FormatBoldIcon fontSize="small" />;
const italicIcon = <FormatItalicIcon fontSize="small" />;
const strikeThroughSIcon = <StrikethroughSIcon fontSize="small" />;
const codeIcon = <CodeIcon fontSize="small" />;
const insertLinkIcon = <InsertLinkIcon fontSize="small" />;
const bulletedListIcon = <FormatListBulletedIcon fontSize="small" />;
const numberedListIcon = <FormatListNumberedIcon fontSize="small" />;
const leftAlignIcon = <FormatAlignLeftIcon fontSize="small" />;
const centerAlignIcon = <FormatAlignCenterIcon fontSize="small" />;
const rightAlignIcon = <FormatAlignRightIcon fontSize="small" />;
const justifyAlignIcon = <FormatAlignJustifyIcon fontSize="small" />;

const MenuBar = ({ editor }) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  const items = [
    {
      icon: boldIcon,
      title: "Bold",
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive("bold"),
    },
    {
      icon: italicIcon,
      title: "Italic",
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive("italic"),
    },
    {
      icon: strikeThroughSIcon,
      title: "Strike",
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive("strike"),
    },
    {
      icon: codeIcon,
      title: "Code",
      action: () => editor.chain().focus().toggleCode().run(),
      isActive: () => editor.isActive("code"),
    },
    {
      icon: insertLinkIcon,
      title: "Link",
      action: () => setLink(),
      isActive: () => editor.isActive("link"),
    },
    {
      icon: bulletedListIcon,
      title: "Bullet List",
      action: () => editor.chain().focus().toggleBulletList().run(),
      isActive: () => editor.isActive("bulletList"),
    },
    {
      icon: numberedListIcon,
      title: "Ordered List",
      action: () => editor.chain().focus().toggleOrderedList().run(),
      isActive: () => editor.isActive("orderedList"),
    },
    {
      icon: leftAlignIcon,
      title: "Left Align",
      action: () => editor.chain().focus().setTextAlign("left").run(),
      isActive: () => editor.isActive({ textAlign: "left" }),
    },
    {
      icon: centerAlignIcon,
      title: "Center Align",
      action: () => editor.chain().focus().setTextAlign("center").run(),
      isActive: () => editor.isActive({ textAlign: "center" }),
    },
    {
      icon: rightAlignIcon,
      title: "Right Align",
      action: () => editor.chain().focus().setTextAlign("right").run(),
      isActive: () => editor.isActive({ textAlign: "right" }),
    },
    {
      icon: justifyAlignIcon,
      title: "Justify Align",
      action: () => editor.chain().focus().setTextAlign("justify").run(),
      isActive: () => editor.isActive({ textAlign: "justify" }),
    },
  ];

  return (
    <div className="editor__header">
      {items.map((item, index) => (
        <Fragment key={index}>
          <MenuItem {...item} />
        </Fragment>
      ))}
    </div>
  );
};

MenuBar.propTypes = {
  // TODO: updated this prop type
  // eslint-disable-next-line react/forbid-prop-types
  editor: object.isRequired,
};

export default MenuBar;
