import { MenuItem, TextField } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { string, func, shape, arrayOf, number, bool } from "prop-types";
import { Wrap } from "./styles";
import { Label } from "@atoms";

const Dropdown = ({
  id,
  name,
  options,
  label,
  onChange,
  isLabelBold,
  showLabel,
  ...props
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Wrap>
          {showLabel && label ? (
            <Label sx={{ fontWeight: isLabelBold ? 600 : 400 }}>{label}</Label>
          ) : null}
          <TextField
            {...field}
            select
            id={id}
            onChange={({ target: { value } }) => {
              field.onChange(value);
              if (onChange) {
                onChange(value);
              }
            }}
            {...props}
          >
            {options.map(
              ({ id: optionId, name: optionName, value: optionValue }) => (
                <MenuItem key={optionId} value={optionValue}>
                  {optionName}
                </MenuItem>
              )
            )}
          </TextField>
        </Wrap>
      )}
    />
  );
};

Dropdown.defaultProps = {
  onChange: undefined,
  label: "",
  isLabelBold: false,
  showLabel: true,
};

Dropdown.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  options: arrayOf(
    shape({
      id: number.isRequired,
      name: string.isRequired,
    })
  ).isRequired,
  label: string,
  onChange: func,
  isLabelBold: bool,
  showLabel: bool,
};

export default Dropdown;
