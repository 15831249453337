import { API_ENDPOINTS } from "@constants";
import { get } from "./get";
import { pageSize } from "./config";

/**
 * @param {ReportsParamsWithPages} params
 * @returns {Promise<ReportsListResponse>}
 */
export const getRoomsList = ({ queryKey: [, params] }) =>
  get(API_ENDPOINTS.ReportsList, { params: { ...params, pageSize } });

export const getEventsList = ({ queryKey: [, params] }) =>
  get(API_ENDPOINTS.ReportsEvent, { params: { ...params, pageSize } });

export const getCreditsList = ({ queryKey: [, params] }) =>
  get(API_ENDPOINTS.ReportsCredits, { params: { ...params, pageSize } });

/**
 * @param {ReportsParams} params
 * @returns {Promise<ReportsTotalResponse>}
 */
export const getRoomsTotal = ({ queryKey: [, params] }) =>
  get(API_ENDPOINTS.ReportsTotal, { params });

export const getEventsTotal = ({ queryKey: [, params] }) =>
  get(API_ENDPOINTS.ReportsEventTotal, { params });

/**
 * @param {ReportsParams} params
 * @param {any} signal
 * @returns {Promise<ReportsCSVResponse>}
 */
export const getRoomsCSV = ({ queryKey: [, params] }, signal = null) =>
  get(API_ENDPOINTS.ReportsCSV, { params, signal });

/**
 * @param {ReportsParams} params
 * @param {any} signal
 * @returns {Promise<ReportsCSVResponse>}
 */
export const getEventsCSV = ({ queryKey: [, params] }, signal = null) =>
  get(`${API_ENDPOINTS.ReportsEvent}/csv`, { params, signal });

/**
 * @typedef ReportsParams
 * @property {Array<Number>} [locationIds]
 * @property {String} [fromDateTime]
 * @property {String} [toDateTime]
 * @property {Number} [meetingRoomId]
 * @property {Number} [userCompanyId]
 * @property {String} [paymentType] One of paymentTypes enum
 */

/**
 * @typedef ReportsParamsWithPages
 * @augments ReportsParams
 * @augments PaginationParams
 * @property {Array<Number>} [locationIds]
 * @property {String} [fromDateTime]
 * @property {String} [toDateTime]
 * @property {Number} [meetingRoomId]
 * @property {Number} [userCompanyId]
 * @property {String} [paymentType] One of paymentTypes enum
 */

/**
 * @typedef ReportsListResponse
 * @property {Pagination} pagination
 * @property {Array<ReportsListItem>} data
 */

/**
 * @typedef ReportsListItem
 * @property {Number} bookingId
 * @property {Number} bookingRoomId
 * @property {String} bookingRoomNumber
 * @property {String} fromDateTime
 * @property {String} toDateTime
 * @property {Number} companyId
 * @property {String} companyName
 * @property {Number} bookedById
 * @property {String} bookedByName
 * @property {String} payment
 * @property {String} paymentCurrency
 * @property {String} paymentType One of paymentTypes enum
 */

/**
 * @typedef ReportsTotalResponse
 * @property {ReportsTotalData} data
 */

/**
 * @typedef ReportsTotalData
 * @property {Object} totalCredits
 * @property {Array<TotalMoney>} totalMoney
 */

/**
 * @typedef TotalMoney
 * @property {String} currency
 * @property {Number} amount
 */

/**
 * @typedef ReportsCSVResponse
 * @property {Object} data
 */
