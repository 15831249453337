import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

import { Login as View } from "@views";
import { ROUTES } from "@constants";
import COUNTRIES from "@constants/countries";
import { validateEmail } from "@helpers/common";
import { getAmplifyConfig } from "@utils/amplify";
import { login } from "@store/user/duck";

const Login = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [emailValue, setEmailValue] = useState("");
  const [phoneNumberValue, setPhoneNumberValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("IL");

  useEffect(() => {
    const webClientId = [
      process.env.REACT_APP_AWS_EMAIL_CLIENT_ID,
      process.env.REACT_APP_AWS_PHONE_CLIENT_ID,
    ][activeTab];

    Auth.configure(getAmplifyConfig(webClientId));
  }, [activeTab]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleTabClick = (index) => () => {
    if (index !== activeTab) {
      setActiveTab(index);
      setError("");
      setEmailValue("");
      setPhoneNumberValue("");
      setSelectedCountry("IL");
    }
  };

  const handleFormSubmit = async () => {
    let isValid = true;
    if (activeTab) {
      isValid = isValidPhoneNumber(phoneNumberValue, selectedCountry);
      if (!isValid) {
        setError("*Hmm.. this is not a valid phone number");
      }
    } else {
      isValid = validateEmail(emailValue);
      if (!isValid) {
        setError("*Hmm.. this is not a valid email");
      }
    }

    const apiCreds = activeTab
      ? parsePhoneNumber(
          `${COUNTRIES[selectedCountry]?.dialCode}${phoneNumberValue}`
        ).number
      : emailValue;

    const formCreds = [
      emailValue,
      `${COUNTRIES[selectedCountry]?.dialCode}${phoneNumberValue}`,
    ][activeTab];

    if (isValid) {
      try {
        setIsLoading(true);

        const cognitoUser = await Auth.signIn(apiCreds);

        dispatch(login({ cognitoUser }));

        setIsLoading(false);

        navigate(ROUTES.VerificationCode, {
          state: { source: apiCreds },
          replace: true,
        });
      } catch (err) {
        setIsLoading(false);
        navigate(ROUTES.LoginFailure, {
          state: { source: formCreds },
          replace: true,
        });
      }
    }
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value.replace(/\s/g, ""));
    const isValid = validateEmail(emailValue);
    if (error && isValid) {
      setError("");
    }
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
  };

  const handlePhoneNumberValueChange = (phoneNumber) => {
    setPhoneNumberValue(phoneNumber);
  };

  return (
    <View
      isLoading={isLoading}
      error={error}
      onTabClick={handleTabClick}
      onFormSubmit={handleFormSubmit}
      onEmailChange={handleEmailChange}
      activeTab={activeTab}
      selectedCountry={selectedCountry}
      onCountrySelect={handleCountrySelect}
      onPhoneNumberValueChange={handlePhoneNumberValueChange}
      phoneNumberValue={phoneNumberValue}
      emailValue={emailValue}
    />
  );
};

export default Login;
