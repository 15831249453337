import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { BootstrapTooltip, DataTableEllipsisCell, StatusLabel } from "@atoms";
import { getCategories, getImage, parseStrictISOString } from "@helpers";
import { MultiplyList } from "@molecules";
import { DATE_TEMPLATE } from "@constants";

export const columns = [
  {
    id: 1,
    field: "imageUrl",
    headerName: "Image",
    sortable: false,
    renderCell: ({ value }) => (value ? getImage(value) : <MissingValue />),
    flex: 0,
  },
  {
    id: 2,
    field: "name",
    headerName: "Event Name",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 3,
    field: "eventCategories",
    headerName: "Category",
    sortable: false,
    renderCell: ({ value = [] }) =>
      value.length ? (
        <MultiplyList values={getCategories(value)} />
      ) : (
        <MissingValue />
      ),
    flex: 1,
  },
  {
    id: 4,
    field: "locationName",
    headerName: "Location",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 5,
    field: "startDate",
    headerName: "Start date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? parseStrictISOString(value, DATE_TEMPLATE) : <MissingValue />,
    flex: 1,
  },
  {
    id: 6,
    field: "endDate",
    headerName: "End date",
    width: 140,
    sortable: false,
    renderCell: ({ value }) =>
      value ? parseStrictISOString(value, DATE_TEMPLATE) : <MissingValue />,
    flex: 1,
  },
  {
    id: 7,
    field: "frequency",
    headerName: "Frequency",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 8,
    field: "attending",
    headerName: "Attending",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
  },
  {
    id: 9,
    field: "activityStatus",
    headerName: "Status",
    sortable: false,
    renderCell: ({ value }) =>
      value ? <StatusLabel value={value} /> : <MissingValue />,
  },
];
