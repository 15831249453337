import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  AddButton,
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { ROUTES } from "@constants";
import { getEvents } from "@requests";
import { columns } from "./columns";
import { LocationsFilter } from "@molecules";
import { FiltersContainer } from "@organisms";
import { useEventCategoriesDictionary } from "@hooks";
import { transformCategoriesStructureFull } from "@views/Event/utils";
import { eventStatusesItems } from "@constants/statusesItems";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import { getFiltersForPage, setFiltersForPage } from "@helpers";

const Events = () => {
  const {
    userData: { locationId },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    locationId,
    page: 1,
    ...getFiltersForPage("events"),
  });

  useEffect(() => {
    setFiltersForPage("events", filters);
  }, [filters]);

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["events", filters],
    queryFn: getEvents,
  });

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id) => navigate(`${ROUTES.Events}/${id}`),
    [navigate]
  );

  const onAddEventClick = useCallback(
    () => navigate(ROUTES.AddEvent),
    [navigate]
  );

  const handleLocationChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.locationId;

      if (!value) {
        return newData;
      }

      if (value.id) {
        newData.locationId = value.id;
      }

      return newData;
    });
  }, []);

  const handleSearchEventNameChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.name = value;
      } else {
        delete newData.name;
      }
      return newData;
    });
  }, []);

  const { data: eventCategories, isLoading: isCategoriesLoading } =
    useEventCategoriesDictionary();

  const eventCategoriesOptions = useMemo(
    () => transformCategoriesStructureFull(eventCategories),
    [eventCategories]
  );

  const handleCategoriesChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.categoryId = value;
      } else {
        delete newData.categoryId;
      }
      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.status;
      if (value) {
        newData.status = value;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const rows = useMemo(
    () =>
      data?.data.map((location) => ({
        ...location,
        activityStatus:
          location.status === "published"
            ? location.activityStatus
            : location.status === "draft"
            ? "draft"
            : "",
      })),
    [data?.data]
  );

  const isLoading = isDataLoading || isCategoriesLoading;

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Title>Events</Title>

        <AddButton onClick={onAddEventClick}>Add Event</AddButton>
      </Stack>

      <FiltersContainer defaultValues={filters}>
        <LocationsFilter
          name="locationId"
          label="Location"
          onChange={handleLocationChange}
        />
        <SearchFieldFilter
          id="searchEventNameFilter"
          name="name"
          placeholder="Search Event name..."
          onChange={handleSearchEventNameChange}
        />
        <DropdownSimpleFilter
          id="categoriesFilter"
          name="categoryId"
          label="Category"
          options={eventCategoriesOptions}
          onChange={handleCategoriesChange}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={eventStatusesItems}
          onChange={handleStatusChange}
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={rows}
          columns={columns}
          superscription={
            <Superscription
              name="event"
              count={data?.pagination?.total}
              isLoading={isLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Events;
