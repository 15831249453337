import { node, func, string, bool } from "prop-types";
import { Flex } from "@mixins";
import { palette } from "@styles/palette";
import Input from "../styles";

const TextInput = ({
  onChange,
  value,
  placeholder,
  children,
  hasError,
  hideCursor,
  onBlur,
}) => (
  <Flex
    width="100%"
    alignItems="center"
    justifyContent="start"
    borderBottom={`1px solid ${hasError ? palette.red : palette.gray[200]}`}
  >
    {children}
    <Input
      onBlur={onBlur}
      hideCursor={hideCursor}
      width="100%"
      border="none"
      py={{ xs: "3px", sm: "3px", md: "5px", lg: "7px" }}
      fontSize="16px"
      fontWeight={400}
      color={palette.black}
      onChange={onChange}
      value={value}
      placeholder={placeholder}
    />
  </Flex>
);

TextInput.defaultProps = {
  children: null,
  hasError: false,
  hideCursor: false,
  onBlur: () => {},
};

TextInput.propTypes = {
  onBlur: func,
  onChange: func.isRequired,
  value: string.isRequired,
  placeholder: string.isRequired,
  children: node,
  hasError: bool,
  hideCursor: bool,
};

export default TextInput;
