import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { palette } from "@styles/palette";

const getBgColor = (status) => {
  switch (status) {
    case "active":
    case "upcoming":
    case "sent":
      return palette.lighterGreen;
    case "past":
      return palette.gray[500];
    case "draft":
      return palette.cream;
    case "inactive":
      return palette.lightRed;
    default:
      return palette.gray[500];
  }
};

export const Wrapper = styled(Box)(({ status }) => ({
  width: "max-content",
  padding: "3px 10px",
  backgroundColor: getBgColor(status),
  borderRadius: "4px",
}));
