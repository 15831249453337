import { createRoutine } from "redux-routines";
import { INIT, LOADING, SUCCESS, FAILURE } from "@constants/requestPhase";
import { storage } from "@utils";
import { getCurrentLocation } from "@store/location/duck";
import * as api from "./api";

// < ----- ACTIONS ----- > //
const loginAction = "LOGIN";
const setActivatedAction = "SET_ACTIVATED";
const resetPhasesAction = "RESET_USER_PHASES";
const resetStoreToInitialStateAction = "RESET_STORE";
const getCurrentUserRoutine = createRoutine("GET_CURRENT_USER_INFO");
const editUserRoutine = createRoutine("EDIT_USER");
const getUserByIdRoutine = createRoutine("GET_USER_BY_ID");
const sendFeedbackRoutine = createRoutine("SEND_FEEDBACK");

// < ----- ACTION CREATORS ----- > //
export const login = (data) => ({
  type: loginAction,
  payload: data,
});

export const setActivated = (data) => ({
  type: setActivatedAction,
  payload: data,
});

export const resetPhases = () => ({
  type: resetPhasesAction,
});

export const resetStore = () => ({
  type: resetStoreToInitialStateAction,
});

export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch(getCurrentUserRoutine.request());

    const response = await api.getCurrentUser();

    dispatch(getCurrentUserRoutine.success(response.data));

    storage.setUserId(response.data.data.id);

    return dispatch(getCurrentLocation(response.data.data?.locationId));
  } catch (error) {
    return dispatch(getCurrentUserRoutine.failure(error.response));
  }
};

export const getUserById = (id) => async (dispatch) => {
  try {
    dispatch(getUserByIdRoutine.request());

    const response = await api.getUserById(id);

    return dispatch(getUserByIdRoutine.success(response.data));
  } catch (error) {
    return dispatch(getUserByIdRoutine.failure(error.response));
  }
};

export const sendFeedback = (data) => async (dispatch) => {
  try {
    dispatch(sendFeedbackRoutine.request());

    await api.sendFeedback(data);

    return dispatch(sendFeedbackRoutine.success());
  } catch (error) {
    return dispatch(sendFeedbackRoutine.failure(error.response));
  }
};

export const editUser = (data) => async (dispatch) => {
  try {
    dispatch(editUserRoutine.request());

    const response = await api.editUser(data);

    return dispatch(editUserRoutine.success(response.data));
  } catch (error) {
    return dispatch(editUserRoutine.failure(error.response));
  }
};

// < ----- STATE ----- > //
export const initialState = {
  user: {
    cognitoUser: {},
  },
  userById: {},
  isActivated: false,

  getCurrentUserPhase: INIT,
  getCurrentUserError: null,

  getUserByIdPhase: INIT,
  getUserByIdError: null,

  editUserPhase: INIT,
  editUserError: null,

  sendFeedbackPhase: INIT,
  sendFeedbackError: null,
};

// < ----- STORE REDUCER ----- > //
const store = (state, { type, payload }) => {
  switch (type) {
    // RESET STORE TO INITIAL STATE
    case resetStoreToInitialStateAction:
      return initialState;
    case loginAction:
      return {
        ...state,
        user: {
          ...state.user,
          cognitoUser: payload.cognitoUser,
        },
      };
    case setActivatedAction:
      return {
        ...state,
        isActivated: payload,
      };
    case resetPhasesAction:
      return {
        ...state,
        getCurrentUserPhase: INIT,
        editUserPhase: INIT,
        getUserByIdPhase: INIT,
        sendFeedbackPhase: INIT,
        getCurrentUserError: null,
        editUserError: null,
        getUserByIdError: null,
        sendFeedbackError: null,
      };
    // GET CURRENT USER
    case getCurrentUserRoutine.REQUEST:
      return {
        ...state,
        getCurrentUserPhase: LOADING,
      };
    case getCurrentUserRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
          imageUrl: payload.data.imageUrl
            ? `${payload.data.imageUrl}?t=${Date.now()}`
            : null,
        },
        getCurrentUserPhase: SUCCESS,
      };
    case getCurrentUserRoutine.FAILURE:
      return {
        ...state,
        getCurrentUserPhase: FAILURE,
        getCurrentUserError: payload,
      };
    // GET USER BY ID
    case getUserByIdRoutine.REQUEST:
      return {
        ...state,
        getUserByIdPhase: LOADING,
      };
    case getUserByIdRoutine.SUCCESS:
      return {
        ...state,
        userById: payload.data,
        getUserByIdPhase: SUCCESS,
      };
    case getUserByIdRoutine.FAILURE:
      return {
        ...state,
        getUserByIdPhase: FAILURE,
        getUserByIdError: payload,
      };
    // SEND FEEDBACK
    case sendFeedbackRoutine.REQUEST:
      return {
        ...state,
        sendFeedbackPhase: LOADING,
      };
    case sendFeedbackRoutine.SUCCESS:
      return {
        ...state,
        sendFeedbackPhase: SUCCESS,
      };
    case sendFeedbackRoutine.FAILURE:
      return {
        ...state,
        sendFeedbackPhase: FAILURE,
        sendFeedbackError: payload,
      };
    // EDIT USER
    case editUserRoutine.REQUEST:
      return {
        ...state,
        editUserPhase: LOADING,
      };
    case editUserRoutine.SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data,
          imageUrl: payload.data.imageUrl
            ? `${payload.data.imageUrl}?t=${Date.now()}`
            : null,
        },
        editUserPhase: SUCCESS,
      };
    case editUserRoutine.FAILURE:
      return {
        ...state,
        editUserPhase: FAILURE,
        editUserError: payload,
      };
    default:
      return initialState;
  }
};

export default store;
