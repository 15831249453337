import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(({ theme }) => ({
  position: "relative",
  "& .MuiSelect-select": {
    padding: `${theme.spacing(0, 2)} !important`,
  },
  "& .MuiOutlinedInput-root": {
    height: "32px",
  },
}));
