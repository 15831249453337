import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { zodResolver } from "@hookform/resolvers/zod";

import { Loader, StatusLabel } from "@atoms";
import { CheckboxesGroup, LeftSidabarSection, RemoveButton } from "@molecules";
import { TabsContainer } from "@organisms";
import { LeaveModalContainer } from "@templates";
import { EventDetails, EventReport, PastEvent } from "@features";
import { ROUTES } from "@constants";
import {
  displayToastSuccess,
  getCategoriesFromFormData,
  serverErrorHandler,
  trackInteraction,
} from "@helpers";
import { useErrorsToast, usePlacesWithLocationsDictionary } from "@hooks";
import {
  deleteEvent,
  editEvent,
  getEvent,
  getEventCategories,
  postEvent,
  publishEvent,
  unpublishEvent,
} from "@requests";
import { palette } from "@styles/palette";

import {
  ButtonsWrapper,
  CategoriesContainer,
  ContentWrapper,
  RemoveButtonContainer,
  Wrap,
} from "./styles";
import {
  createEventFormValues,
  createRequestBody,
  defaultEventValues,
  transformCategoriesStructure,
  transformLocationsStructure,
} from "./utils";
import { notTimeSlottedSchema, timeSlottedSchema } from "./schema";

const Event = () => {
  const { eventId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data: locations } = usePlacesWithLocationsDictionary();
  const [activeTab, setActiveTab] = useState(
    searchParams.get("tab") === "attending" ? 1 : 0
  );
  const [searchedLocations, setSearchedLocations] = useState(locations?.data);
  const queryClient = useQueryClient();

  const { mutateAsync: createEvent, isLoading: isCreateEventLoading } =
    useMutation((formData) => postEvent(formData), {
      onSuccess: () => {
        displayToastSuccess("Success", "Event is created");
        navigate(ROUTES.Events);
      },
      onError: serverErrorHandler,
    });

  const { mutateAsync: updateEvent, isLoading: isUpdateEventLoading } =
    useMutation((formData) => editEvent(eventId, formData), {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["event"]);
        displayToastSuccess("Success", "Event is updated");
      },
      onError: serverErrorHandler,
    });

  const { mutate: onDeleteEvent } = useMutation(() => deleteEvent(eventId), {
    onSuccess: () => {
      trackInteraction("'Delete' button clicked", { scope: "Events" });
      displayToastSuccess("Success", "Event is deleted");
      navigate(ROUTES.Events);
    },
    onError: serverErrorHandler,
  });

  const {
    mutate: onPublishEvent,
    isLoading: isPublicEventLoading,
    isSuccess: isSuccessPublish,
  } = useMutation((id) => publishEvent(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["event"]);
      displayToastSuccess("Success", "Event is published");
    },
    onError: serverErrorHandler,
  });

  const {
    mutate: onUnpublishEvent,
    isLoading: isUnpublishEventLoading,
    isSuccess: isSuccessUnpublish,
  } = useMutation(() => unpublishEvent(eventId), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(["event"]);
      trackInteraction("'Unpublish' button clicked", { scope: "Events" });
      displayToastSuccess("Success", "Event is unpublished");
    },
    onError: serverErrorHandler,
  });

  const { isLoading, data: eventData } = useQuery({
    queryKey: ["event", eventId],
    queryFn: (queryOptions) => (eventId ? getEvent(queryOptions) : null),
  });

  const { data: eventCategories } = useQuery({
    queryKey: ["eventCategories"],
    queryFn: getEventCategories,
  });

  const handleSearchLocations = useCallback(
    (searchValue) => {
      const lowered = searchValue.toLowerCase();
      const searchedLocationsOptions = locations?.data.filter(
        ({ title, city, country }) =>
          title.toLowerCase().includes(lowered) ||
          city?.toLowerCase().includes(lowered) ||
          country?.toLowerCase().includes(lowered)
      );
      setSearchedLocations(searchedLocationsOptions);
    },
    [locations?.data]
  );

  const locationOptions = useMemo(
    () => transformLocationsStructure(searchedLocations),
    [searchedLocations]
  );

  const categoriesOptions = useMemo(
    () => transformCategoriesStructure(eventCategories),
    [eventCategories]
  );

  const isDraftEvent = eventData?.data?.status === "draft";
  const isPastEvent =
    !isDraftEvent && eventData?.data?.activityStatus === "past";
  const isEditMode = !!eventId && !isPastEvent;
  const eventStatus = isDraftEvent
    ? eventData?.data?.status
    : eventData?.data?.activityStatus;

  const eventValues = useMemo(
    () => createEventFormValues(eventData, locations?.data),
    [eventData, locations?.data]
  );

  const methods = useForm({
    defaultValues: defaultEventValues,
    values: eventValues,
    resolver: (values, context, options) =>
      zodResolver(
        values.isTimeSlotted ? timeSlottedSchema : notTimeSlottedSchema
      )(values, context, options),
  });

  const {
    formState: { isDirty, errors, dirtyFields },
    handleSubmit,
    watch,
    reset,
  } = methods;

  useErrorsToast(errors, "Events");

  const onSubmit = async (formData, shouldBePublished) => {
    let eventIdFromResponse;

    const eventBody = createRequestBody(
      formData,
      watch("services"),
      dirtyFields
    );

    if (isEditMode) {
      const { data, status } = await updateEvent(eventBody);
      eventIdFromResponse = data?.data?.id;
      if (status !== 200) {
        return;
      } else {
        reset({}, { keepValues: true });
      }
    } else {
      const { data } = await createEvent(eventBody);
      eventIdFromResponse = data?.data?.id;
    }

    trackInteraction("'Save' button clicked", {
      scope: "Events",
      categories: getCategoriesFromFormData(formData, categoriesOptions),
      newEntity: !isEditMode,
    });

    if (shouldBePublished && (eventIdFromResponse || eventId)) {
      await onPublishEvent(eventIdFromResponse || eventId);

      trackInteraction("'Publish' button clicked", {
        scope: "Events",
        categories: getCategoriesFromFormData(formData, categoriesOptions),
        newEntity: !isEditMode,
      });
    }
  };

  const tabs = useMemo(
    () => [
      {
        id: 1,
        label: "Event details",
        component: (
          <EventDetails
            locationOptions={locationOptions}
            handleSearchLocations={handleSearchLocations}
          />
        ),
      },
      {
        id: 2,
        label: "Attending",
        component: (
          <EventReport id={+eventId} dateId={searchParams.get("dateId")} />
        ),
      },
    ],
    [locationOptions, handleSearchLocations, eventId]
  );

  const [showLeaveModal, setShowLeaveModal] = useState(
    isDirty && !(isCreateEventLoading || isUpdateEventLoading)
  );

  useEffect(() => {
    setShowLeaveModal(
      isDirty && !(isCreateEventLoading || isUpdateEventLoading)
    );
  }, [isDirty, isCreateEventLoading, isUpdateEventLoading]);

  // Checking successful publish/unpublish event for preventing showing the leave modal
  useEffect(() => {
    if (isSuccessPublish || isSuccessUnpublish) {
      reset({}, { keepValues: true });
      setShowLeaveModal(false);
    }
  }, [isSuccessPublish, isSuccessUnpublish, reset]);

  if (isLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer when={showLeaveModal}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
          {!isPastEvent ? (
            <Wrap>
              <LeftSidabarSection
                title={isEditMode ? eventData.data.name : "Add event"}
                prevPageButtonContent="Back to Events"
                prevPageRoute={ROUTES.Events}
              >
                {isEditMode && (
                  <StatusLabel
                    value={eventStatus}
                    sx={{ mt: "25px", mb: "27px" }}
                  />
                )}
                <Typography sx={{ mb: "7px", fontWeight: "600" }}>
                  Category*
                </Typography>
                <CategoriesContainer error={!!errors?.categories?.message}>
                  <CheckboxesGroup
                    options={categoriesOptions}
                    name="categories"
                  />
                </CategoriesContainer>
                <ButtonsWrapper>
                  <Button
                    disabled={
                      isUpdateEventLoading ||
                      isCreateEventLoading ||
                      isPublicEventLoading ||
                      isUnpublishEventLoading
                    }
                    sx={{
                      "&.MuiButton-contained.Mui-disabled": {
                        background: "#e4e4e4",
                        color: "#c0c0c0",
                      },
                      "&.MuiButton-root": {
                        padding: "0 14px",
                        minWidth: isDraftEvent ? "120px" : "110px",
                      },
                      whiteSpace: "nowrap",
                    }}
                    type="submit"
                    variant="contained"
                  >
                    {isEditMode ? "Save Changes" : "Save"}
                  </Button>
                  <Button
                    disabled={
                      isUpdateEventLoading ||
                      isCreateEventLoading ||
                      isPublicEventLoading ||
                      isUnpublishEventLoading
                    }
                    onClick={
                      isDraftEvent || !isEditMode
                        ? handleSubmit((data) => onSubmit(data, true))
                        : onUnpublishEvent
                    }
                    variant="outlined"
                    sx={{
                      "&.MuiButton-outlined.Mui-disabled": {
                        background: "#eaeaea",
                        color: "#b7babf",
                      },
                      "&.MuiButton-root": {
                        padding: "0 14px",
                        minWidth: "110px",
                      },
                      whiteSpace: "nowrap",
                    }}
                  >
                    {isDraftEvent || !isEditMode
                      ? "Publish Event"
                      : "Unpublish"}
                  </Button>
                </ButtonsWrapper>
                {isDraftEvent ? (
                  <RemoveButtonContainer>
                    <RemoveButton onClick={onDeleteEvent}>
                      Delete Event
                    </RemoveButton>
                  </RemoveButtonContainer>
                ) : null}
              </LeftSidabarSection>
              <ContentWrapper isEditMode={isEditMode}>
                {isEditMode ? (
                  <TabsContainer
                    tabs={tabs}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    sx={{ p: "0 50px" }}
                  />
                ) : (
                  <EventDetails
                    locationOptions={locationOptions}
                    handleSearchLocations={handleSearchLocations}
                  />
                )}
              </ContentWrapper>
            </Wrap>
          ) : (
            <PastEvent details={eventData?.data} />
          )}
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default Event;
