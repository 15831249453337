import { useQuery } from "@tanstack/react-query";
import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useMemo } from "react";
import { bool } from "prop-types";
import { Label, RichTextEditorWithLimit } from "@atoms";
import { ImageUpload, TextFieldWithLimit, TreeSelect } from "@molecules";
import { getPlaces } from "@requests";
import { transformPlacesToOptions } from "./utils";

const PartnerInfoSection = ({ isWorldWide }) => {
  const { data: places } = useQuery({
    queryKey: ["places"],
    queryFn: getPlaces,
  });

  const {
    getValues,
    formState: { errors },
  } = useFormContext();

  const defaultOption = getValues("place");

  const placesOptions = useMemo(
    () =>
      transformPlacesToOptions(places?.data || [], defaultOption, isWorldWide),
    [places?.data, defaultOption]
  );

  return (
    <Box sx={{ mb: 7.5 }}>
      <TextFieldWithLimit
        isLabelBold
        name="title"
        charactersLimit={100}
        label="Partner Name*"
        placeholder="Business Name"
        error={errors?.title?.message}
        sx={{
          mb: 5,
        }}
      />

      <TextFieldWithLimit
        isLabelBold
        name="tagline"
        charactersLimit={48}
        label="Discount Tagline*"
        placeholder="20% off all Bonnies"
        error={errors?.tagline?.message}
        sx={{
          mb: 7,
        }}
      />

      <Box sx={{ mb: 7.5 }}>
        <TreeSelect
          id="locations-select"
          name="place"
          mode="multiSelect"
          options={placesOptions}
          label="Location*"
          //TODO: investigate why validation fails after user selects any location and remove condition
          {...(errors?.place?.type === "too_small" && {
            error: errors?.place?.message,
          })}
        />
      </Box>

      <Box sx={{ mb: 6 }}>
        <Label>Benefit Image*</Label>
        <ImageUpload
          imageURL={getValues("image")}
          fieldName="image"
          errorText={errors?.image?.message}
        />
      </Box>

      <RichTextEditorWithLimit
        name="description"
        label="Description of Business"
        isLabelBold
        charactersLimit={500}
      />
    </Box>
  );
};

PartnerInfoSection.defaultProps = {
  isWorldWide: false,
};

PartnerInfoSection.propTypes = {
  isWorldWide: bool,
};

export default PartnerInfoSection;
