import styled from "styled-components";
import {
  color,
  flexbox,
  layout,
  space,
  typography,
  border,
  shadow,
} from "styled-system";
import { palette } from "@styles/palette";

export const Typography = styled.p`
  color: ${palette.black};
  ${border};
  ${color};
  ${flexbox};
  ${layout};
  ${space};
  ${typography};
  ${shadow};
`;

export default Typography;
