import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Box, Stack } from "@mui/material";
import {
  AddButton,
  ControlledCheckbox,
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { getBenefits, getPlaces } from "@requests";
import { ROUTES, statusesItems } from "@constants";
import { columns } from "./columns";
import { FiltersContainer } from "@organisms";
import { TreeSelect } from "@molecules";
import {
  transformBenefitsDataToOptions,
  transformPlacesDataToOptions,
} from "@features/Benefits/PartnerInfoSection/utils";
import { useBenefitCategoriesDictionary } from "@hooks/useBenefitCategoriesDictionary";
import { palette } from "@styles";
import { getFiltersForPage, setFiltersForPage } from "@helpers";

const Benefits = () => {
  const [filters, setFilters] = useState({
    page: 1,
    ...getFiltersForPage("benefits"),
  });

  useEffect(() => {
    setFiltersForPage("benefits", filters);
  }, [filters]);

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["benefits", filters],
    queryFn: getBenefits,
  });

  const { data: queryData, isLoading: isPlacesLoading } = useQuery({
    queryKey: ["places"],
    queryFn: getPlaces,
  });

  const placesOptions = useMemo(
    () => transformPlacesDataToOptions(queryData?.data || [], filters.place),
    [queryData]
  );

  const { data: benefitCategories, isLoading: isBenefitsCategoriesLoading } =
    useBenefitCategoriesDictionary();

  const benefitCategoriesOptions = useMemo(
    () =>
      transformBenefitsDataToOptions(
        benefitCategories?.data || [],
        filters.category
      ),
    [benefitCategories]
  );

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id) => navigate(`${ROUTES.Benefits}/${id}`),
    [navigate]
  );

  const onAddBenefitClick = useCallback(
    () => navigate(ROUTES.AddBenefit),
    [navigate]
  );

  const handleLocationChange = useCallback((items) => {
    setFilters((oldData) => {
      const newData = { ...oldData };

      if (items && items.length) {
        newData.place = items.map(({ value, parent }) => {
          const city = parent ? value : undefined;
          const country = parent ? parent : value;

          const placeObj = { country };
          if (city) {
            placeObj.city = city;
          }

          return placeObj;
        });
      } else {
        delete newData.place;
      }

      return newData;
    });
  }, []);

  const handleSearchPartnerNameChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.search = value;
      } else {
        delete newData.search;
      }
      return newData;
    });
  }, []);

  const handleCategoriesChange = useCallback((items) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (items && items.length) {
        newData.category = items.map(({ value }) => parseInt(value));
      } else {
        delete newData.category;
      }
      return newData;
    });
  }, []);

  const handleIsFeaturedChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.isFeatured = value;
      } else {
        delete newData.isFeatured;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  const rows = useMemo(
    () =>
      data?.data.map((benefit) => ({
        ...benefit,
        place: benefit.isWorldWide ? "Worldwide" : benefit.place,
      })),
    [data?.data]
  );

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.status = value;
      } else {
        delete newData.status;
      }
      return newData;
    });
  }, []);

  const isLoading =
    isDataLoading || isPlacesLoading || isBenefitsCategoriesLoading;

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Title>Member Benefits</Title>
        <AddButton onClick={onAddBenefitClick}>Add Benefit</AddButton>
      </Stack>

      <FiltersContainer defaultValues={filters}>
        <Box sx={{ "& > div": { minWidth: "240px" } }}>
          <TreeSelect
            id="locationsFilter"
            name="place"
            mode="multiSelect"
            options={placesOptions}
            onChange={handleLocationChange}
            texts={{ placeholder: "Location" }}
          />
        </Box>
        <SearchFieldFilter
          id="searchPartnerNameFilter"
          name="search"
          placeholder="Search Partner name..."
          onChange={handleSearchPartnerNameChange}
        />
        <TreeSelect
          id="categoriesFilter"
          name="category"
          mode="multiSelect"
          options={benefitCategoriesOptions}
          onChange={handleCategoriesChange}
          texts={{ placeholder: "Category" }}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={statusesItems}
          onChange={handleStatusChange}
        />
        <ControlledCheckbox
          id="isFeaturedFilter"
          name="isFeatured"
          label="Featured"
          onChange={handleIsFeaturedChange}
        />
      </FiltersContainer>

      {isLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={rows}
          columns={columns}
          superscription={
            <Superscription
              name="benefit"
              count={data?.pagination?.total}
              isLoading={isLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Benefits;
