import { useContext, useState } from "react";
import { MenuItem, Stack, Typography } from "@mui/material";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { ROUTES } from "@constants";
import { Avatar } from "@atoms";
import { ReactComponent as Dropdown } from "@assets/svgs/Dropdown.svg";
import storage from "@utils/storage";
import { UserContext } from "@context/UserContext";
import { resetStore } from "@store/user/duck";

import { StyledMenu } from "./styles";

const UserProfileMenu = () => {
  const { userData: currentUser } = useContext(UserContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    handleClose();
    Auth.signOut();
    storage.clear();
    dispatch(resetStore());
    navigate(ROUTES.Login);
  };

  return (
    <div
      style={{
        width: "100px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        onClick={handleClick}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          imageUrl={currentUser?.imageUrl}
          firstName={currentUser?.firstName}
          lastName={currentUser?.lastName}
        />
        <Dropdown />
      </Stack>
      <StyledMenu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.15)",
            mt: 2.5,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogOut}>
          <Typography variant="h3" color="darkGreen">
            Log Out
          </Typography>
        </MenuItem>
      </StyledMenu>
    </div>
  );
};

export default UserProfileMenu;
