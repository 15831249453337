const h1 = {
  fontWeight: 600,
  fontSize: "28px",
  lineHeight: "35px",
};

const h2 = {
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "34px",
};

const h3 = {
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
};

const body1 = {
  fontWeight: 400,
  fontSize: "13px",
  lineHeight: "19.5px",
};

const body2 = {
  fontWeight: 400,
  fontSize: "12px",
  lineHeight: "19.5px",
};

const caption = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
};

const button = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "21px",
};

export const fontSizes = {
  h1,
  h2,
  h3,
  body1,
  body2,
  caption,
  button,
};

export const typography = {
  ...fontSizes,
  fontFamily: "'Proxima Nova', Helvetica, Arial, sans-serif",
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontSize: 13,
};
