import { Events as View } from "@views";
import { Layout } from "@templates";

const Events = () => (
  <Layout>
    <View />
  </Layout>
);

export default Events;
