import { palette } from "./palette";
import { typography } from "./typography";

export const components = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        minWidth: "1280px",
      },
      ".MuiDataGrid-root": {
        "& .MuiDataGrid-columnHeader": {
          "&:focus-within": {
            outline: "none !important",
            outlineWidth: "none !important",
            outlineOffset: "none !important",
          },
        },
        "& .MuiDataGrid-cell": {
          "&:focus-within": {
            outline: "none !important",
            outlineWidth: "none !important",
            outlineOffset: "none !important",
          },
        },
      },
    },
  },
  MuiButtonBase: {
    styleOverrides: {
      root: {
        "&.MuiButton-root": {
          textTransform: "none",
          fontWeight: 600,
          padding: "0 16px",
          height: "32px",
        },
        "&.MuiButton-contained": {
          backgroundColor: palette.darkGreen,
          color: "#fff",
          borderRadius: 6,
          minWidth: 120,
          "&:hover": {
            backgroundColor: palette.darkerGreen,
          },
          "&:disabled": {
            backgroundColor: palette.gray[200],
            color: "#fff",
          },
        },

        "&.MuiButton-text": {
          color: palette.darkGreen,
        },

        "&.MuiButton-outlined": {
          borderRadius: 6,
          border: `1px solid ${palette.darkGreen}`,
          color: palette.darkGreen,
          backgroundColor: "#fff",
          "&:hover": {
            border: `1px solid ${palette.darkGreen}`,
            backgroundColor: "#fff",
          },
          "&:disabled": {
            backgroundColor: palette.gray[200],
            color: palette.gray[200],
          },
        },
        "&.MuiButton-outlinedSecondary": {
          color: palette.gray[200],
          border: `1px solid ${palette.gray[200]}`,
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        backgroundColor: "#fff",
        fontSize: "12px",
        fontWeight: 400,
        border: "1px solid rgba(21, 21, 21, 0.3)",
        padding: "12px",
        borderRadius: 2,
        color: palette.black,
        maxWidth: 250,
        marginTop: "11px !important",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${palette.gray[500]} !important`,
        "& .MuiTab-root": {
          color: palette.black,
          textTransform: "none",
        },
        "& .MuiTab-textColorPrimary": {
          color: palette.black,
        },
        "& .Mui-selected": {
          color: `${palette.darkGreen} !important`,
          fontWeight: 700,
        },
        "& .MuiTabs-indicator": {
          height: "3px",
          backgroundColor: palette.darkGreen,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        width: "100%",
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.gray[700],
          borderWidth: "1px !important",
          borderStyle: "solid !important",
        },
        "&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${palette.darkGreen} !important`,
        },
        "&:not(.Mui-disabled, .Mui-error):hover": {
          ".MuiOutlinedInput-notchedOutline": {
            borderColor: `${palette.darkGreen} !important`,
          },
        },
        "& .MuiOutlinedInput-input:not(.MuiSelect-select)": {
          color: palette.black,
          padding: "8px 12px",
          borderRadius: "6px",
          width: "100%",
          height: "32px",
          boxSizing: "border-box",
          "&:focus": {
            borderColor: palette.darkGreen,
          },
        },
        "&.MuiInputBase-multiline": {
          padding: 0,
        },
        "&.MuiInputBase-adornedEnd": {
          paddingRight: "12px !important",
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        ...typography.body1,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        margin: "3px 0 0",
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        width: "100%",
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: "9px",
        "& input:checked + .MuiSvgIcon-root": {
          fill: palette.checkbox,
        },
        "& .MuiSvgIcon-root": {
          width: "16px",
          height: "16px",
        },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        padding: 0,
        marginLeft: 0,
        marginRight: "8px",
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: `${typography.body1}`,
        wordBreak: "break-word",
        whiteSpace: "pre-wrap",
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        mt: 3,
        border: "1px solid gray[500]",
        "& .MuiDataGrid-main": {
          backgroundColor: "#fff",
          borderRadius: "4px",
        },
        "& .MuiDataGrid-row": {
          cursor: "pointer",
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "& .MuiDataGrid-footerContainer": {
          display: "block",
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: palette.checkbox,
      },
    },
  },
};
