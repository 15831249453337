import { styled } from "@mui/material/styles";
import { ListItem } from "@mui/material";

export const StyledListItem = styled(ListItem)(() => ({
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "#fff",
  padding: "10px 9px",
  borderRadius: "2px",
}));
