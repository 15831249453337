import { ReactComponent as MissingValue } from "@assets/svgs/MissingValue.svg";
import { parseStrictISOString } from "@helpers";
import { BootstrapTooltip, DataTableEllipsisCell, StatusLabel } from "@atoms";
import { MultiplyList } from "@molecules";
import { DATE_TEMPLATE, DATE_TIME_TEMPLATE } from "@constants";

export const columns = [
  {
    id: 1,
    field: "label",
    headerName: "Label",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 2,
  },
  {
    id: 2,
    field: "content",
    headerName: "Content",
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        <BootstrapTooltip title={value} placement="top">
          <DataTableEllipsisCell>{value}</DataTableEllipsisCell>
        </BootstrapTooltip>
      ) : (
        <MissingValue />
      ),
    flex: 2,
  },
  {
    id: 3,
    field: "createdBy",
    headerName: "Created By",
    sortable: false,
    renderCell: ({ value }) => value || <MissingValue />,
    flex: 1,
  },
  {
    id: 4,
    field: "lastModified",
    headerName: "Last Modified",
    sortable: false,
    renderCell: ({ value }) =>
      value ? parseStrictISOString(value, DATE_TEMPLATE) : <MissingValue />,
    flex: 0,
  },
  {
    id: 5,
    field: "sentTo",
    headerName: "Sent To",
    width: 145,
    sortable: false,
    renderCell: ({ value }) =>
      value.length ? <MultiplyList values={value} /> : <MissingValue />,
    flex: 0,
  },
  {
    id: 6,
    field: "status",
    headerName: "Status",
    width: 100,
    sortable: false,
    renderCell: ({ value }) => <StatusLabel value={value} />,
    flex: 0,
  },
  {
    id: 7,
    field: "sentDate",
    headerName: "Date",
    width: 120,
    sortable: false,
    renderCell: ({ value }) =>
      value ? (
        parseStrictISOString(value, DATE_TIME_TEMPLATE)
      ) : (
        <MissingValue />
      ),
    flex: 0,
  },
];
