import { CombineRooms as View } from "@views";
import { Layout } from "@templates";

const CombineRooms = () => (
  <Layout>
    <View />
  </Layout>
);

export default CombineRooms;
