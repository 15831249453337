import { useCallback } from "react";
import { func, string } from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { GenericSearchFilter } from "@molecules";
import { getCompanyNames } from "@requests";
import { transformCompaniesByStructure } from "@views/Event/utils";

const CompaniesFilter = ({ label, onChange }) => {
  const { data: itemsData } = useQuery({
    queryKey: ["companies", {}],
    queryFn: getCompanyNames,
  });

  const filterItemsFunction = useCallback(
    (searchValue) =>
      itemsData?.data.filter(({ name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [itemsData?.data]
  );

  return (
    <GenericSearchFilter
      name="companyId"
      label={label}
      itemsData={itemsData}
      transformFunction={transformCompaniesByStructure}
      filterItemsFunction={filterItemsFunction}
      onChange={onChange}
      customWidth="140px"
    />
  );
};

CompaniesFilter.propTypes = {
  label: string,
  onChange: func,
};

export default CompaniesFilter;
