import { Box, Typography } from "@mui/material";
import { useMemo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { shape, string, number, arrayOf, func } from "prop-types";
import { Subtitle, Label, ControlledCheckbox, Toggle } from "@atoms";
import { NestedSingleSelect, GenericSearchFilter } from "@molecules";
import { Separator } from "./styles";
import { transformRoomsByStructure } from "@views/CombineRooms/utils";

const AddComboDetails = ({
  locationOptions,
  handleSearchLocations,
  roomOptions,
}) => {
  const {
    formState: { errors },
    watch,
  } = useFormContext();

  const location = watch("location");

  const firstMeetingRoom = roomOptions?.data?.find(
    (room) => parseInt(watch("firstMeetingRoom"), 10) === room.id
  );

  const secondMeetingRoom = roomOptions?.data?.find(
    (room) => parseInt(watch("secondMeetingRoom"), 10) === room.id
  );

  const firstList = useMemo(
    () =>
      firstMeetingRoom && secondMeetingRoom
        ? [firstMeetingRoom]
        : roomOptions?.data?.filter(
            ({ roomNumber, floor }) =>
              roomNumber !== secondMeetingRoom?.roomNumber &&
              (!secondMeetingRoom ||
                !!firstMeetingRoom ||
                floor === secondMeetingRoom?.floor)
          ),
    [firstMeetingRoom, secondMeetingRoom, roomOptions?.data]
  );

  const secondList = useMemo(
    () =>
      firstMeetingRoom && secondMeetingRoom
        ? [secondMeetingRoom]
        : roomOptions?.data?.filter(
            ({ roomNumber, floor }) =>
              roomNumber !== firstMeetingRoom?.roomNumber &&
              (!firstMeetingRoom ||
                !!secondMeetingRoom ||
                floor === firstMeetingRoom?.floor)
          ),
    [firstMeetingRoom, secondMeetingRoom, roomOptions?.data]
  );

  const filterFirstItemsFunction = useCallback(
    (searchValue) =>
      firstList?.filter(({ roomNumber }) =>
        roomNumber?.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [firstList]
  );

  const filterSecondItemsFunction = useCallback(
    (searchValue) =>
      secondList?.filter(({ roomNumber }) =>
        roomNumber?.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [secondList]
  );

  return (
    <>
      <Box
        sx={{
          mb: "20px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <NestedSingleSelect
          id="location-nested-select"
          options={locationOptions}
          option={location}
          name="location"
          customWidth="260px"
          label="Location*"
          onSearchChange={handleSearchLocations}
          textFieldProps={{
            error: !!errors?.location?.name?.message,
          }}
        />
      </Box>
      <Subtitle>Rooms To Combine</Subtitle>
      <Label sx={{ fontWeight: 400 }}>Room 1*</Label>
      <GenericSearchFilter
        maxHeight="200px"
        error={!!errors?.firstMeetingRoom}
        searchPlaceholder="Room"
        name="firstMeetingRoom"
        itemsData={{ data: firstList }}
        transformFunction={transformRoomsByStructure}
        filterItemsFunction={filterFirstItemsFunction}
        customWidth="260px"
      />
      <Label sx={{ fontWeight: 400, marginTop: "20px" }}>Room 2*</Label>
      <GenericSearchFilter
        searchPlaceholder="Room"
        name="secondMeetingRoom"
        error={!!errors?.secondMeetingRoom}
        customWidth="260px"
        itemsData={{ data: secondList }}
        transformFunction={transformRoomsByStructure}
        filterItemsFunction={filterSecondItemsFunction}
      />
      <Separator />
      <Box
        sx={{
          mb: "15px",
        }}
      >
        <ControlledCheckbox
          defaultValue={true}
          name="cmNotificationEnabled"
          label="Notify CM team when booked"
        />
      </Box>
      <Toggle
        checked={true}
        isBig
        name="status"
        id="statusSwitch"
        label="Active"
        sx={{ mr: 13.5, mb: 4 }}
      />
      <Typography
        sx={(theme) => ({ mb: "15px", color: theme.palette.gray[200] })}
      >
        Rooms marked as Active will be published when saved.
      </Typography>
    </>
  );
};

AddComboDetails.defaultProps = {
  isSubmitButtonDisabled: false,
  announcement: null,
};

AddComboDetails.propTypes = {
  locationOptions: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      suboptions: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
          suboptions: arrayOf(
            shape({
              id: number.isRequired,
              name: string.isRequired,
              timezone: string.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ),
  handleSearchLocations: func.isRequired,
  handleSearchRooms: func.isRequired,
  roomOptions: shape({}),
};

export default AddComboDetails;
