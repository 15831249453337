export const findRecursively = (
  arr = [],
  childrenFieldName = "children",
  fieldName = "id",
  fieldValue = -1
) => {
  for (const arrayItem of arr) {
    if (arrayItem[fieldName].toString() === fieldValue.toString()) {
      return arrayItem;
    }

    if (arrayItem[childrenFieldName]) {
      const foundThroughChildren = findRecursively(
        arrayItem[childrenFieldName],
        childrenFieldName,
        fieldName,
        fieldValue
      );

      if (foundThroughChildren) {
        return foundThroughChildren;
      }
    }
  }

  return null;
};
