import { Event as View } from "@views";
import { Layout } from "@templates";

const Event = () => (
  <Layout withContainer={false}>
    <View />
  </Layout>
);

export default Event;
