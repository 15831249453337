import axios from "axios";

import { BASE_URL, UNAUTHORIZED } from "@constants";
import { refreshToken } from "@utils/amplify";
import storage from "./storage";
// import { reportError } from "./sentry";

const options = {
  baseURL: BASE_URL,
};

const tokenHeaderInterceptor = (config) => {
  const token = storage.getToken();

  const configWithTokenHeader = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${token || ""}`,
    },
  };

  return configWithTokenHeader;
};

const successInterceptor = (response) => response;

const errorInterceptor = (error) => {
  const { status } = error.response || {};

  // TODO: figure out of why the error object is modified by Sentry
  // const userId = storage.getUserId();

  // reportError(
  //   `Request failed (${error.response.config.method}/ ${error.response.config.url})`,
  //   "userId",
  //   userId,
  //   error.response.config.data ? JSON.parse(error.response.config.data) : {}
  // );

  if (status && status === UNAUTHORIZED) {
    refreshToken();
  }

  return Promise.reject(error);
};

const authorized = axios.create(options);
authorized.interceptors.request.use(tokenHeaderInterceptor);
authorized.interceptors.response.use(successInterceptor, errorInterceptor);

export default {
  authorized,
};
