import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Wrap = styled(Box)(() => ({
  position: "relative",

  "& .dropdown-select": {
    minWidth: "240px",
  },
}));
