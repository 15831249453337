import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  DataTable,
  DropdownSimpleFilter,
  Loader,
  SearchFieldFilter,
  Superscription,
  Title,
} from "@atoms";
import { getLocations } from "@requests";
import { ROUTES, statusesItems } from "@constants";
import { getAddress, getFiltersForPage, setFiltersForPage } from "@helpers";
import { FiltersContainer } from "@organisms";
import { LocationsFilter } from "@molecules";
import { palette } from "@styles";
import { UserContext } from "@context/UserContext";
import { columns } from "./columns";

const Locations = () => {
  const {
    userData: { locationId: id },
    locationData: { country, city },
  } = useContext(UserContext);

  const [filters, setFilters] = useState({
    id,
    country,
    city,
    page: 1,
    ...getFiltersForPage("locations"),
  });

  useEffect(() => {
    setFiltersForPage("locations", filters);
  }, [filters]);

  const { isLoading: isDataLoading, data } = useQuery({
    queryKey: ["locations", filters],
    queryFn: getLocations,
  });

  const navigate = useNavigate();

  const onRowClick = useCallback(
    (id) => navigate(`${ROUTES.Locations}/${id}`),
    [navigate]
  );

  const rows = useMemo(
    () =>
      data?.data.map((location) => ({
        ...location,
        locationName: location.title,
        locationDisplayName: location.title,
        address: getAddress(location),
      })),
    [data?.data]
  );

  const handleLocationChange = useCallback((data) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      delete newData.id;
      delete newData.country;
      delete newData.city;

      if (data) {
        if (data.id) {
          newData.id = parseInt(data.id);
        }
        if (data.city) {
          newData.city = data.city;
        }
        if (data.country) {
          newData.country = data.country;
        }
      }

      return newData;
    });
  }, []);

  const handleSearchChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.search = value;
      } else {
        delete newData.search;
      }
      return newData;
    });
  }, []);

  const handleStatusChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value) {
        newData.status = value;
      } else {
        delete newData.status;
      }
      return newData;
    });
  }, []);

  const handlePageChange = useCallback((value) => {
    setFilters((oldData) => {
      const newData = { ...oldData };
      if (value || value === 0) {
        newData.page = value + 1;
      } else {
        delete newData.page;
      }
      return newData;
    });
  }, []);

  return (
    <>
      <Title>Locations</Title>

      <FiltersContainer defaultValues={filters}>
        <LocationsFilter
          name="id"
          label="Location"
          onChange={handleLocationChange}
        />
        <SearchFieldFilter
          id="searchFilter"
          name="search"
          placeholder="Search by name or salesforce ID..."
          onChange={handleSearchChange}
        />
        <DropdownSimpleFilter
          id="statusFilter"
          name="status"
          label="Status"
          options={statusesItems}
          onChange={handleStatusChange}
        />
      </FiltersContainer>

      {isDataLoading ? (
        <Loader
          position="relative"
          background={palette.white}
          width="100%"
          height={64}
        />
      ) : (
        <DataTable
          rows={rows}
          columns={columns}
          superscription={
            <Superscription
              name="location"
              count={data?.pagination?.total}
              isLoading={isDataLoading}
            />
          }
          onRowClick={onRowClick}
          paginationMode="server"
          page={filters.page - 1}
          rowCount={data?.pagination?.total}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
};

export default Locations;
