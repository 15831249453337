import { node } from "prop-types";
import { StyledTypography } from "./styles";

const Subtitle = ({ children, ...props }) => (
  <StyledTypography variant="h3" {...props}>
    {children}
  </StyledTypography>
);

Subtitle.propTypes = {
  children: node.isRequired,
};

export default Subtitle;
