import { useEffect } from "react";
import { displayToastError, trackInteraction } from "@helpers";

const useErrorsToast = (errors, scope = "App") => {
  useEffect(() => {
    if (Object.keys(errors).length) {
      console.warn(errors);
      trackInteraction("Required fields missing", { scope });
      displayToastError("Error", "Required fields missing");
    }
  }, [errors]);
};

export default useErrorsToast;
