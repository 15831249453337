import { Dialog, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogTitle-root": {
    padding: theme.spacing(4, 8),
    backgroundColor: theme.palette.cream,
  },
  "& .MuiDialogContent-root": {
    padding: theme.spacing(7, 8),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(5, 8),
  },
  "& .MuiDialog-paper": {
    width: "100%",
  },
}));

export const Title = styled(Typography)(() => ({
  fontSize: "20px",
  lineHeight: "24px",
  fontWeight: 600,
}));
