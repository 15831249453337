import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Wrap = styled(Box)(() => ({
  width: "260px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
}));

export const Inner = styled(Box)(({ theme, error }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100%",
  border: `1px dashed ${error ? theme.palette.red : theme.palette.gray[200]}`,
  backgroundColor: theme.palette.gray[600],
  borderRadius: "6px",
  cursor: "pointer",
}));

export const IconWrap = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.darkGreen,
  letterSpacing: "0.01em",
  textDecoration: "underline",
  fontWeight: 600,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "18px",
  color: theme.palette.black,
  letterSpacing: "0.02em",
  marginTop: "6px",
}));

export const LoadedFileWrap = styled(Box)(() => ({
  position: "relative",
}));

export const ImageWrap = styled(Box)(() => ({
  overflow: "hidden",
  width: "260px",
  height: "150px",
  "& > img": {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: "6px",
  },
}));

export const TagBlock = styled(Box)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  color: theme.palette.common.white,
  width: "56px",
  height: "28px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "absolute",
  top: "8px",
  left: "10px",
  letterSpacing: "0.02em",
  borderRadius: "4px",
}));

export const ControlsWrap = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "absolute",
  right: "10px",
  top: "10px",
  "& > button:first-child": {
    marginRight: theme.spacing(2.5),
  },
}));

export const StyledButton = styled(Button)(() => ({
  borderRadius: "4px",
  backgroundColor: "rgba(255, 255, 255, 0.95)",

  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "auto",
  padding: 0,
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.95)",
  },
  "&.MuiButton-root": {
    width: "24px",
    height: "24px",
    padding: 0,
  },
}));

export const TitleWrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  alignSelf: "start",
  gap: "8px",
  marginBottom: "5px",
}));

export const UploadWrap = styled(Box)(() => ({
  width: "100%",
  minHeight: "150px",
  position: "relative",
}));
