const prefix = "/api";

export const API_ENDPOINTS = {
  Announcements: `${prefix}/admin/announcement`,
  Me: `${prefix}/user/me`,
  User: `${prefix}/admin/user`,
  UserSearch: `${prefix}/admin/user/small-search`,
  Locations: `${prefix}/admin/location`,
  PushNotifications: `${prefix}/admin/push-notifications`,
  Events: `${prefix}/admin/events`,
  EventCategories: `${prefix}/event-category`,
  Amenities: `${prefix}/admin/amenity`,
  Benefits: `${prefix}/admin/benefit`,
  Rooms: `${prefix}/admin/meeting-room`,
  PlacesWithLocations: `${prefix}/admin/location/place-with-location`,
  BenefitCategories: `${prefix}/admin/benefit-category`,
  Room: `${prefix}/admin/meeting-room`,
  CompanyNames: `${prefix}/admin/company/names`,
  Managers: `${prefix}/admin/user/manager`,
  Places: `${prefix}/admin/location/place`,
  Features: `${prefix}/admin/feature`,
  ReportsList: `${prefix}/report/room-bookings/list`,
  ReportsTotal: `${prefix}/report/room-bookings/total`,
  ReportsCSV: `${prefix}/report/room-bookings/csv`,
  ReportsEvent: `${prefix}/report/event`,
  ReportsCredits: `${prefix}/report/credits`,
  ReportsEventTotal: `${prefix}/report/event/total`,
};
