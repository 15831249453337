import { Stack } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { Loader } from "@atoms";
import { LeftSidabarSection } from "@molecules";
import { LeaveModalContainer } from "@templates";
import { ROUTES } from "@constants";
import { addBenefit, editBenefit, getBenefit } from "@requests";
import {
  displayToastSuccess,
  getCategoriesFromFormData,
  serverErrorHandler,
  trackInteraction,
} from "@helpers";
import { palette } from "@styles/palette";
import {
  ContactDetailsSection,
  GetBenefitSection,
  PartnerInfoSection,
  SidebarContent,
} from "@features";
import { Column } from "./styles";
import { schema } from "./schema";
import {
  createRequestBody,
  defaultValues,
  generateDefaultFormValues,
} from "./utils";
import { useErrorsToast } from "@hooks";
import { useBenefitCategoriesDictionary } from "@hooks/useBenefitCategoriesDictionary";
import { transformBenefitCategoriesToOptions } from "@features/Benefits/SidebarContent/utils";

const Benefit = () => {
  const { benefitId } = useParams();
  const navigate = useNavigate();
  const isEditMode = !!benefitId;
  const [defaultDates, setDefaultDates] = useState({
    startDate: "",
    endDate: "",
  });

  const { isLoading, data: benefitData } = useQuery({
    queryKey: ["benefit", benefitId],
    queryFn: () => (benefitId ? getBenefit(benefitId) : null),
  });

  const methods = useForm({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const {
    handleSubmit,
    reset,
    formState: { isDirty, errors },
  } = methods;

  useErrorsToast(errors, "Benefits");

  const { mutate: createBenefit, isLoading: isCreateBenefitLoading } =
    useMutation((formData) => addBenefit(formData), {
      onSuccess: () => {
        displayToastSuccess("Success", "Benefit is created");
        navigate(ROUTES.Benefits);
      },
      onError: serverErrorHandler,
    });

  const { mutate: updateBenefit, isLoading: isUpdateBenefitLoading } =
    useMutation((formData) => editBenefit(benefitId, formData), {
      onSuccess: () => {
        displayToastSuccess("Success", "Benefit is updated");
        reset({}, { keepValues: true });
      },
      onError: serverErrorHandler,
    });

  const { data: benefitCategoriesData } = useBenefitCategoriesDictionary();

  const benefitCategories = useMemo(
    () =>
      transformBenefitCategoriesToOptions(benefitCategoriesData?.data || []),
    [benefitCategoriesData]
  );

  const onSubmit = useCallback(
    async (formData) => {
      const benefitBody = createRequestBody(formData, isEditMode, defaultDates);

      if (isEditMode) {
        await updateBenefit(benefitBody);
      } else {
        await createBenefit(benefitBody);
      }

      trackInteraction("'Save' button clicked", {
        scope: "Benefits",
        categories: getCategoriesFromFormData(formData, benefitCategories),
        newEntity: !isEditMode,
      });
    },
    [defaultDates]
  );

  useEffect(() => {
    if (benefitData?.data) {
      const formValues = generateDefaultFormValues(benefitData);

      setDefaultDates({
        startDate: formValues.startDate,
        endDate: formValues.endDate,
      });

      reset(formValues);
    }
  }, [benefitData?.data]);

  // TODO: create a HOC component and handle loader logic there
  if (isLoading) {
    return (
      <Loader
        position="fixed"
        background={palette.white}
        width={64}
        height={64}
      />
    );
  }

  return (
    <LeaveModalContainer
      when={isDirty && !(isCreateBenefitLoading || isUpdateBenefitLoading)}
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="row">
            <LeftSidabarSection
              prevPageButtonContent="Back to Benefits"
              prevPageRoute={ROUTES.Benefits}
              title={benefitId ? benefitData?.data?.title : "Add Benefit"}
            >
              <SidebarContent
                isSubmitButtonDisabled={
                  isCreateBenefitLoading || isUpdateBenefitLoading
                }
              />
            </LeftSidabarSection>
            <Stack spacing={10} pt={12} pb={12} pr={12} pl={12}>
              <Column>
                <PartnerInfoSection
                  isWorldWide={benefitData?.data?.isWorldWide}
                />
              </Column>
              <GetBenefitSection />
              <Column>
                <ContactDetailsSection />
              </Column>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </LeaveModalContainer>
  );
};

export default Benefit;
