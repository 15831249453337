import { Button, Typography, Box } from "@mui/material";
import { useMemo } from "react";
import { bool } from "prop-types";
import { useFormContext } from "react-hook-form";
import { Label, Toggle } from "@atoms";
import { CheckboxesGroup } from "@molecules";
import { Container as CheckboxesGroupContainer } from "@molecules/CheckboxesGroup/styles";
import { useBenefitCategoriesDictionary } from "@hooks/useBenefitCategoriesDictionary";
import { palette } from "@styles/palette";
import DatePickersSection from "../DatePickersSection/DatePickersSection";
import Controls from "../Controls/Controls";
import { Separator } from "./styles";
import { transformBenefitCategoriesToOptions } from "./utils";

const SidebarContent = ({ isSubmitButtonDisabled }) => {
  // TODO: use the isLoading from this hook and create a skeleton for the categories block
  const { data: benefitCategories } = useBenefitCategoriesDictionary();

  const benefitCategoriesMemoized = useMemo(
    () => transformBenefitCategoriesToOptions(benefitCategories?.data || []),
    [benefitCategories]
  );

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Label>Category*</Label>
      <Box
        sx={{
          mb: 7.5,
        }}
      >
        <CheckboxesGroupContainer
          sx={{
            borderColor: errors?.category?.message
              ? palette.red
              : palette.gray[700],
          }}
        >
          <CheckboxesGroup
            options={benefitCategoriesMemoized}
            name="category"
            errorText={errors?.category?.message}
          />
        </CheckboxesGroupContainer>
      </Box>
      <DatePickersSection />
      <Controls />
      <Separator />
      <Toggle
        isBig
        name="status"
        id="statusSwitch"
        label="Active"
        sx={{ mr: 13.5, mb: 4 }}
      />
      <Typography sx={(theme) => ({ mb: 8.5, color: theme.palette.dark })}>
        Benefits marked as Active will be published when saved.
      </Typography>

      <Button
        type="submit"
        variant="contained"
        disabled={isSubmitButtonDisabled}
      >
        Save
      </Button>
    </>
  );
};

SidebarContent.defaultProps = {
  isSubmitButtonDisabled: false,
};

SidebarContent.propTypes = {
  isSubmitButtonDisabled: bool,
};

export default SidebarContent;
