import { TOKEN, USER_ID, AMPLIFY_AUTH_USER } from "@constants";

const setToken = (token) => localStorage.setItem(TOKEN, token);

const getToken = () => localStorage.getItem(TOKEN);

const setUserId = (id) => localStorage.setItem(USER_ID, id);

const getUserId = () => localStorage.getItem(USER_ID);

const setAmplifyAuthUser = (user) =>
  localStorage.setItem(AMPLIFY_AUTH_USER, user);

const getAmplifyAuthUser = () => localStorage.getItem(AMPLIFY_AUTH_USER);

const removeToken = () => localStorage.removeItem(TOKEN);

const clear = () => localStorage.clear();

export default {
  setToken,
  getToken,
  removeToken,
  clear,
  setUserId,
  getUserId,
  setAmplifyAuthUser,
  getAmplifyAuthUser,
};
