import { string } from "prop-types";
import { Box } from "@mui/material";
import { DropdownSimple } from "@atoms";

const DropdownSimpleFilter = ({ name, customWidth, ...props }) => {
  return (
    <Box
      sx={{
        width: customWidth,
        "& .MuiInputBase-input": {
          height: "32px",
          lineHeight: "32px",
        },
      }}
    >
      <DropdownSimple
        {...props}
        name={name}
        sx={{
          width: customWidth,
        }}
      />
    </Box>
  );
};

DropdownSimpleFilter.propTypes = {
  name: string,
  customWidth: string,
};

DropdownSimpleFilter.defaultProps = {
  customWidth: "160px",
};

export default DropdownSimpleFilter;
