import styled, { css } from "styled-components";
import {
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  background,
  shadow,
  typography,
  grid,
} from "styled-system";

export const Flex = styled.div`
  ${({ display }) => css`
    display: ${display || "flex"};

    ${grid};
    ${typography};
    ${shadow};
    ${background}
    ${border};
    ${color};
    ${flexbox};
    ${layout};
    ${position};
    ${space};
  `}
`;

export default Flex;
