import { node } from "prop-types";
import { StyledFormLabel } from "./styles";

const Label = ({ children, ...props }) => (
  <StyledFormLabel {...props}>{children}</StyledFormLabel>
);

Label.propTypes = {
  children: node.isRequired,
};

export default Label;
