import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { string } from "prop-types";
import Picker from "@emoji-mart/react";
import emojiPickerData from "@emoji-mart/data";
import { Box } from "@mui/material";

import { TextFieldWithLimit } from "@molecules";
import { useModal } from "@hooks";
import { Typography } from "@mixins";
import { ReactComponent as EmojisIcon } from "@assets/svgs/Emojis.svg";
import { Wrap } from "./styles";

const id = "pushNotificationsContent";

const TextareaWithEmojis = ({ name, ...restProps }) => {
  const { setValue } = useFormContext();
  const {
    showModal: isShowingEmojis,
    onOpen: showEmojis,
    onClose: hideEmojis,
  } = useModal();

  const handleEmojiSelect = useCallback((emojiObject) => {
    const textAreaElement = document.getElementById(id);

    if (textAreaElement) {
      const newValue =
        textAreaElement.value.substr(0, textAreaElement.selectionStart) +
        emojiObject.native +
        textAreaElement.value.substr(textAreaElement.selectionEnd);

      setValue(name, newValue);
    }
  }, []);

  const handleClickOutside = useCallback(() => {
    hideEmojis();
  }, []);

  const handleEmojisClick = useCallback(
    (event) => {
      event.preventDefault();
      event.stopPropagation();

      if (isShowingEmojis) {
        hideEmojis();
      } else {
        showEmojis();
      }
    },
    [isShowingEmojis]
  );

  return (
    <Wrap>
      <TextFieldWithLimit id={id} name={name} {...restProps} />
      <Box className="link-wrapper">
        <a href="#" onClick={handleEmojisClick}>
          <EmojisIcon />
          <Typography fontWeight={600}>Emoji</Typography>
        </a>
      </Box>
      <Box
        className="picker-wrapper"
        sx={{ display: isShowingEmojis ? "block" : "none" }}
      >
        <Picker
          theme="light"
          data={emojiPickerData}
          onEmojiSelect={handleEmojiSelect}
          onClickOutside={handleClickOutside}
        />
      </Box>
    </Wrap>
  );
};

TextareaWithEmojis.propTypes = {
  name: string.isRequired,
};

export default TextareaWithEmojis;
