import { bool, func } from "prop-types";
import { Typography } from "@mui/material";
import { ModalContainer } from "@molecules";
import { ReactComponent as TrashBin } from "@assets/svgs/TrashBinX.svg";

const LeaveModal = ({ isOpen, onClose, onConfirm }) => (
  <ModalContainer
    isOpen={isOpen}
    onClose={onClose}
    title="Leaving without saving?"
    titleAriaLabel="leave-modal-title"
    descriptionAriaLabel="leave-modal-description"
    maxWidth={360}
    confirmButtonConfig={{
      title: "No, stay",
      onClick: onClose,
    }}
    cancelButtonConfig={{
      title: "Yes, leave",
      onClick: onConfirm,
      icon: <TrashBin />,
    }}
  >
    <Typography>Saving is good for your skin :)</Typography>
  </ModalContainer>
);

LeaveModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onConfirm: () => {},
};

LeaveModal.propTypes = {
  isOpen: bool,
  onClose: func,
  onConfirm: func,
};

export default LeaveModal;
